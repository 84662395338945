import { Pipe, PipeTransform } from '@angular/core';

declare const moment;

@Pipe({
    name: 'date'
})
export class DatePipe implements PipeTransform {

    transform(milliseconds: number): string {
        if (milliseconds == null) {
            return null;
        }
        return moment(milliseconds).format('MMM Do YYYY');
    }

}
