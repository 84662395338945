import { Injectable } from '@angular/core';
import { WorkDepartment } from '../entities/work-department';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkDeparmentsService {

  constructor(private http: HttpClient) { }



  getAll(): Promise<Array<WorkDepartment>> {
    return this.http.get<Array<WorkDepartment>>(environment.api.customers + '/workDepartments').toPromise();
}
}


