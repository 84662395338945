import { CustomerPayment } from 'src/app/entities/customer-payment';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Company } from '../entities/company';
import { PaymentMethod } from '../entities/payment-method';
import { PaymentTransaction } from '../entities/payment-transaction';
import { ResponseTransaction } from '../entities/response-transaction';
import { Payment } from '../entities/payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private http: HttpClient
  ) { }

  sendPaymentAuthorization(payment, companyId) {
    return this.http.post<any>(environment.api.customers + '/payment-authorization?company_id=' + companyId, payment).toPromise();
  }

  sendPaymentAuthorizationAch(payment, companyId) {
    return this.http.post<any>(environment.api.customers + '/payment-authorization-ach?company_id=' + companyId, payment).toPromise();
  }

  sendPayment(idBalance: string, idMethod: string, payment: CustomerPayment) {
    return this.http.post<ResponseTransaction>(environment.api.customers + '/payment/' +
      idBalance + '/method/' + idMethod, payment).toPromise();
  }

  getPaymentMethod(idMethod: string) {
    return this.http.get<PaymentMethod>(environment.api.customers + '/payment-method/' + idMethod).toPromise();
  }

  getById(balanceId: string) {
    return this.http.get<any>(environment.api.customers + '/payments-gateway-settings/' + balanceId).toPromise();
  }
}
