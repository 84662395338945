import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

 
  transform(value: any[], descending?: boolean, attr?: string): any {
    value.sort((t1, t2) => {
      const name1 = t1[attr];
      const name2 = t2[attr];
      if( descending ){
            if (name1 > name2) { return 1; }
            if (name1 < name2) { return -1; }
      } else{
            if (name1 > name2) { return -1; }
            if (name1 < name2) { return 1; }
      }
      return 0;
    });
    return value;
  }

}
