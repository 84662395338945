<!-- CONTENT BREADCRUMB -->
<div class="breadcrumb-four mt-1">
  <ul class="breadcrumb">        
      <li>
          <a routerLink="/job-detail/{{customerEstimate.estimate_id}}">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
          </a>
      </li>
      <li class="text-breadcrumb">
          <a class="d-flex" aria-expanded="false" routerLink="/tickets" > 
              <span>Tickets</span>
          </a>
      </li>
      <li class="text-breadcrumb"><span>New Ticket</span></li>
  </ul>
</div>
<!-- CONTENT BREADCRUMB -->


<div class="row layout-top-spacing container-new-ticket">
  <div class="col-12 col-md-6">
    <!-- header -->
    <div class="statbox widget box box-shadow center">

      <div class="widget-header">
        <h4>
          Create New Ticket
        </h4>
      </div>
      <div class="widget-content widget-content-area">  
        <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>
          <div class="row">
            <div class="col-12">

              <div class="form-group mb-4 ">
                <label class="control-label">Subject</label>
                <select name="type" class="form-control" [(ngModel)]="dataForm.ticket_type_id" (change)="prioritySelect()">
                  <option *ngFor="let ticketType of ticketTypes" value="{{ticketType.id}}">
                      {{ticketType.name | titlecase}} 
                  </option>
                </select>
            
              </div>

              <div class="form-group mb-4">
                <label class="control-label">Description</label>
                <textarea name="description_ticket" id="description_ticket" rows="4" [(ngModel)]="dataForm.description"
                  class="form-control"></textarea>
              </div>

              <div class="form-group mt-4">
                <label class="control-label">Upload Images or Files</label>
                <input type="file" id="input-file-max-fs" multiple [appDropify] (onChange)="onChangeLogo($event)" class="dropify" 
                  data-max-file-size="2M" />
                <div class="post-gallery-img d-flex mt-2" *ngIf="dataForm.files.length > 0">
                  <div class="box-preview-image" *ngFor="let fil of dataForm.files">                    
                    <div class="delete-button-file" (click)="deleteImageItem(fil)">
                      <i class="fa-solid fa-xmark"></i>
                    </div>
                    <img src="{{environment.api.base}}/tmp/{{fil.display_name}}" alt="avatar">  
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="text-center mt-5">
            <button class="btn btn-save" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>


  </div>
</div>