import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';


declare const jQuery;

@Directive({
    selector: '[appS3Link]'
})
export class S3LinkDirective {


    @Input()
    appS3Link: string

    constructor(private authService: AuthService, private element: ElementRef) {
        this.appS3Link = null;
    }

    ngOnChanges(changes: SimpleChanges) {
        // verificamos si se recibio algo
        if (changes.appS3Link.currentValue !== null && changes.appS3Link.currentValue !== undefined) {

            // establecemos ese algo
            this.setLink(changes.appS3Link.currentValue);
        } else {

            // eliminamos el que tenga la html
            this.removeLink();
        }
    }

    /**
     * Permite establecer el enlace en la etiqueta html
     * @param link 
     */
    private setLink(link: string) {

        if (jQuery(this.element.nativeElement).is('a')) {
            jQuery(this.element.nativeElement).attr('href', this.transform(link));
        }

        if (jQuery(this.element.nativeElement).is('img')) {
            jQuery(this.element.nativeElement).attr('src', this.transform(link));
        }

        if (jQuery(this.element.nativeElement).is('iframe')) {
            jQuery(this.element.nativeElement).attr('src', this.transform(link));
        }
    }

    /**
     * Permite retirar el link de la etiqueta html
     */
    private removeLink() {
        if (jQuery(this.element.nativeElement).is('a')) {
            jQuery(this.element.nativeElement).removeAttr('href');
        }

        if (jQuery(this.element.nativeElement).is('img')) {
            jQuery(this.element.nativeElement).removeAttr('src');
        }

        if (jQuery(this.element.nativeElement).is('iframe')) {
            jQuery(this.element.nativeElement).removeAttr('src');
        }
    }


    /**
     * Permite complementar el link a establecer en la etiqueta html
     * @param postLink 
     */
    private transform(postLink: string): string {

        if (postLink == null) {
            return null;
        }

        if (this.authService.session == null) {
            return null;
        }

        return environment.az_api.cdn + '/' + this.authService.session.workspace.id + postLink;
    }

}
