export class SwipeportSetting {

    public id: string;
    public security_key: string;

    constructor() {
        this.id = null;
        this.security_key = null;
    }
}
