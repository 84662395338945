
<!-- CONTENT BREADCRUMB -->
<div class="breadcrumb-four">
    <ul class="breadcrumb">
        <li><a routerLink="/dashboard"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg></a></li>
        <li class="active"><a href=""><span>Jobs</span></a></li>
    </ul>
</div>
<!-- CONTENT BREADCRUMB -->

<div id="content" class="main-content">

    <div class="layout-px-spacing-head pad-0">
        <div class="row" id="cancel-row">
  
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing header-table">
                <div class="widget-content widget-content-area widget-content-head br-6 py-2">
                    <table id="invoice-list" class="table table-hover m-0" style="width:100%">
                        <thead>
                            <tr>                                
                                <th>Job</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>            
        
            <div id="resize"></div>
  
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing body-table">
                <div class="widget-content widget-content-area br-6">
                    <table id="invoice-list" class="table table-hover" style="width:100%">                        
                        <tbody>
                           
                            <tr>
                                <td class="tdHeader headerCollapse">
                                    <button class="btn-arrow rotate"><i class="fas fa-chevron-down"></i></button>                                    
                                    <div>
                                        <span class="key">Job: </span>RR00004
                                        <span class="badge badge-warning ml-3">Pending</span>
                                    </div>
                                </td>                                
                                <td class="rowCollapse" data-label="Customer">
                                  <div class="arrow-down"></div>
                                  <div><span class="key">Job: </span>RR00004</div>
                                  <div><span class="key">Service: </span>LONG</div>
                                </td>
                                <td class="rowCollapse" data-label="From">
                                  <div class="text-right">
                                    IN 46312
                                  </div>
                                  <div class="content-between">
                                      <span class="inv-date-start mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"
                                              class="feather feather-calendar">
                                              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                              <line x1="16" y1="2" x2="16" y2="6"></line>
                                              <line x1="8" y1="2" x2="8" y2="6"></line>
                                              <line x1="3" y1="10" x2="21" y2="10"></line>
                                          </svg>
                                      </span>
                                      Jun 22nd 2021
                                  </div>
                                  <div class="content-between">
                                      <span class="inv-date-end mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"
                                              class="feather feather-calendar">
                                              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                              <line x1="16" y1="2" x2="16" y2="6"></line>
                                              <line x1="8" y1="2" x2="8" y2="6"></line>
                                              <line x1="3" y1="10" x2="21" y2="10"></line>
                                          </svg>
                                      </span>
                                      Jun 25th 2021
                                  </div>
                                </td>
                                <td class="rowCollapse" data-label="To">
                                  <div class="text-right">
                                    TX 77590
                                  </div>
                                  <div class="content-between">
                                      <span class="inv-date-start mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"
                                              class="feather feather-calendar">
                                              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                              <line x1="16" y1="2" x2="16" y2="6"></line>
                                              <line x1="8" y1="2" x2="8" y2="6"></line>
                                              <line x1="3" y1="10" x2="21" y2="10"></line>
                                          </svg>
                                      </span>
                                      May 19th 2021
                                  </div>
                                  <div class="content-between">
                                      <span class="inv-date-end mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"
                                              class="feather feather-calendar">
                                              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                              <line x1="16" y1="2" x2="16" y2="6"></line>
                                              <line x1="8" y1="2" x2="8" y2="6"></line>
                                              <line x1="3" y1="10" x2="21" y2="10"></line>
                                          </svg>
                                      </span>
                                      May 19th 2021
                                  </div>
                                </td>
                                <td class="rowCollapse t-align" data-label="Status">
                                    <span class="badge badge-success">
                                        On Quote
                                    </span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Action">
                                    <button class="btn btn-info m-0" data-toggle="modal" data-target="#modalPaymentMethod">View</button>                         
                                </td>                                
                            </tr>
                           
                            <tr>
                                <td class="tdHeader headerCollapse">
                                    <button class="btn-arrow rotate"><i class="fas fa-chevron-down"></i></button>                                    
                                    <div>
                                        <span class="key">Job: </span>RR00004
                                        <span class="badge badge-warning ml-3">Pending</span>
                                    </div>
                                </td>                                
                                <td class="rowCollapse" data-label="Customer">
                                  <div class="arrow-down"></div>
                                  <div><span class="key">Job: </span>RR00004</div>
                                  <div><span class="key">Service: </span>LONG</div>
                                </td>
                                <td class="rowCollapse" data-label="From">
                                  <div class="text-right">
                                    IN 46312
                                  </div>
                                  <div class="content-between">
                                      <span class="inv-date-start mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"
                                              class="feather feather-calendar">
                                              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                              <line x1="16" y1="2" x2="16" y2="6"></line>
                                              <line x1="8" y1="2" x2="8" y2="6"></line>
                                              <line x1="3" y1="10" x2="21" y2="10"></line>
                                          </svg>
                                      </span>
                                      Jun 22nd 2021
                                  </div>
                                  <div class="content-between">
                                      <span class="inv-date-end mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"
                                              class="feather feather-calendar">
                                              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                              <line x1="16" y1="2" x2="16" y2="6"></line>
                                              <line x1="8" y1="2" x2="8" y2="6"></line>
                                              <line x1="3" y1="10" x2="21" y2="10"></line>
                                          </svg>
                                      </span>
                                      Jun 25th 2021
                                  </div>
                                </td>
                                <td class="rowCollapse" data-label="To">
                                  <div class="text-right">
                                    TX 77590
                                  </div>
                                  <div class="content-between">
                                      <span class="inv-date-start mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"
                                              class="feather feather-calendar">
                                              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                              <line x1="16" y1="2" x2="16" y2="6"></line>
                                              <line x1="8" y1="2" x2="8" y2="6"></line>
                                              <line x1="3" y1="10" x2="21" y2="10"></line>
                                          </svg>
                                      </span>
                                      May 19th 2021
                                  </div>
                                  <div class="content-between">
                                      <span class="inv-date-end mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"
                                              class="feather feather-calendar">
                                              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                              <line x1="16" y1="2" x2="16" y2="6"></line>
                                              <line x1="8" y1="2" x2="8" y2="6"></line>
                                              <line x1="3" y1="10" x2="21" y2="10"></line>
                                          </svg>
                                      </span>
                                      May 19th 2021
                                  </div>
                                </td>
                                <td class="rowCollapse t-align" data-label="Status">
                                    <span class="badge badge-success">
                                        On Quote 
                                    </span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Action">
                                    <button class="btn btn-info m-0" data-toggle="modal" data-target="#modalPaymentMethod">View</button>                         
                                </td>                                
                            </tr>                            
  
                        </tbody>
                    </table>                    
  
                </div>
            </div>
  
        </div>
    </div>
  </div>
  <!--  END CONTENT AREA  -->
  
  
 