import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/app/entities/customer';
import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { TicketView } from 'src/app/entities/ticket-view';
import { AuthService } from 'src/app/services/auth.service';
import { CustomersTicketsService } from 'src/app/services/customers-tickets.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare var moment;
@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

    allTickets: Array<TicketView>;
    customer: Customer;
    oneDayTiketDisabled : boolean;
    labelTime: string;
    public customerEstimate: CustomerEstimate;

    
    constructor (
    private helperService: HelperService,
    private authService: AuthService,
    private customersTicketsService: CustomersTicketsService) {
        this.allTickets = [];
        this.customer = new Customer();
        this.oneDayTiketDisabled = true;
        this.labelTime = '';
    }
    actualPage = 1;

    ngOnInit(): void {
        this.loadTickets();
        this.loadCustomer();
        this.customerEstimate = this.helperService.loadCustomerServiceLocalStorage();
    }

    loadCustomer(){
        this.customer = this.authService.session.customer;
    }

    loadTickets() {
        // this.helperService.showLoadingMxpro360();
        this.customersTicketsService
            .getAll(this.authService.session.company.id)
            .then((response) => {
                this.allTickets = response;
                if (this.allTickets.length > 0) {
                    setInterval(() => {
                        this.oneDayTiketDisabled = moment(this.allTickets[0].created).add(1, 'day').unix() * 1000 < moment().unix() * 1000;
                        this.labelTime = moment( moment(this.allTickets[0].created).add(1, 'day').unix() * 1000  - moment().unix() * 1000).format('H:mm:ss');
                    }, 1000);
                }

                this.rowCollapse();

                setTimeout(() => {          
                this.toggleCollapse();          
                }, 100);
                
            })
            .catch((error) => {
                this.helperService.showMessageError(error, '');
            }).finally(() => {
                // this.helperService.hideLoadingMxpro360();
            });
    }


    /**
     * Se alterna entre agregar y eliminar el Collapse a los row
     */
  private toggleCollapse() {
    jQuery('#invoice-list tbody').on('click', '.headerCollapse', function () {
        jQuery(this).nextUntil('tr.header').slideToggle(500);
        jQuery(this).children('.rotate').toggleClass('down')
    });

    jQuery(window).resize(function () {
        if (jQuery(window).width() > 1024) {                
            jQuery("table tbody tr td").removeAttr("style");
        }
        else {
            jQuery(".tdHeader").addClass("headerCollapse");
            jQuery(".rowCollapse").css("display", "none");  
        }
    }); 
  }

  /**
   * Se define como estado inicial el Collapse para los row
   */
  private rowCollapse(){
      setTimeout(() => {
          if (jQuery(window).width() < 1024) {                
                  jQuery(".tdHeader").addClass("headerCollapse");
                  jQuery(".rowCollapse").css("display", "none");                
          }
          else {
              jQuery(".tdHeader").removeClass("headerCollapse");
              jQuery("table tbody tr td").removeAttr("style");
          }
      }, 50);
  }

}
