import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    constructor() { }

    async transform(items: Array<any> = [], search: string = null): Promise<any> {
        // Se realiza la busqueda por medio de una pormesa, implementado el pipe como un
        // AsyncPipe. En el llamado del pipe en el html se llama el pipe y se llama el async
        // Ejemplo, nombreDelPipe | async
        return await this.searchWithWorker(items, search);
    }

    /**
     * Realiza la busqueda con un web worker
     *
     * @param items Items donde se va a seralizar la busqueda
     * @param search Filtro de bisqueda que se va a realizar
     */
    private searchWithWorker(items: Array<any>, search: string = null): Promise<any> {
        return new Promise((resolve, reject) => {
            if (typeof Worker !== 'undefined') {
                // Se crea el worker
                const worker = new Worker(new URL('src/app/workers/search.worker', import.meta.url), { type: 'module' });
                // Se envian los datos de busqueda al worker
                worker.postMessage({ items, search });
                // Se recibe el resultado del worker y se resuelve la promesa con la busqueda realizada
                worker.onmessage = (data) => {
                    // Se agrega un temporizador para retornar los resultados en la vista
                    setTimeout(() => {
                    resolve(data.data);
                    }, 200);
                };
            } else {
                reject();
            }
        });
    }

}
