import { Company } from 'src/app/entities/company';
import { PaymentProfile } from 'src/app/entities/payment-profile';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BalanceView } from '../../entities/balance-view';
import { CustomerPayment } from '../../entities/customer-payment';
import { PaymentMethod } from '../../entities/payment-method';
import { ResponseTransaction } from '../../entities/response-transaction';
import { BalancesService } from '../../services/balances.service';
import { PaymentsService } from '../../services/payments.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

declare const jQuery;
declare const moment;

const units: string[] = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
const teens: string[] = ['', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
const tens: string[] = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

@Component({
  selector: 'app-payments-ach',
  templateUrl: './payments-ach.component.html',
  styleUrls: ['./payments-ach.component.scss']
})
export class PaymentsAchComponent implements OnInit {

  public paymentProfile: PaymentProfile;

  public paymentMethod: PaymentMethod;

  public balance: BalanceView;

  private idPaymentMethod: string;

  public tabBank: string;
  public tabResult: string;
  public tabSign: string;

  public responseTransaction: ResponseTransaction;
  readonly environment = environment;

  public dateNow = '';

  public numberToString: string;
  public signature: File;
  

  constructor(
    public authService: AuthService,
    private balancesService: BalancesService,
    private currentRoute: ActivatedRoute,
    private paymentsService: PaymentsService,
    private helperService: HelperService
    ) {
    this.paymentMethod = new PaymentMethod();
    this.paymentProfile = new PaymentProfile();
    this.tabBank = 'active';
    this.tabResult = 'inactive';
    this.tabSign = 'inactive';
    this.balance = new BalanceView();
    this.responseTransaction = new ResponseTransaction();
    this.dateNow = moment().format('MM/DD/YYYY');
    this.numberToString = '';
    this.signature = null;
  }

  ngOnInit(): void {
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id_balance !== 'undefined' && typeof params.id_method !== 'undefined') {
        this.idPaymentMethod = params.id_method;
        this.loadPaymentMethod(params.id_method);
        this.loadPayment(params.id_balance);
      }
    });
  }


  /**
   * Permite escuchar la firma
   */
  onSignature(signature: File) {
    this.signature = signature;
  }

  private loadPaymentMethod(idMethod: string): void {
    this.helperService.showLoadingMxpro360();
    this.paymentsService.
      getPaymentMethod(idMethod).
      then(async (response) => {
        this.paymentMethod = response;
      })
      .catch((error) => {
        this.helperService.showMessageError(error, idMethod);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private loadPayment(id) {
    this.helperService.showLoadingMxpro360();
    this.balancesService
      .getPendingBalanceById(id)
      .then((response) => {
        this.balance = response;
        
        this.numberToWords(this.balance.amount);
      })
      .catch((error) => {
        this.helperService.showMessageError(error, id);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  /**
   * captura la informacion del formulario de tarjeta para preauthorizar un pago
   */
  sendPaymentAuthorizationAch() {
    this.tabSign = "active";
    this.tabBank = "inactive";
    this.helperService.showLoadingMxpro360();

    // const holder_birthday = this.paymentProfile.card_holder.birth_date.split('-');
    // const format_holder_birthday = holder_birthday[1] + '/' + holder_birthday[2] + '/' + holder_birthday[0];
    // this.paymentProfile.credit_card.number = Number(this.paymentProfile.credit_card.number.toString().replace(/\s/g, '').substr(0, 16));
    const data: any = {
      JOB_NUMBER: this.balance.job_number,
      AMOUNT_BALANCE: this.balance.amount,
      ESTIMATE_PICKUP_DATE: this.balance.pickup_date,
      SIGNATURE_DATE: this.balance.created,
      balance_id: this.balance.id,
      job_id: this.balance.job_id,
      company_id: this.balance.company_id,
      CUSTOMER_FULL_NAME: this.paymentProfile.bank_information.account_name,
      CUSTOMER_EMAIL: this.paymentProfile.card_holder.email,
      CUSTOMER_PHONE: this.paymentProfile.card_holder.phone,
      ACH_ROUTING: this.paymentProfile.bank_information.routing_number,
      ACH_ACCOUNT: this.paymentProfile.bank_information.account_number
    };

    this.paymentsService.
      sendPaymentAuthorizationAch(data, this.balance.company_id).
      then((response) => {
        jQuery('#filePfd').attr('src', environment.az_api.cdn + '/' + this.authService.session.workspace.id +
          '/documents/' + response.document.file.display_name);
      })
      .catch((error) => {
        this.helperService.showMessageError(error, {data: data, balanceCompanyId: this.balance.company_id});
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  /**
   * Permite registrar firma para pago
   */
  saveFormData() {

    const formData = new FormData();
    formData.append('files[]', this.signature);
    jQuery.ajax({
      url: environment.api.base + '/files',
      type: 'post',
      dataType: 'json',
      data: formData,
      cache: false,
      contentType: false,
      processData: false
    })
      .done((response) => {
        this.sendPayment(response);
      });
  }


  public sendPayment(signature) {
    this.helperService.showLoadingMxpro360();
    // inicializa datos para post de pagos
    const dataPost: CustomerPayment = new CustomerPayment();


    dataPost.reference = this.balance.id.substring(4, 24);
    dataPost.amount = this.balance.amount;
    dataPost.payment_profile.bank_information = this.paymentProfile.bank_information;
    dataPost.payment_profile.billing_address = this.paymentProfile.billing_address;
    dataPost.payment_profile.card_holder = this.paymentProfile.card_holder;


    if (this.signature) {
      dataPost.esignature.esignature_file.name = signature.files[0];
      dataPost.esignature.esignature_file.display_name = signature.files[0];
      dataPost.esignature.internet_browser = this.helperService.detectBrowser();
    }

    // envia pago a api
    this.paymentsService.
      sendPayment(this.balance.id, this.idPaymentMethod, dataPost).
      then(async (response) => {
        this.responseTransaction = response;
        this.tabSign = "inactive";
        this.tabBank = "active";
        this.changeTab();
      })
      .catch((error) => {
        this.helperService.showMessageError(error, {balance: this.balance.id, idPaymentMethod: this.idPaymentMethod, dataPost: dataPost});
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  public changeTab(): void {
    // Realiza el cambio de tab al dar clic en next
    if (this.tabBank === 'active') {
      this.tabBank = 'inactive';
      this.tabResult = 'active';
    }
  }

  private convertToWords(num: number): string {
    if (num < 20) {
      return units[num];
    } else if (num < 100) {
      return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? '-' + units[num % 10] : '');
    } else {
      const hundred: number = Math.floor(num / 100);
      const remainder: number = num % 100;
      return units[hundred] + ' hundred' + (remainder !== 0 ? ' and ' + this.convertToWords(remainder) : '');
    }
  }

  private numberToWords(number: number): void {
  
    const integerPart: number = Math.floor(number);
    const decimalPart: number = Math.round((number - integerPart) * 100);
    const integerWords: string = this.convertToWords(integerPart);
    const decimalWords: string = this.convertToWords(decimalPart);
    const result: string = integerWords + ' dollars and ' + decimalWords + ' cents';
    this.numberToString = result;
  }
  
}
