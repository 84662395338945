import { EstimateEmail } from 'src/app/entities/estimate-email';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EstimatesEmailsCustomerService {

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getEstimatesEmail(estimateDocumentId: string) {
    return this.http.get<EstimateEmail>(environment.api.customers + '/estimates/' + estimateDocumentId + '/emails').toPromise();
  }

  getPPWEstimatesEmail(estimateDocumentId: string) {
    return this.http.get<EstimateEmail>(environment.api.customers + '/estimates/' + estimateDocumentId + '/ppw-emails').toPromise();
  }
}
