export class AdminUser {
    public id: string;
    public username: string;
    public password: string;
    public active: boolean;
    public role: "ADMIN" | "SALESMAN" | "SUPPORT"

    constructor() {
        this.id = null;
        this.username = null;
        this.password = null;
        this.active = false;
        this.role = null;
    }

}
