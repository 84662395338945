import { File } from './file';
import { Document } from './document';

export class Ticket {
    id: string;
    document: Document;
    ticket_type_id: string;
    customer_id: string;
    estimate_id: string;
    employee_id: string;
    work_department_id: string;
    status: 'OPEN' | 'PENDING' | 'RESOLVED' | 'CLOSED' | 'WAITING_COSTUMER';
    priority: 'LOW' | 'HALF' | 'HIGH' | 'URGENT';   
    subject: string;
    description: string;
    files: Array<File>;
    company_id: string;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.document = new Document();
        this.ticket_type_id = null;
        this.customer_id = null;
        this.estimate_id = null;
        this.employee_id = null;
        this.work_department_id = null;
        this.status = 'OPEN';
        this.priority = 'LOW';
        this.subject = null;
        this.description = null;
        this.files = [];
        this.company_id = null;
        this.created = null;
        this.updated = null;
    }
}
