<!-- CONTENT BREADCRUMB -->
<div class="breadcrumb-four mt-1">
    <ul class="breadcrumb">        
        <li>
            <a routerLink="/job-detail/{{customerEstimate.estimate_id}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
            </a>
        </li>
        <li class="text-breadcrumb"><span>Tickets</span></li>
    </ul>
</div>
<!-- CONTENT BREADCRUMB -->




<div class="title-ticket-history">
    <h3>Ticket History</h3>
    <span>Recent Ticket</span>
</div>

<div class="text-right mt-3 btn-new-ticket" *ngIf="oneDayTiketDisabled">
    <a routerLink="/tickets/id/add" class="btn btn-warning width-100">
        <i class="fas fa-plus-square"></i>
        New Ticket
    </a>
</div>

<div class="text-right mt-3 btn-new-ticket" *ngIf="!oneDayTiketDisabled">
    <a class="btn btn-danger width-100">
        New Ticket Available: {{labelTime}}
    </a>
</div>



<div id="content" class="main-content">

    <div class="layout-px-spacing-head-tickets pad-0" *ngIf="allTickets.length > 0">
        <div class="row" id="cancel-row">
  
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing header-table">                

                <div class="widget-content widget-content-area widget-content-head br-6 py-2 marg-t24">
                    <table id="invoice-list" class="table table-hover m-0" style="width:100%">
                        <thead>
                            <tr>                
                                <th>Ticket Number</th>                
                                <th>Job</th>
                                <th>Subject</th>
                                <th>Last Activity</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>            
        
            <div id="resize"></div>
  
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing body-table">
                <div class="widget-content widget-content-area br-6">
                    <table id="invoice-list" class="table table-hover" style="width:100%">                        
                        <tbody>
                           
                            <tr *ngFor="let ticket of allTickets ">
                                <td class="tdHeader headerCollapse">
                                    <button class="btn-arrow rotate"><i class="fas fa-chevron-down"></i></button>                                    
                                    <div>
                                        <span class="key">Job: </span>{{ticket.document.code}}                           
                                        <span class="badge badge-warning ml-3" *ngIf="ticket.status == 'OPEN'">
                                            {{ticket.status}}
                                        </span>
                                        <span class="badge badge-info ml-3" *ngIf="ticket.status == 'PENDING'">
                                            {{ticket.status}}
                                        </span>
                                        <span class="badge badge-success ml-3" *ngIf="ticket.status == 'RESOLVED'">
                                            {{ticket.status}} 
                                        </span>
                                        <span class="badge badge-danger ml-3" *ngIf="ticket.status == 'CLOSED'">
                                            {{ticket.status}}
                                        </span>
                                        <span class="badge badge-secondary ml-3" *ngIf="ticket.status == 'WAITING_COSTUMER'">
                                            {{ticket.status}}
                                        </span>
                                    </div>
                                </td>                                
                                <td class="rowCollapse" data-label="Customer">
                                  <div class="arrow-down"></div>
                                  <div><span class="key">Ticket: </span>{{ticket.document.code}}</div>
                                </td>
                                <td class="rowCollapse" data-label="Customer">
                                  <div><span class="key">Job: </span>{{ticket.estimate_document.code}}</div>
                                  <div><span class="key">Name: </span>{{customer.name}} {{customer.last_name}}</div>
                                  <div><span class="key">Service: </span>LONG</div>
                                </td>                                
                                <td class="rowCollapse" data-label="Concept">
                                    <div class="concept text-justify"><br class="break">
                                        {{ticket.subject}}
                                    </div>
                                </td>
                                <td class="rowCollapse" data-label="Concept">
                                    <div class="concept text-justify"><br class="break">
                                        {{ticket.created | dateTime}}
                                    </div>
                                </td>
                                <td class="rowCollapse t-align" data-label="Status">
                                    <span class="badge badge-warning" *ngIf="ticket.status == 'OPEN'">
                                        {{ticket.status}}
                                    </span>
                                    <span class="badge badge-info" *ngIf="ticket.status == 'PENDING'">
                                        {{ticket.status}}
                                    </span>
                                    <span class="badge badge-success" *ngIf="ticket.status == 'RESOLVED'">
                                        {{ticket.status}} 
                                    </span>
                                    <span class="badge badge-danger" *ngIf="ticket.status == 'CLOSED'">
                                        {{ticket.status}}
                                    </span>
                                    <span class="badge badge-secondary" *ngIf="ticket.status == 'WAITING_COSTUMER'">
                                        {{ticket.status}}
                                    </span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Action">
                                    <a routerLink="/tickets/{{ticket.id}}/view" class="btn btn-info m-0">View</a>                         
                                </td>                                
                            </tr>
                        </tbody>
                    </table>                    
  
                </div>
            </div>
  
        </div>
    </div>

    <div class="layout-px-spacing-head no-tickets" *ngIf="allTickets.length == 0">



        <img src="../../../assets/img/empty_box.svg" alt="">
        <div class="txt-no-ticket">
            <h3>It´s Empty Here</h3>
            <span>You Have No Tickets</span> 
        </div>
    </div>

</div>
  <!--  END CONTENT AREA  -->
  