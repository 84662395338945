export class EstimateOperator {
    public id: string;
    public work_department_id: string;
    public salesman_id: string;
    public operator_id: string;

    /**
     * Constructor de la clase
     */
    constructor() {
        this.id = null;
        this.work_department_id = null;
        this.salesman_id = null;
        this.operator_id = null;
    }
}
