import { ConstantsMessages } from './../../constants-messages';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

import { CustomerEstimate } from '../../entities/customer-estimate';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

declare const jQuery;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public customerEstimate: CustomerEstimate;
    public logoEstimate: string;
    readonly environment = environment;

    constructor(
        public authService: AuthService,
        private helperService: HelperService,
        private router: Router
    ) {
        this.logoEstimate = '';
    }

    ngOnInit(): void {
        this.customerEstimate = this.helperService.loadCustomerServiceLocalStorage();
        this.logoEstimate = this.authService.session.company.logo;
    }

    public logout(): void {
        localStorage.removeItem('customer_estimate');
        this.helperService.showMessageSnackbar(ConstantsMessages.SESSION_CLOSED)
        this.router.navigateByUrl('/login?workspace=' + this.authService.session.workspace.id);
        localStorage.removeItem('session');
    }

}
