import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BalancesService } from 'src/app/services/balances.service';
import { BalanceView } from 'src/app/entities/balance-view';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Customer } from 'src/app/entities/customer';
import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { CustomersJobsService } from 'src/app/services/customers-jobs.service';
import { environment } from 'src/environments/environment';
import { Estimate } from 'src/app/entities/estimate';
import { EstimateCustomerRemark } from 'src/app/entities/estimate-customer-remark';
import { EstimateCustomerRemarkView } from 'src/app/entities/estimate-customer-remark-view';
import { EstimateDocument } from 'src/app/entities/estimate-document';
import { EstimateEmail } from 'src/app/entities/estimate-email';
import { EstimatesCustomerRemarksService } from 'src/app/services/estimates-customer-remarks.service';
import { EstimatesEmailsCustomerService } from 'src/app/services/estimates-emails-customer.service';
import { HelperService } from 'src/app/services/helper.service';
import { NewBindingService } from 'src/app/services/new-binding-service';
import { PaymentMethod } from 'src/app/entities/payment-method';
import { PaymentMethodView } from 'src/app/entities/payment-method-view';
import { DocumentPPWService } from 'src/app/services/document-ppw.service';

declare const jQuery: any;
@Component({
    selector: 'app-estimates-view',
    templateUrl: './estimates-view.component.html',
    styleUrls: ['./estimates-view.component.scss']
})

export class EstimatesViewComponent implements OnInit {

    @ViewChild('signModal') signModal: ElementRef;
    public balances: Array<BalanceView>;
    public companySettings: any;
    public customer: Customer;
    public customerEstimate: CustomerEstimate;
    public customerRemark: string;
    public document: any;
    public documentNewBinding: any;
    public estimateCustomerRemark: EstimateCustomerRemark;
    public estimateCustomerRemarks: Array<EstimateCustomerRemarkView>;
    public estimateEmail: EstimateEmail;
    public flagButtonSignature: string;
    public job: Estimate;
    public jobId: string;
    public oneBalance: BalanceView;
    public paymentMethod: PaymentMethodView;
    public remark: string;
    public signature: File;
    public signatured: boolean;
    public signedPPW: boolean;
    public storageInvoice: any;
    readonly environment = environment;

    constructor(
        private balancesService: BalancesService,
        private currentRoute: ActivatedRoute,
        private customersJobsService: CustomersJobsService,
        private documentPPWService: DocumentPPWService,
        private estimatesCustomerRemarksService: EstimatesCustomerRemarksService,
        private estimatesEmailsCustomerService: EstimatesEmailsCustomerService,
        private helperService: HelperService,
        private newBindingService: NewBindingService,
        private router: Router,
        public authService: AuthService,
    ) {
        this.balances = [];
        this.companySettings = null;
        this.customer = new Customer();
        this.customerEstimate = new CustomerEstimate();
        this.customerRemark = '';
        this.document = '';
        this.documentNewBinding = null;
        this.estimateCustomerRemark = new EstimateCustomerRemark();
        this.estimateCustomerRemarks = [];
        this.estimateEmail = new EstimateEmail();
        this.flagButtonSignature = 'NOT_SIGNED';
        this.job = new Estimate();
        this.jobId = '';
        this.oneBalance = new BalanceView();
        this.paymentMethod = new PaymentMethodView();
        this.remark = '';
        this.signature = null;
        this.signedPPW = false;
        this.storageInvoice = null;
    }

    ngOnInit(): void {
        // verificamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id_estimate !== 'undefined') {
                this.load(params.id_estimate);
            }
        });

        this.loadPaymentList();
        this.customer = this.authService.session.customer;
    }


    payPendingPayment() {
        for( let balance of this.balances ) {            
            if ( balance.status == "PENDING" ) {
                this.oneBalance = balance;                
                break;
            }
        }
    }



    /**
   * consulta el arreglo del listado de pagos
   */
    loadPaymentList() {
    const customerEstimate = this.helperService.loadCustomerServiceLocalStorage();
    this.helperService.showLoadingMxpro360();
    const data = {
      company_id: this.authService.session.company.id,
      estimate_id: customerEstimate.estimate_id
    };

    this.balancesService
      .getAllCustomer(data)
      .then((response) => {
        this.balances = response;
        this.payPendingPayment();
        /* this.rowCollapse(); */
      })
      .catch((error) => {
        this.helperService.showMessageError(error, data);

      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
    }

    loadStorageInvoice(id: string, company_id: string) {
        this.balancesService
        .getStorageInvoice(id, company_id)
        .then((response) => {
            this.storageInvoice = response;
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

    loadPaymentMethods(balance: BalanceView) {
        this.paymentMethod.id = balance.id;
        this.paymentMethod.payment_methods = balance.payment_methods;
    
        for (const paymentMethod of balance.payment_methods) {
          switch (paymentMethod.method) {
            case 'CREDIT_CARD':
              paymentMethod['logo'] = 'assets/img/credit-card-method.png';
              break;
    
            case 'BANK_TRANSFER_ONLINE':
              if (paymentMethod.name.toLowerCase() === 'ach') {
                paymentMethod['logo'] = 'assets/img/ach-method.png';
              } else {
                paymentMethod['logo'] = 'assets/img/bank-transfer-method.png';
              }
              break;
    
            case 'BANK_TRANSFER_OFFLINE':
              if (paymentMethod.name.toLowerCase() === 'zelle') {
                paymentMethod['logo'] = 'assets/img/zelle-method.png';
              } else {
                paymentMethod['logo'] = 'assets/img/bank-transfer-method.png';
              }
              break;
    
            case 'CHECK':
              paymentMethod['logo'] = 'assets/img/check-method.png';
              break;
          }
        }
    }

    public redirectToPayment(paymentMethod: PaymentMethod, idBalance: string) {
        this.helperService.redirectToPayment(paymentMethod, idBalance, null);
    }


    loadEstimateEmail(): void {
        this.helperService.showLoadingMxpro360();
        this.estimatesEmailsCustomerService
            .getEstimatesEmail(this.customerEstimate.estimate_id)
            .then(response => {
                this.estimateEmail = response;
                if (response.request_ppw) {
                    this.getPPWDocument();
                }
            })
            .catch((error) => {
                this.helperService.showMessageError(error, this.job.id);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        // this.helperService.showLoadingMxpro360();
    }

    getPPWDocument() {
        this.documentPPWService
        .getDocumentPPW(this.jobId, this.estimateEmail.ppw_document_type_id)
        .then(response => {
            this.signedPPW = response.document.esignatures.main !== null;;
        });
            
    }

    private load(idEstimate: string) {
        this.loadDocumentNewBinding(idEstimate);
        this.jobId = idEstimate;
        this.helperService.showLoadingMxpro360();
        this.customerEstimate = this.helperService.loadCustomerServiceLocalStorage();
        this.customersJobsService.getById(this.customerEstimate.estimate_id, this.authService.session.company.id, this.customerEstimate.id)
            .then((response) => {
                this.job = response['estimate'];
                this.loadStorageInvoice(idEstimate, this.job.company_id);
                this.loadEstimateEmail();
                this.loadCustomerRemarks(this.job.id);
                this.signatured = response['estimate'].signatured;
                this.document = response['document'];
                this.companySettings = response['company_settings']['policy_setting'];
                
                if (this.document.indexOf('@CUSTOMER_SIGNATURE') >= 0 && response['estimate'].request_signature == false) {
                    this.flagButtonSignature = 'SIGNATURE_NOT_AVAILABLE';
                } else if (this.document.indexOf('@CUSTOMER_SIGNATURE') < 0 && response['estimate'].request_signature == false) {
                    this.flagButtonSignature = 'SIGNED';
                } else {
                    this.flagButtonSignature = 'NOT_SIGNED';
                }
                this.estimatePdf(this.customerEstimate.id);
            })
            .catch((error) => {
                this.helperService.showMessageError(error, {customerEstimate: this.customerEstimate.estimate_id, companyId: this.authService.session.company.id, customer: this.customerEstimate.id});
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    openSignature() {
        this.signature = null;
        jQuery(this.signModal.nativeElement).modal('show');
    }

    closeSignature() {
        this.signature = null;
        jQuery(this.signModal.nativeElement).modal('hide');
    }

    print() {
        const printContent = document.getElementById("printer");
        const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
        WindowPrt.close();
    }

    downloadPdf() {
        const customerEstimate: CustomerEstimate = this.helperService.loadCustomerServiceLocalStorage();

        if (this.job.service === 'AUTO_TRANSPORT') {
            this.helperService.downloadReport('AUTO_TRANSPORT_CUSTOMERS', customerEstimate.id);
        }

        if (this.job.service === 'LONG') {
            this.helperService.downloadReport('LONG_ESTIMATES_CUSTOMER', customerEstimate.id);
        }

        if (this.job.service === 'LOCAL') {
            this.helperService.downloadReport('LOCAL_ESTIMATES_CUSTOMER', customerEstimate.id);
        }
    }


    async save() {

        const formData = new FormData();
        formData.append('files[]', this.signature);
        jQuery.ajax({
            url: environment.api.base + '/files',
            type: 'post',
            dataType: 'json',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        })
            .done((sign) => {

                let data: EstimateDocument;
                data = new EstimateDocument();
                data.document.esignature.esignature_file.name = sign.files[0];
                data.document.esignature.esignature_file.display_name = sign.files[0];
                data.document.esignature.internet_browser = this.helperService.detectBrowser();

                this.helperService.showLoadingMxpro360();
                this.customersJobsService
                    .eSignature(this.job.id, data, this.authService.session.company.id)
                    .then(response => {
                        this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
                        this.signatured = true;
                        this.load(this.jobId);
                    })
                    .catch((error) => {
                        console.error('error', error);
                        this.helperService.showMessageError(error, {jobNumber: this.job.id, data: data, companyId: this.authService.session.company.id});
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });
            });
    }

    private loadCustomerRemarks(id: string) {
        // this.helperService.showLoadingMxpro360();
        this.estimatesCustomerRemarksService
            .getAllWithFullView(id)
            .then((response) => {
                this.estimateCustomerRemarks = response;
            })
            .catch((error) => {
                this.helperService.showMessageError(error, id);
            })
            .finally(() => {
                // this.helperService.hideLoadingMxpro360();
            });
    }

    addCustomerRemarks() {
        if (!this.estimateCustomerRemark.remarks) {
            return;
        }
        this.helperService.showLoadingMxpro360();
        this.estimatesCustomerRemarksService
            .add(this.job.id, this.estimateCustomerRemark)
            .then((response) => {
                this.loadCustomerRemarks(this.job.id);
            })
            .catch((error) => {
                console.error('error', error);
                this.helperService.showMessageError(error, {jobNumber: this.job.id, estimateCustomer: this.estimateCustomerRemark});
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        this.estimateCustomerRemark = new EstimateCustomerRemark();
    }

    // Crea el pdf en la vista
    estimatePdf(customerEstimate) {

        if (this.job.service === 'AUTO_TRANSPORT') {
            jQuery('#estimatePdf').attr('src', environment.api.customers + '/estimates/' + customerEstimate +
                '/export-pdf-autotransport?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token);
        }

        if (this.job.service === 'LONG') {
            jQuery('#estimatePdf').attr('src', environment.api.customers + '/estimates/' + customerEstimate + '/export-pdf-quote?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token);
        }

        if (this.job.service === 'LOCAL') {
            jQuery('#estimatePdf').attr('src', environment.api.customers + '/estimates/' + customerEstimate +
                '/export-pdf-estimate-local?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token);
        }

        if (this.job.service === 'FREIGHT') {
            jQuery('#estimatePdf').attr('src', environment.api.customers + '/estimates/' + customerEstimate +
                '/export-pdf-freight?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token);
        }
    }

    public openDocumentPOA(): void {
        this.router.navigateByUrl('/customer/document-poa/' + this.job.id);
    }

    /**
     * Permite escuchar la firma
     */
    onSignature(signature: File) {
        this.signature = signature;
        this.helperService.showMessageSnackbar(ConstantsMessages.SIGNED);
    }

    loadDocumentNewBinding(idJob: string): void {
        this.helperService.showLoadingMxpro360();    
        this.newBindingService
          .getDocument(idJob)
          .then(response => {
            this.documentNewBinding = response['document'];
          })
          .catch((error) => {
            console.error('load new binding error ', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      }

      exportStorageInvoicePdf() {
        window.open(environment.api.customers + '/estimates/' + this.storageInvoice.job_id + '/export-storage-invoice/' + this.storageInvoice.id + '?Authorization=' +
          this.authService.session.token, '_blank');
      }
   
}
