export class Workspace {

    public id: string;
    public name: string;
    public created: number;
    public updated: number;

    constructor(){
        this.id = null;
        this.name = null;
        this.created = null;
        this.updated = null;
    }
}
