import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TicketRemark } from '../entities/ticket-remark';
import { TicketRemarkView } from '../entities/ticket-remark-view';


@Injectable({
    providedIn: 'root'
})
export class TicketsRemarksService {
    constructor(private http: HttpClient) {
    }

    getAll(ticket_id: any): Promise<Array<TicketRemarkView>> {
        return this.http.get<Array<TicketRemarkView>>(environment.api.customers + '/tickets/' + ticket_id + '/remarks').toPromise();
    }

    getById(ticket_id: any, id: any) {
        return this.http.get<TicketRemark>(environment.api.customers + '/tickets/' + ticket_id + '/remarks' + id).toPromise();
    }

    save(ticket_id: any, dataForm: TicketRemark): Promise<any> {
        if (dataForm.id === null) {
            return this.add(ticket_id, dataForm);
        }
    }

    add(ticket_id: any, dataForm: TicketRemark) {
        return this.http.post(environment.api.customers + '/tickets/' + ticket_id + '/remarks', dataForm).toPromise();
    }

    remove(ticket_id: any, id: any) {
        return this.http.delete(environment.api.customers + '/tickets/' + ticket_id + '/remarks/' + id).toPromise();
    }

}
