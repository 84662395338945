import { AuthService } from 'src/app/services/auth.service';
import { BalancesService } from 'src/app/services/balances.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ElectronicSignature } from 'src/app/entities/electronic-signature';

declare const jQuery;
declare const swal;
@Component({
  selector: 'app-sign-credit-card-payments',
  templateUrl: './sign-credit-card-payments.component.html',
  styleUrls: ['./sign-credit-card-payments.component.scss']
})
export class SignCreditCardPaymentsComponent implements OnInit {

  readonly environment = environment;

  /*
  contiene los datos del pago
  */
  public signature: File;

  private balanceId: string;
  private displayName: string

  constructor(
    private currentRoute: ActivatedRoute,
    private balancesService: BalancesService,
    private helperService: HelperService,
    public authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.currentRoute.params.subscribe(params => {
      this.sendSignature(params);
    });
  }

  ngAfterViewInit(): void { }


  sendSignature(params) {
    this.helperService.showLoadingMxpro360();
    this.balancesService.getEstimateDocumentsByBalanceId(params.id_balance)
      .then((response) => {
        this.displayName = response.document.file.display_name;
        jQuery('#filePfd').attr('src', environment.az_api.cdn + '/' + this.authService.session.workspace.id +
          '/documents/' + this.displayName);
        this.balanceId = params.id_balance
      })
      .catch((error) => {
        this.helperService.showMessageError(error, params.id_balance);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * Permite escuchar la firma
   */
  onSignature(signature: File) {
    this.signature = signature;
  }

  save() {
    const formData = new FormData();
    formData.append('files[]', this.signature);
    jQuery.ajax({
      url: environment.api.base + '/files',
      type: 'post',
      dataType: 'json',
      data: formData,
      cache: false,
      contentType: false,
      processData: false
    }).done((sign) => {
      let esignature: ElectronicSignature;
      esignature = new ElectronicSignature();
      esignature.esignature_file.name = sign.files[0];
      esignature.esignature_file.display_name = sign.files[0];
      esignature.internet_browser = this.helperService.detectBrowser();

      const data = {
        'esignature': esignature,
        'balance_id': this.balanceId
      }

      this.helperService.showLoadingMxpro360();
      this.balancesService.saveEsignature(data)
        .then((response: any) => {
          swal({
            text: response.message,
          });
          this.router.navigateByUrl('/balances');
        })
        .catch((error) => {
          console.error('error =>', error);
          this.helperService.showMessageError(error, this.balanceId);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
    });
  }

  viewJAF(){
    window.open(environment.az_api.cdn + '/' + this.authService.session.workspace.id +
    '/documents/' + this.displayName, '_blank');
    
  }
}


