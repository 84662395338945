import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

declare const jQuery;
declare const Dropzone;

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss']
})
export class FileUploadModalComponent implements OnInit {

  /**
     * Permite operar el contenedor de la modal
     */
   @ViewChild('FileUploadModal')
   innerModal: ElementRef<HTMLDivElement>;

   /**
    * Permite operar el elmento que se usara para la instacia de dropzone
    */
   @ViewChild('dropZoneJs')
   dropZone: ElementRef<HTMLDivElement>;

   /**
    * indica el eleento donde se mostrara los archivos a subir
    */
   @ViewChild('previewFile')
   previewFile: ElementRef<HTMLDivElement>;

   /**
    * se utiliza para detectar cuando se hace cierre la modal,
    * de esta manera se puede aplicar el destroy desde el servicio, 
    * y de paso pasar los archivos subidos
    */
   onClose: EventEmitter<Array<string>>;

   /**
    * Con este metodo se identifica cuando esta listo para trabajarlo
    */
   onReady: EventEmitter<null>;

   /**
    * Contiene los archivos que se van a subir
    */
   files: Array<File>;

   /**
    * Contiene los nombres de los archivos subidos
    */
   uploadedFiles: Array<string>;

   /**
    * manipula la instancia de la libreria de dropzone 
    */
   instanceDropZone: any;

   /**
    * Indica si esta subiendo los archivos
    */
   isUploading: boolean;


  constructor() {
      this.isUploading = false;
      this.onClose = new EventEmitter();
      this.onReady = new EventEmitter();
      this.instanceDropZone = null;
      this.files = [];
      this.uploadedFiles = [];
  }

  ngOnDestroy(){
    delete this.isUploading;
    delete this.onClose;
    delete this.onReady;
    delete this.instanceDropZone;
    delete this.files;
    delete this.uploadedFiles;   
}


  ngOnInit(): void {

  }


  /**
   * Cuando se termine de renderizar indicamos que esta listo
   */
  ngAfterViewInit() {

      // inicializamos el drop zone
      this.initDropZone();

      // inidicamos que ya esta listo esta modal
      this.onReady.emit();
  }

  /**
   * Permite inicializar el plugin  dropzonejs
   */
  initDropZone() {

      // obtenemos una copia de la plantilla
      const templateFile = jQuery(this.previewFile.nativeElement).html();

      // ocultamos la plantilla de la vista
      jQuery(this.previewFile.nativeElement).children().remove();

      // instanciamos el dropzone
      this.instanceDropZone = new Dropzone(this.dropZone.nativeElement, {
          uploadMultiple: true,
          url: environment.api.base + '/files', 
          paramName: 'files',
          thumbnailWidth: 80,
          thumbnailHeight: 80,
          parallelUploads: 20,
          previewTemplate: templateFile,
          autoQueue: false, // Make sure the files aren't queued until manually added
          previewsContainer: this.previewFile.nativeElement, //"#previews", // Define the container to display the previews
          //clickable: ".fileinput-button" // Define the element that should be used as click trigger to select files.
          dictDefaultMessage: "Drop files here or click to upload",
      });

      // establecemos los listeners
      this.setListenersDropZone();

  }

  /**
   * Configura los listeners para los eventos a trabajar
   */
  private setListenersDropZone() {

      this.instanceDropZone.on("sending", (file) => {
          // Deshabilitamos el boton de cancelar por que despues de subido el archivo que no haya vuelta a atras
          file.previewElement.querySelector(".cancel").setAttribute("disabled", "disabled");
      });

      // Evento para cuando se finalize la subia de archivos
      this.instanceDropZone.on("success", (progress, responseText) => {

          // cerramos la modal pasado el segundo para que haya tiempo de visualizar que se cargo correctamente las imagenes
          setTimeout(() => {
              
              // cerramos la modal, y devolvemos los archivos que fueron subidos
              jQuery(this.innerModal.nativeElement).modal('hide');

              try {
                  // enviamos el resultado.. por ahora se coloca la ejecucion en un try por que parece que se esta ejecutando varias veces...
                  this.onClose.emit(responseText.files);
              } catch (error) {
                  
              }
          }, 1000); 
      });

  }

  /**
   * Permite guardar los archivos seleccionados
   */
  save() {
      this.isUploading = true;
      this.instanceDropZone.enqueueFiles(this.instanceDropZone.getFilesWithStatus(Dropzone.ADDED));
  }

  /**
   * la idea es que tenga este metodo para abrir la modal desde el servicio (control remoto)
   */
  open() {
      // abrimos la modal
      jQuery(this.innerModal.nativeElement).modal('show');
  }

  /**
  * Permite cerrar la modal y a su ves devolver el saldo que le queda al usuario
  * eso para que se pueda actualizar en la vista sin necesidad de vovler a cargar la informacion de este
  */
  close() {
      this.instanceDropZone.removeAllFiles(true);
      jQuery(this.innerModal.nativeElement).modal('hide');
      this.onClose.emit([]);
  }

}
