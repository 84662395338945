import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { EstimateView } from "src/app/entities/estimate-view";
import { environment } from "src/environments/environment";
import { Estimate } from "src/app/entities/estimate";
import { ElectronicSignature } from "src/app/entities/electronic-signature";

@Injectable({
  providedIn: 'root'
})
export class CustomersJobsService {

    constructor(private http: HttpClient) { }

    getAll(companyId): Promise<Array<EstimateView>> {
        return this.http.get<Array<EstimateView>>(environment.api.customers + '/estimates?company_id=' + companyId).toPromise();
    }

    getById(id: string, companyId: string, idCustomerEstimate: string) {
        return this.http.get<Estimate>(environment.api.customers + '/estimates/' + id + '?company_id=' + companyId + '&customer_estimate=' + idCustomerEstimate).toPromise();
    }

    eSignature(id: string, data, companyId: string) {
        return this.http.post<any>(environment.api.customers + '/estimates/' + id +
            '/documents?company_id=' + companyId, data).toPromise();
    }
}
