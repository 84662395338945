<div id="content" class="main-content d-flex justify-content-center">
    <div class="justify-content-center">
        <div class="row layout-top-spacing" id="cancel-row">
            <div class="col-lg-12 layout-spacing">
                <div class="statbox widget box box-shadow">
                    <div class="widget-header">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12 title-payment-method">
                                <h4>Payment Credit Card</h4>
                            </div>
                        </div>
                    </div>

                    <div class="widget-content widget-content-area">

                        <!-- Seccion de firma del authorize -->
                        <ng-container>
                            <!-- <h3>Authorization</h3> -->
                            <section class="row d-flex">
                                <div
                                    class="d-flex col-8 container-image-credit-card justify-content-center align-items-center">

                                    <iframe class="signature-mobile" id="filePfd" style="width:970px; height:650px;"
                                        title="Authorization">
                                    </iframe>

                                </div>

                                <div class="col-12 btn-view-jaf">
                                    <button class="btn btn-secondary" (click)="viewJAF()">
                                        View JAF
                                    </button>
                                </div>

                                <div class="col-12 col-md-12 col-lg-4 container-form-credit-card">
                                    <div class="mt-3 mb-3 section-info">
                                        <h4 class="m-0">Signature</h4>
                                        <span>Sign the credit card payment authorization here</span>
                                    </div>
                                    <div class="form-credit-card form-padd padd-30">
                                        <app-sign (onSignature)="onSignature($event)"></app-sign>
                                    </div>

                                </div>
                            </section>
                        </ng-container>
                        <!-- Fin de la seccion de firma del authorize -->

                        <!-- Seccion de botones next and preview -->
                        <div class="col-xl-12 col-md-12 col-sm-12 col-12 mb-12 next-and-preview">
                            <button [disabled]="!signature" class="btn btn-success" (click)="save()">
                                Save
                            </button>
                        </div>
                        <!-- Fin de seccion de botones next and preview -->
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>