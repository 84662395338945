import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InventoryCategory } from '../entities/inventory-category';

@Injectable({
  providedIn: 'root'
})
export class CustomersInventoryCategoriesService {

  
    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<InventoryCategory>> {
        return this.http.get<Array<InventoryCategory>>(environment.api.customers + '/inventory-categories').toPromise();
    }

    finishInventory(estimateId: string) {
      return this.http.post(environment.api.customers + '/estimates/' + estimateId + '/inventory-finished', {}).toPromise();
    }
}
