import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { Estimate } from 'src/app/entities/estimate';
import { Ticket } from 'src/app/entities/ticket';
import { TicketType } from 'src/app/entities/ticket-type';
import { AuthService } from 'src/app/services/auth.service';
import { EstimatesService } from 'src/app/services/estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { environment } from 'src/environments/environment';

declare var jQuery;

@Component({
  selector: 'app-tickets-form',
  templateUrl: './tickets-form.component.html',
  styleUrls: ['./tickets-form.component.scss']
})
export class TicketsFormComponent implements OnInit {

  public customerEstimate: CustomerEstimate;
  /*
  Constantes que contiene el tipo de mensaje a mostrar
  */
 constantsMessages = ConstantsMessages;

 dataForm: Ticket;

 ticketTypes : Array<TicketType>;
 readonly environment = environment;

 constructor(
   private ticketsService: TicketsService,
   private router: Router,
   private currentRoute: ActivatedRoute,
   private authService: AuthService,
   private estimatesService: EstimatesService,
   public helperService: HelperService
 ) {
   this.dataForm = new Ticket();
   this.dataForm.employee_id = this.authService.session.employee.id;
   this.ticketTypes = [];
 }

 ngOnInit(): void {
   this.currentRoute.parent.params.subscribe(params => {
     if (typeof params.id !== 'undefined') {
       this.dataForm.estimate_id = params.id;
       this.loadEstimate(params.id);
     }
   });
    this.loadTypes();
   this.customerEstimate = this.helperService.loadCustomerServiceLocalStorage();
 }

 save() {
     this.dataForm.company_id = this.authService.session.company.id;
     let estimateId =   JSON.parse(localStorage.getItem('customer_estimate'));
     this.dataForm.estimate_id = estimateId.estimate_id;
   this.helperService.showLoadingMxpro360();
   this.ticketsService
     .add(this.dataForm)
     .then(response => {
       this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
       this.router.navigateByUrl('/tickets');
     })
     .catch((error) => {
       this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
       console.error(error);
     })
     .finally(() => {
       this.helperService.hideLoadingMxpro360();
     });
 }

 loadEstimate(id: string) {
   this.helperService.showLoadingMxpro360();
   this.estimatesService
     .getById(id)
     .then((response: Estimate) => {
       this.dataForm.customer_id = response.customer_id;
     })
     .catch((error) => {
       console.error(error);
     })
     .finally(() => {
       this.helperService.hideLoadingMxpro360();
     });
 }

 loadTypes() {
   this.helperService.showLoadingMxpro360();
   this.ticketsService
   .getAllTypes()
   .then(response => {
   this.ticketTypes = response;
   
   })
   .catch((error) => {
     this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
     console.error(error);
   })
   .finally(() => {
     this.helperService.hideLoadingMxpro360();
   });
 }

  prioritySelect(){
    for (const item of this.ticketTypes) {
        if (item.id == this.dataForm.ticket_type_id) {
            this.dataForm.priority = item.priority;
        }
    }
  }

  onChangeLogo(files: Array<string>) {
    if (files.length > 0) {
        const filess = {
            id: null,
            name: files[0],
            display_name: files[0],
            mime_type: 'mime_type',
            created: null
        };
        this.dataForm.files.push(filess);
    }
}


deleteImageItem(image) {
  for (let i = this.dataForm.files.length - 1; i >= 0; i--) {
      if (this.dataForm.files[i].display_name === image.display_name) {
          this.dataForm.files.splice(i, 1);
          break;
      }
  }
}

}
