import { Company } from './company';
import { PaymentTransaction } from './payment-transaction';

export class ResponseTransaction {
    company: Company;
    message: string;
    transaction: PaymentTransaction;

    constructor() {
        this.company = new Company();
        this.message = '';
        this.transaction = new PaymentTransaction();
    }
}
