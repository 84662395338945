import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { ElectronicSignature } from 'src/app/entities/electronic-signature';
import { Estimate } from 'src/app/entities/estimate';
import { EstimateEmail } from 'src/app/entities/estimate-email';
import { AuthService } from 'src/app/services/auth.service';
import { CustomersJobsService } from 'src/app/services/customers-jobs.service';
import { DocumentBolService } from 'src/app/services/document-bol.service';
import { EstimatesEmailsCustomerService } from 'src/app/services/estimates-emails-customer.service';
import { EstimatesService } from 'src/app/services/estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { NewBindingService } from 'src/app/services/new-binding-service';
import { environment } from 'src/environments/environment';
declare var jQuery;
declare var swal;

@Component({
  selector: 'app-new-binding',
  templateUrl: './new-binding.component.html',
  styleUrls: ['./new-binding.component.scss']
})
export class NewBindingComponent implements OnInit {

  job: Estimate;
  document: any;
  signature: File;
  signatured: boolean;
  public flagSignDocuments: string;
  public flagSignRequestForNewEstimate: string;
  public cancellationOfServices: string;
  public jobId: string;
  readonly environment = environment;
  public remark: string;
  public customerEstimate: CustomerEstimate;

  public estimateEmail: EstimateEmail;

  public designate_fullname: string;

  public customer_fullname: string;
  public designate_phonenumber: string;
  private bolSignatureRequested: string

  @ViewChild('signaturePriorPickupModal')
  signaturePriorPickupModal: ElementRef;


  constructor(
    private currentRoute: ActivatedRoute,
    private customersJobsService: CustomersJobsService,
    public authService: AuthService,
    private router: Router,
    private estimatesService: EstimatesService,
    private helperService: HelperService,
    private newBindingService: NewBindingService,
    private estimatesEmailsCustomerService: EstimatesEmailsCustomerService,
    private documentBolService: DocumentBolService
  ) {
    this.job = new Estimate();
    this.document = '';
    this.jobId = '';
    this.remark = '';
    this.flagSignDocuments = 'NOT_SIGNED';
    this.flagSignRequestForNewEstimate = 'SIGNATURE_NOT_AVAILABLE';
    this.cancellationOfServices = 'SIGNATURE_NOT_AVAILABLE';
    this.bolSignatureRequested = "";
    this.estimateEmail = new EstimateEmail();
    this.signature = null;
  }

  ngOnInit(): void {    
    // verficamos si se esta editando
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id_estimate !== 'undefined') {
        this.loadDocument(params.id_estimate);
        this.jobId = params.id_estimate;
      }
    });

    this.customerEstimate = this.helperService.loadCustomerServiceLocalStorage();
  }

  loadDocument(idJob: string): void {
    // this.helperService.showLoadingMxpro360();

    this.newBindingService
      .getDocument(idJob)
      .then(response => {
        this.loadestimateEmail(idJob);
        this.customer_fullname = this.authService.session.customer.name + ' ' + this.authService.session.customer.last_name;
        this.document = response['document'];

        // this.estimatePdf(customerEstimate.id);

        jQuery('#estimatePdf').attr('src', environment.az_api.cdn + '/' + this.authService.session.workspace.id + '/documents/' + response.document.file.display_name);
      })
      .catch((error) => {
        if (error.status == 422) {
          swal({
            title: 'Warning!',
            text: error.error.message,
            type: 'warning',
            padding: '2em'
          })
        } else {
          this.helperService.showMessageError(error, idJob);
        }
      })
      .finally(() => {
        // this.helperService.hideLoadingMxpro360();
      });
  }


  loadestimateEmail(idJob): void {
    this.helperService.showLoadingMxpro360();
    this.estimatesEmailsCustomerService
      .getEstimatesEmail(idJob)
      .then(response => {

        // validaciones para Sign Document (Request For New Estimate)
        if (this.document.esignatures.third) {          
          this.cancellationOfServices = 'SIGNED';
        } else {
          this.cancellationOfServices = 'NOT_SIGNED';
        }

        // validaciones para Sign Document (Request For New Estimate)
        if (this.document.esignatures.second) {          
          this.flagSignRequestForNewEstimate = 'SIGNED';
        } else {
          this.flagSignRequestForNewEstimate = 'NOT_SIGNED';
        }

        // validaciones para Sign Document (Documents)
        if (this.document.esignatures.main) {          
          this.flagSignDocuments = 'SIGNED';
        } else {
          this.flagSignDocuments = 'NOT_SIGNED';
        }
        
      })
      .catch((error) => {
        this.helperService.showMessageError(error, this.job.id);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
    // this.helperService.showLoadingMxpro360();
  }

  // Modales para las firmas del BOL
  // Document Prior to Pickup
  openSignature(type: string) {
    this.signature = null;
    this.bolSignatureRequested = type;
    jQuery(this.signaturePriorPickupModal.nativeElement).modal('show');
  }

  closeSignature() {
    this.signature = null;
    jQuery(this.signaturePriorPickupModal.nativeElement).modal('hide');
  }

  print() {
    const printContent = document.getElementById("printer");
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

  downloadPdf() {
    this.helperService.downloadReport('CONTRACT_INFORMATION', null, this.document);
  }


  async save() {
    this.helperService.showLoadingMxpro360();
    const formData = new FormData();

    formData.append('files[]', this.signature);
    jQuery.ajax({
      url: environment.api.base + '/files',
      type: 'post',
      dataType: 'json',
      data: formData,
      cache: false,
      contentType: false,
      processData: false
    })
      .done((sign) => {

        let esignature: ElectronicSignature;
        esignature = new ElectronicSignature();
        esignature.esignature_file.name = sign.files[0];
        esignature.esignature_file.display_name = sign.files[0];
        esignature.internet_browser = this.helperService.detectBrowser();

        const data = {
          electronic_signature: esignature,
          type: this.bolSignatureRequested
        }
        // this.helperService.showLoadingMxpro360();
        this.newBindingService
          .signDocument(this.jobId, data)
          .then(response => {

            this.loadDocument(this.jobId);

          })
          .catch((error) => {
            this.helperService.showMessageError(error, this.jobId);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      });
  }

  // Crea el pdf en la vista
  estimatePdf(customerEstimate) {

    if (this.job.service === 'AUTO_TRANSPORT') {
      jQuery('#estimatePdf').attr('src', environment.api.customers + '/estimates/' + customerEstimate +
        '/export-pdf-autotransport?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token);
    }

    if (this.job.service === 'LONG') {
      jQuery('#estimatePdf').attr('src', environment.api.customers + '/estimates/' + customerEstimate + '/export-pdf-quote?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token);
    }

    if (this.job.service === 'LOCAL') {
      jQuery('#estimatePdf').attr('src', environment.api.customers + '/estimates/' + customerEstimate +
        '/export-pdf-estimate-local?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token);
    }
  }

  /**
   * Permite escuchar la firma
   */
  onSignature(signature: File) {
    this.signature = signature;
    this.helperService.showMessageSnackbar(ConstantsMessages.SIGNED);
  }

}
