import { Rol } from 'src/app/entities/rol';

import { Account } from './account';
import { AdminUser } from './admin-user';
import { Carrier } from './carrier';
import { Company } from './company';
import { Credential } from './credential';
import { Customer } from './customer';
import { Employee } from './employee';
import { Role } from './role';
import { Workspace } from './workspace';

export class Session {


    token: string;
    context: 'ADMIN' | 'ACCOUNT' | 'CUSTOMER';
    adminUser: AdminUser;
    account: Account;
    employee: Employee;
    customer: Customer;
    workspace: Workspace;
    carrier: Carrier;
    role: Role; // eliminar este campo cuando se haya retirado las dependicias
    company: Company; // eliminar este campo cuando se haya retirado las dependicias

    constructor() {
        this.token = null;
        this.context = null;
        this.adminUser = new AdminUser();
        this.account = new Account();
        this.employee = new Employee();
        this.customer = new Customer();
        this.workspace = new Workspace();
        this.carrier = new Carrier();
        this.role = new Role(); // eliminar este campo cuando se haya retirado las dependicias
        this.company = new Company(); // eliminar este campo cuando se haya retirado las dependicias
    }
}
