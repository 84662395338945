import { NgxPaginationModule } from 'ngx-pagination';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { PanelLayoutComponent } from './components/panel-layout/panel-layout.component';
import { PaymentVoucherComponent } from './components/payment-voucher/payment-voucher.component';
import { SearchAddressComponent } from './components/search-address/search-address.component';
import { SignComponent } from './components/sign/sign.component';
import { S3LinkDirective } from './directives/s3-link.directive';
import { Select2Directive } from './directives/select2.directive';
import { FileUploadModalComponent } from './modals/file-upload-modal/file-upload-modal.component';
import { BalancesViewComponent } from './pages/balances-view/balances-view.component';
import { BalancesComponent } from './pages/balances/balances.component';
import { DocumentPoaComponent } from './pages/document-poa/document-poa.component';
import { EstimatesViewComponent } from './pages/estimates-view/estimates-view.component';
import { EstimatesComponent } from './pages/estimates/estimates.component';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PaymentsAchComponent } from './pages/payments-ach/payments-ach.component';
import {
    PaymentsCreditCardComponent
} from './pages/payments-credit-card/payments-credit-card.component';
import { PaymentsOfflineComponent } from './pages/payments-offline/payments-offline.component';
import { TicketsFormComponent } from './pages/tickets-form/tickets-form.component';
import { TicketsViewComponent } from './pages/tickets-view/tickets-view.component';
import { TicketsComponent } from './pages/tickets/tickets.component';
import { CalendarDateTimePipe } from './pipes/calendar-date-time.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { DatePipe } from './pipes/date.pipe';
import { FilterBetweenPipe } from './pipes/filter-between.pipe';
import { FilterReplacePipe } from './pipes/filter-replace.pipe';
import { NoSanitizePipe } from './pipes/no-sanitize.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { StripTagsHtmlPipe } from './pipes/strip-tags-html.pipe';
import { TeamEmployeeFilterPipe } from './pipes/team-employee-filter.pipe';
import { ZeroFillPipe } from './pipes/zero-fill.pipe';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { DropifyDirective } from './directives/dropify.directive';
import { InventoryItemComponent } from './pages/inventory-item/inventory-item.component';
import { SignCreditCardPaymentsComponent } from './pages/sign-credit-card-payments/sign-credit-card-payments.component';
import { DocumentBolComponent } from './pages/document-bol/document-bol.component';
import { MovingMediaFilesComponent } from './pages/moving-media-files/moving-media-files.component';
import { FilePreviewerComponent } from './components/file-previewer/file-previewer.component';
import { SingFromEmailComponent } from './pages/sing-from-email/sing-from-email.component';
import { NewBindingComponent } from './pages/new-binding/new-binding.component';
import { SignFromStorageComponent } from './pages/sign-from-storage/sign-from-storage.component';
import { DocumentPPWComponent } from './pages/document-ppw/document-ppw.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        PanelLayoutComponent,
        DateTimePipe,
        NoSanitizePipe,
        Select2Directive,
        ZeroFillPipe,
        SearchPipe,
        OrderByPipe,
        DatePipe,
        PhonePipe,
        CalendarDateTimePipe,
        FilterBetweenPipe,
        TeamEmployeeFilterPipe,
        StripTagsHtmlPipe,
        S3LinkDirective,
        SearchAddressComponent,
        FooterComponent,
        PanelLayoutComponent,
        PaymentVoucherComponent,
        LoginComponent,
        EstimatesComponent,
        BalancesComponent,
        PageNotFoundComponent,
        TicketsComponent,
        TicketsFormComponent,
        TicketsViewComponent,
        BalancesViewComponent,
        EstimatesViewComponent,
        PaymentsCreditCardComponent,
        FilterReplacePipe,
        PaymentsOfflineComponent,
        PaymentsAchComponent,
        FileUploadModalComponent,
        SignComponent,
        DocumentPoaComponent,
        DropifyDirective,
        InventoryItemComponent,
        SignCreditCardPaymentsComponent,
        DocumentBolComponent,
        MovingMediaFilesComponent,
        FilePreviewerComponent,
        SingFromEmailComponent,
        NewBindingComponent,
        SignFromStorageComponent,
        DocumentPPWComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        NgxPaginationModule,
    ],
    providers: [

        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        }

    ],
    bootstrap: [
        AppComponent,
        FileUploadModalComponent
    ]
})
export class AppModule { }
