import { BalancesComponent } from './pages/balances/balances.component';
import { BalancesViewComponent } from './pages/balances-view/balances-view.component';
import { DocumentBolComponent } from './pages/document-bol/document-bol.component';
import { DocumentPoaComponent } from './pages/document-poa/document-poa.component';
import { EstimatesComponent } from './pages/estimates/estimates.component';
import { EstimatesViewComponent } from './pages/estimates-view/estimates-view.component';
import { InventoryItemComponent } from './pages/inventory-item/inventory-item.component';
import { LoginComponent } from './pages/login/login.component';
import { MovingMediaFilesComponent } from './pages/moving-media-files/moving-media-files.component';
import { NewBindingComponent } from './pages/new-binding/new-binding.component';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PanelLayoutComponent } from './components/panel-layout/panel-layout.component';
import { PaymentsAchComponent } from './pages/payments-ach/payments-ach.component';
import { PaymentsCreditCardComponent } from './pages/payments-credit-card/payments-credit-card.component';
import { PaymentsOfflineComponent } from './pages/payments-offline/payments-offline.component';
import { RouterModule, Routes } from '@angular/router';
import { SignCreditCardPaymentsComponent } from './pages/sign-credit-card-payments/sign-credit-card-payments.component';
import { SignFromStorageComponent } from './pages/sign-from-storage/sign-from-storage.component';
import { SingFromEmailComponent } from './pages/sing-from-email/sing-from-email.component';
import { TicketsComponent } from './pages/tickets/tickets.component';
import { TicketsFormComponent } from './pages/tickets-form/tickets-form.component';
import { TicketsViewComponent } from './pages/tickets-view/tickets-view.component';
import { DocumentPPWComponent } from './pages/document-ppw/document-ppw.component';

const customerPanelRoutes: Routes = [
    { path: 'jobs', component: EstimatesComponent },
    { path: 'job-detail/:id_estimate', component: EstimatesViewComponent },
    { path: 'inventory-item/:id_estimate', component: InventoryItemComponent },
    { path: 'balances', component: BalancesComponent },
    { path: 'balances/:id/view', component: BalancesViewComponent },
    { path: 'tickets', component: TicketsComponent},
    { path: 'tickets/:id/view', component: TicketsViewComponent},
    { path: 'tickets/:id/add', component: TicketsFormComponent },
    { path: 'special-power-attorney/:id_estimate', component: DocumentPoaComponent },
    { path: 'bill-of-lading/:id_estimate', component: DocumentBolComponent },
    { path: 'ppw/:id_estimate', component:DocumentPPWComponent },
    { path: 'estimate-media-files/:id', component: MovingMediaFilesComponent },
    { path: 'new-binding/:id_estimate', component: NewBindingComponent },

    //Payment Methods
    { path: 'payment/:id_balance/sign-payment', component: SignCreditCardPaymentsComponent },
    { path: 'payment/:id_balance/credit-card/:id_method', component: PaymentsCreditCardComponent },
    { path: 'payment/:id_balance/transfer-online/:id_method', component: PaymentsAchComponent },
    { path: 'payment/:id_balance/transfer-offline/:id_method', component: PaymentsOfflineComponent },

    { path: 'payment/:id_balance/credit-card/:id_method/:partial_amount', component: PaymentsCreditCardComponent },
    { path: 'payment/:id_balance/transfer-online/:id_method/:partial_amount', component: PaymentsAchComponent },
    { path: 'payment/:id_balance/transfer-offline/:id_method/:partial_amount', component: PaymentsOfflineComponent },

    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent }
];

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'login/:id_customer_estimate', component: LoginComponent },
    { path: 'login/:id_customer_estimate/sing/:id', component: SingFromEmailComponent },
    { path: 'login/:id_customer_estimate/balances', component: SignFromStorageComponent },
    { path: '', component: PanelLayoutComponent, children: customerPanelRoutes },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor() { }
}
