export class EstimateCharges {

    public id: string;
    public cubic_feet_total_price: number;
    public monthly_storage_fee: number;
    public packing_materials: number;
    public auto_transport: number;
    public fuel_surcharge_percentage: number;
    public fuel_surcharge: number;
    public packers_packing: number;
    public packers_unpacking: number;
    public insurance: number;

    /**
     * Constructor de la clase
     */
    constructor() {
        this.id = null;
        this.cubic_feet_total_price = 0;
        this.monthly_storage_fee = 0;
        this.packing_materials = 0;
        this.auto_transport = 0;
        this.fuel_surcharge_percentage = 0;
        this.fuel_surcharge = 0;
        this.packers_packing = 0;
        this.packers_unpacking = 0;
        this.insurance = 0;
    }
}
