import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RecurringCustomer } from '../entities/recurring-customer';
import { RecurringCustomerView } from '../entities/recurring-customer-view';

@Injectable({
  providedIn: 'root'
})
export class RecurringCustomersService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Promise<Array<RecurringCustomerView>> {
    return this.http.get<Array<RecurringCustomerView>>(environment.api.app + '/recurring-customers').toPromise();
  }

  add(recurringCustomer: RecurringCustomer) {
    return this.http.post(environment.api.customers + '/recurring-customers', recurringCustomer).toPromise();
  }

  filterByDates(data): Promise<any> {
    return this.http.get<Array<RecurringCustomerView>>(environment.api.app + '/recurring-customers/dates', {params: data}).toPromise();
  }
}
