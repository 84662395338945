import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Balance } from '../entities/balance';
import { BalanceView } from '../entities/balance-view';
import { PaymentMethodView } from '../entities/payment-method-view';

@Injectable({
  providedIn: 'root'
})
export class BalancesService {

  constructor(
    private http: HttpClient
  ) { }

  saveBalance(dataForm: Balance) {
    return this.http.post(environment.api.app + '/balances', dataForm).toPromise();
  }

  /**
   * Metodo que obtiene todos los balances en la web de customers
   */
  getAllCustomer(data) {
    return this.http.get<Array<BalanceView>>(environment.api.customers + '/balances', { params: data }).toPromise();
  }

  /**
   * Metodo que obtiene todos los balances en la web de customers
   */
  getStorageInvoice(id, company_id) {
    return this.http.get<any>(environment.api.customers + '/estimates/' + id + '/storage-invoices-complete/' + company_id).toPromise();
  }

  /**
   * Metodo que obtiene todos los balances en la web
   */
  getAllApp() {
    return this.http.get<Array<BalanceView>>(environment.api.app + '/balances').toPromise();
  }

  /**
   * Metodo que obtiene los balances por id de jobs
   *
   * @param id Id del job
   */
  getById(id) {
    return this.http.get<Array<BalanceView>>(environment.api.app + '/estimates/' + id + '/balances').toPromise();
  }

  /**
   * Metodo que obtiene el detalle de un balance
   *
   * @param id Id del balance
   */
  getDetailBalanceById(id) {
    return this.http.get<BalanceView>(environment.api.customers + '/balances/' + id).toPromise();
  }

  getDetailBalancePaymentById(id: string, paymentId: string) {
    return this.http.get<BalanceView>(`${environment.api.customers}/balances/${id}/payment/${paymentId}`).toPromise();
  }
  /**
   * Metodo que modifica los balances
   */
  setBalance(balance: Balance) {
    return this.http.put(environment.api.app + '/balances/' + balance.id, balance).toPromise();
  }

  /**
  * Metodo que obtiene los balances por id de jobs
  *
  * @param id Id del job
  */
  getPendingBalanceById(id) {
    return this.http.get<BalanceView>(environment.api.customers + '/balances/' + id + '/pending').toPromise();
  }

  /**
   * Metodo que obtiene los metodos de pago poe balance
   *
   * @param id Id del job
   */
  getPaymentMethods(id) {
    return this.http.get<PaymentMethodView>(environment.api.customers + '/payments-methods-by-balance/' + id).toPromise();
  }

  /**
  * Metodo que obtiene el estimate_documents por balance
  *
  * @param id Id del job
  */
   getEstimateDocumentsByBalanceId(id) {
    return this.http.get<any>(environment.api.customers + '/balances/payment_profile/' + id).toPromise();
  }

  saveEsignature(dataForm: any) {
    return this.http.post(environment.api.customers + '/balances/esignature/sign', dataForm).toPromise();
  }
}
