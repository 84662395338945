export class EstimateRouteTariff {
    public id: string;
    public cf_cost: number;
    public cf_discount: number;
    public packing_cost: number;
    public packing_discount: number;
    public route_rate_id: string;
    public route_volume_id: string;

    /**
     * Constructor de la clase
     */
    constructor() {
        this.id = null;
        this.cf_cost = null;
        this.cf_discount = null;
        this.packing_cost = null;
        this.packing_discount = null;
        this.route_rate_id = null;
        this.route_volume_id = null;
    }
}
