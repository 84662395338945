import { Pipe, PipeTransform } from '@angular/core';

declare const moment;

@Pipe({
    name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

    transform(milliseconds: number): string {
        if (milliseconds == null) {
            return null;
        }
        return moment(milliseconds).format('MMM Do YYYY hh:mm A');
    }

}
