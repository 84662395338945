export class BankInformation {
    public id: string;
    public account_name: string;
    public bank_name: string;
    public routing_number: number;
    public account_number: number;

    constructor() {
        this.id = null;
        this.account_name = null;
        this.bank_name = null;
        this.routing_number = null;
        this.account_number = null;
    }
}
