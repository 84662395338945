<div class="title-customer">
    <h3>Welcome</h3>
    <span>{{customer.name}} {{customer.last_name}}</span>
</div>

<div class="estimate-document">
    Job Estimate No.
    <span class="job-estimate"> {{job.document.code}}</span>
</div>

<div id="content" class="main-content container-estimate-view">
    <div class="layout-px-spacing">

        <div class="row invoice layout-spacing">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 padd-0">

                <div class="doc-container">

                    <div class="row">

                        <div class="col-xl-9">
                            <!-- web -->
                            <div class="invoice-content estimate-pdf">
                                <div class="invoice-detail-body">
                                    <iframe class="signature-mobile" id="estimatePdf" style="width:100%;"
                                        title="Autorization">
                                    </iframe>
                                </div>
                            </div>
                            <!-- mobile -->
                            <div class="container-download-estimate invoice-actions-btn mb-4">                                
                                <div class="download-estimate">                                
                                    <i class="fas fa-file-pdf"></i> 
                                    <span class="text-center pt-1">Moving Estimate Document</span>
                                    <button class="btn btn-success mt-2" (click)="downloadPdf()">View Here</button>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3">

                            <div class="main-container-btn-opt">

                                <div class="invoice-actions-btn container-btn-opt download-btn-bg">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div class="col-12 job-code title-btn-opt px-4">
                                                Job No. <span class="code"> {{job.document.code}} </span> 
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons download-btn-opt">
                                                <a href="javascript:void(0);" class="btn btn-secondary btn-download btn-job-estimate" (click)="downloadPdf()">
                                                    <i class="fa-solid fa-cloud-arrow-down d-wb-none"></i>
                                                    <span>Download Document</span> 
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="invoice-actions-btn container-btn-opt pay-btn-bg">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div class="col-12 job-concept title-btn-opt px-4">
                                                Last Payment Pending
                                                <div class="concept"> {{oneBalance.concept}} </div> 
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons pay-btn-opt" *ngIf="oneBalance.amount">
                                                <a href="javascript:void(0);" class="btn btn-danger btn-send btn-job-estimate" data-toggle="modal" data-target="#modalPaymentMethod" (click)="loadPaymentMethods(oneBalance)">                                                
                                                    <i class="fa-solid fa-money-check-dollar d-wb-none"></i>
                                                    <span class="pay">
                                                        <span>Pay</span> 
                                                        <span> {{ oneBalance.amount | currency }}</span>                                                         
                                                    </span>
                                                </a>
                                            </div> 
                                            <div class="col-xl-12 col-md-4 margin-buttons pay-btn-opt" *ngIf="!oneBalance.amount">
                                                <a class="btn btn-danger btn-send btn-no-payments btn-job-estimate" disabled >                                                
                                                    <i class="fa-solid fa-money-check-dollar d-wb-none"></i>
                                                    <span class="pay">No Pending Payments</span>
                                                </a>
                                            </div>  
                                        </div>
                                    </div>
                                </div>

                                <div class="invoice-actions-btn container-btn-opt signature-btn-bg">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div class="col-12 job-code title-btn-opt px-4">
                                                <span>Accept Job</span> 
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons signature-btn-opt">
                                                <a href="javascript:void(0);" class="btn btn-success btn-send btn-job-estimate" (click)="openSignature()" *ngIf="flagButtonSignature == 'NOT_SIGNED'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span class="sign">
                                                        <span>Sign </span> 
                                                        <span> Moving Estimate</span> 
                                                    </span>                                                    
                                                </a>
                                                <a href="javascript:void(0);" class="btn btn-success btn-send btn-job-estimate" disabled *ngIf="flagButtonSignature == 'SIGNED'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span class="sign">
                                                        <span>Signed </span> 
                                                        <span> Document</span> 
                                                    </span>     
                                                </a>
                                                <a href="javascript:void(0);" class="btn btn-success btn-send btn-job-estimate" disabled *ngIf="flagButtonSignature == 'SIGNATURE_NOT_AVAILABLE'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span class="sign">
                                                        <span>Signature </span> 
                                                        <span> Not Available</span> 
                                                    </span>  
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
         
                                <div class="invoice-actions-btn container-btn-opt pay-btn-bg" *ngIf="estimateEmail.request_ppw && !signedPPW">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div class="col-12 job-concept title-btn-opt px-4">
                                                Sign PPW
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons pay-btn-opt">
                                                <a routerLink="/ppw/{{jobId}}" class="btn btn-primary btn-send btn-job-estimate" data-toggle="modal" data-target="#modalPaymentMethod" (click)="loadPaymentMethods(oneBalance)">                                                
                                                    <i class="fa-solid fa-money-check-dollar d-wb-none"></i>
                                                    <span class="pay">
                                                        <span>Sign Document</span>                                                      
                                                    </span>
                                                </a>
                                            </div> 
                                            <!-- <div class="col-xl-12 col-md-4 margin-buttons pay-btn-opt" *ngIf="!oneBalance.amount">
                                                <a class="btn btn-danger btn-send btn-no-payments btn-job-estimate" disabled >                                                
                                                    <i class="fa-solid fa-money-check-dollar d-wb-none"></i>
                                                    <span class="pay">No Pending Payments</span>
                                                </a>
                                            </div>   -->
                                        </div>
                                    </div>
                                </div>

                                <div class="invoice-actions-btn container-btn-opt special-power-btn-bg" *ngIf="estimateEmail.request_poa">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div class="col-12 job-code title-btn-opt px-4">
                                                <span>Appoint Special Power Attorney</span> 
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons special-power-btn-opt">
                                                <a routerLink="/special-power-attorney/{{jobId}}" class="btn btn-info btn-preview btn-job-estimate">
                                                    <i class="fa-solid fa-file-signature d-wb-none"></i>
                                                    <span class="special-sign">
                                                        <span>Special </span> 
                                                        <span>Power Attorney</span> 
                                                    </span> 
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="invoice-actions-btn container-btn-opt special-power-btn-bg" *ngIf="estimateEmail.request_bol">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div class="col-12 job-code title-btn-opt px-4">
                                                <span>Interstate Bill Of Lading Contract</span> 
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons special-power-btn-opt">
                                                <a routerLink="/bill-of-lading/{{jobId}}" class="btn btn-info btn-preview btn-job-estimate">
                                                    <i class="fa-solid fa-file-signature d-wb-none"></i>
                                                    <span class="special-sign">
                                                        <span>Bill </span> 
                                                        <span>Of Lading</span> 
                                                    </span> 
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="invoice-actions-btn container-btn-opt special-power-btn-bg" *ngIf="documentNewBinding">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div class="col-12 job-code title-btn-opt px-4">
                                                <span>New Binding</span> 
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons special-power-btn-opt">
                                                <a routerLink="/new-binding/{{jobId}}" class="btn btn-warning btn-preview btn-job-estimate">
                                                    <i class="fa-solid fa-file-signature d-wb-none"></i>
                                                    <span class="special-sign">
                                                        <span>Sign New Binding </span> 
                                                    </span> 
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                            <div class="invoice-actions-btn mt-4" *ngIf="estimateEmail.request_inventory_update && job.service !== 'FREIGHT' && (!job.booked || job.qa_status === 'RELEASE')">
                                <div class="invoice-action-btn">
                                    <div class="row">
                                        <div class="col-12 job-code px-4">
                                            <span>Inventory Item</span> 
                                        </div>
                                        <div class="col-xl-12 col-md-4 margin-buttons">
                                            <a routerLink="/inventory-item/{{jobId}}" class="btn btn-dark btn-preview">
                                                Inventory Item
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="invoice-actions-btn mt-4">
                                <div class="invoice-action-btn">
                                    <div class="row">
                                        <div class="col-12 job-code px-4">
                                            <span>Upload Media Files</span> 
                                        </div>
                                        <div class="col-xl-12 col-md-4 margin-buttons">
                                            <a routerLink="/estimate-media-files/{{jobId}}" class="btn btn-primary btn-preview">
                                                Go to Media Files Section
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice-actions-btn mt-4" *ngIf="storageInvoice">
                                <div class="invoice-action-btn">
                                    <div class="row">
                                        <div class="col-12 job-code px-4">
                                            <span>Storage Invoice</span> 
                                        </div>
                                        <div class="col-xl-12 col-md-4 margin-buttons">
                                            <a (click)="exportStorageInvoicePdf()" class="btn btn-success btn-preview">
                                                View Storage Invoice
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

      
                            <div class="widget widget-activity-five mt-4">

                                <div class="widget-heading">
                                    <h5 class="">Remarks</h5>
    
                                    <button class="btn btn-warning additem" data-toggle="modal" data-target="#addRemark">
                                        Add Remark
                                    </button>

                                </div>
    
                                <div class="widget-content">
    
                                    <div class="w-shadow-top"></div>
    
                                    <div class="mt-container mx-auto pl-0">
                                        <div class="timeline-line">
                                            
                                            <div class="item-timeline timeline-new" *ngFor="let remark of estimateCustomerRemarks">
                                                <div class="t-dot">
                                                    <div class="t-warning">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
                                                    </div>
                                                </div>
                                                <div class="t-content">
                                                    <div class="">
                                                        <h5>{{remark.remarks}}</h5>
                                                        <span class=""></span>
                                                    </div>
                                                    <p>{{remark.created | dateTime}}</p>
                                                </div>
                                            </div>
                                            
                                        </div>                                    
                                    </div>
    
                                    <div class="w-shadow-bottom"></div>
                                </div>
                            </div>
                            
                            
          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal Customer Remark -->
<div class="modal fade" id="addRemark" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                    New Customer Remark
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
            </div>
            <div class="modal-body">

                <div class="form-group mb-4">
                    <label for="">
                        Remark
                    </label>
                    <textarea type="text" rows="8" name="description" class="form-control" [(ngModel)]="estimateCustomerRemark.remarks"></textarea>
                </div>

            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal"><i class="flaticon-cancel-12"></i> Discard</button>
                <button type="button" class="btn btn-warning" data-dismiss="modal" (click)="addCustomerRemarks()">Save</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Add Signature -->
<div class="modal fade" id="addEmail" #signModal tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">

                <div class="mb-4 title-payment-methods">
                    <h5 class="modal-title mb-2">
                        <strong>Add Signature</strong>
                    </h5>
                </div>

                <div class="row" *ngIf="companySettings?.enable_move_siganture_terms">
                    <div class="col-12">
                        <h6 class="modal-title mb-2"> <strong>Terms And Conditions</strong> </h6>
                        <p [innerHtml]="companySettings?.enable_move_siganture_text"></p>
                    </div>
                </div>
                <br>
                <app-sign (onSignature)="onSignature($event)"></app-sign>

            </div>
            <div class="modal-footer">
                <button (click)="closeSignature()" class="btn" data-dismiss="modal">
                    Discard
                </button>
                <button [disabled]="!signature" (click)="save()" data-dismiss="modal" class="btn btn-success">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>



<!-- Modal Payment Method -->
<div class="modal fade" id="modalPaymentMethod" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modalPaymentMethod" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <div class="col-lg-12 layout-spacing">
                    <div class="mb-4 title-payment-methods">
                        <h5 class="modal-title mb-2" id="exampleModalLabel">
                            <strong>Payment Methods</strong>
                        </h5>
                        <span>
                            What type of payment method would you like to use?
                        </span>
                        <button type="button" class="close close-button" data-dismiss="modal" aria-label="Close">
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </button>
                    </div>

                    <a *ngFor="let method of paymentMethod.payment_methods" data-backdrop="false" y data-dismiss="modal"
                        (click)="redirectToPayment(method, paymentMethod.id)">
                        <div class="widget-content widget-content-area method-content cursor mb-2 px-3 py-2">
                            <div class="media d-flex align-items-center">
                                <img class="align-self-center rounded pr-3" src="{{method.logo}}" alt="pic1">
                                <div class="media-body">
                                    <p class="media-text">{{method.name}}</p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-chevron-right">
                                    <polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </div>
                        </div>
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>