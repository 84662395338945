import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Ticket } from '../entities/ticket';
import { TicketView } from '../entities/ticket-view';


@Injectable({
    providedIn: 'root'
})
export class CustomersTicketsService {

    constructor(private http: HttpClient) {

    }

    getAll(companyId): Promise<Array<TicketView>> {
        return this.http.get<Array<TicketView>>(environment.api.customers + '/tickets',{ params: {company_id: companyId}}).toPromise();
    }

    getById(id: string) {
        return this.http.get<Ticket>(environment.api.customers + '/tickets/' + id).toPromise();
    }

    save(dataForm: Ticket): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Ticket) {
        return this.http.post(environment.api.customers + '/tickets', dataForm).toPromise();
    }

    edit(dataForm: Ticket) {
        return this.http.put(environment.api.customers + '/tickets/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.customers + '/tickets/' + id).toPromise();
    }
}
