<!-- CONTENT BREADCRUMB -->
<div class="breadcrumb-four mt-1">
    <ul class="breadcrumb">        
        <li>
            <a routerLink="/job-detail/{{customerEstimate.estimate_id}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
            </a>
        </li>
        <li class="text-breadcrumb"><span>Balances</span></li>
    </ul>
</div>
<!-- CONTENT BREADCRUMB -->


<div class="title-payments-history">
    <h3>Payments History</h3>
    <span>Transactions Details</span>
</div>



<div id="content" class="main-content" >


    <div class="layout-px-spacing-head pad-0" *ngIf="balances.length > 0">
        <div class="row" id="cancel-row">

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing header-table">
                <div class="widget-content widget-content-area widget-content-head br-6 py-2">
                    <table id="invoice-list" class="table table-hover m-0" style="width:100%">
                        <thead>
                            <tr>
                                <th>Job</th>
                                <th>Customer</th>
                                <th>Concept</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Signed</th>
                                <th colspan="2">Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>

            <div id="resize"></div>

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing body-table">
                <div class="widget-content widget-content-area br-6">
                    <table id="invoice-list" class="table table-hover" style="width:100%">
                        <tbody>

                            <tr *ngFor="let balance of balances">
                                <td class="tdHeader headerCollapse">
                                    <button class="btn-arrow rotate"><i class="fas fa-chevron-down"></i></button>
                                    <div>
                                        <span class="key">Amount: </span>
                                        <span class="mr-3">{{balance.amount | currency}}</span>
                                        <span class="badge badge-danger" *ngIf="balance.status == 'PENDING'">
                                            <i class="fa-solid fa-money-check-dollar"></i>
                                        </span>
                                        <span class="badge badge-info" *ngIf="balance.status == 'PROCESSING'">
                                            <i class="fa-solid fa-money-check-dollar"></i>
                                        </span>
                                        <span class="badge badge-success" *ngIf="balance.status == 'PAID'">
                                            <i class="fa-solid fa-money-check-dollar"></i> 
                                        </span>
                                        <span class="badge badge-secondary" *ngIf="balance.status == 'PENDING_REVIEW'">
                                            <i class="fa-solid fa-money-check-dollar"></i>
                                        </span>
                                        <span class="badge badge-secondary" *ngIf="balance.status == 'PARTIAL_PENDING_REVIEW'">
                                            <i class="fa-solid fa-money-check-dollar"></i>
                                        </span>
                                    </div>
                                </td>
                                <td class="rowCollapse t-align" data-label="Job Code">
                                    <div class="arrow-down"></div>
                                    <div>{{balance.estimate_document.code}}</div>
                                </td>
                                <td class="rowCollapse text-wrap t-align" data-label="Customer">
                                    <div>{{balance.customer.name}} {{balance.customer.last_name}}</div>
                                </td>
                                <td class="rowCollapse" data-label="Concept">
                                    <div class="text-wrap">
                                        {{balance.concept}} {{balance.amount | currency}}
                                    </div>
                                </td>
                                <td class="rowCollapse t-align" data-label="Amount">
                                    <span class="inv-amount" *ngIf="balance.status == 'PENDING'">
                                        {{balance.amount | currency}}
                                    </span>
                                    <span class="inv-amount" *ngIf="balance.status !== 'PENDING'">
                                        {{balance.payment?.amount | currency}}
                                    </span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Status">
                                    <span [ngClass]="{'badge badge-secondary': balance.status == 'PENDING_REVIEW' || balance.status == 'PARTIAL_PENDING_REVIEW', 'badge badge-success': balance.status == 'PAID', 'badge badge-info': balance.status == 'PROCESSING', 'badge badge-danger': balance.status == 'PENDING' || balance.status == 'REFUNDED'}">
                                        <span *ngIf="balance.status == 'PARTIAL_PENDING_REVIEW' && balance.payment?.status == 'ACCEPTED'">
                                            Partial Paid
                                        </span>
                                        <span *ngIf="(balance.status == 'PARTIAL_PENDING_REVIEW' && balance.payment?.status !== 'ACCEPTED') || balance.status !== 'PARTIAL_PENDING_REVIEW'">
                                            {{balance.status | filterReplace}}
                                        </span>
                                        <br>
                                        <span *ngIf="balance.status != 'PAID' && balance.status !== balance.payment?.status">
                                            {{balance.payment?.status | filterReplace}}
                                        </span>
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="balance.status == 'PAID' && balance.payment_method?.method == 'CREDIT_CARD'">
                                        <svg *ngIf="balance.is_signed"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline _ngcontent-adk-c155="" points="20 6 9 17 4 12"></polyline></svg>
                                        <svg *ngIf="!balance.is_signed"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line _ngcontent-pvi-c164="" x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                    </span>
            
                                    <span *ngIf="balance.status == 'PAID' && balance.payment_method?.method != 'CREDIT_CARD'"> <b>N/A</b> </span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Action">
                                    <button *ngIf="['PARTIAL_PAID', 'PENDING', 'PARTIAL_PENDING_REVIEW', 'CERTIFIED_FOUNDS'].indexOf(balance.status) >= 0" class="btn btn-danger m-0"
                                        data-toggle="modal" data-target="#modalPaymentMethod"
                                        (click)="loadPaymentMethods(balance)">
                                        Pay
                                    </button>
                                    <button *ngIf="['PARTIAL_PAID', 'PAID', 'PARTIAL_PENDING_REVIEW'].indexOf(balance.status) >= 0" class="btn btn-success m-0" (click)="loadPayment(balance.id, balance.payment?.id)"
                                        data-toggle="modal" data-target="#modalPaymentView">
                                        View
                                    </button>                                    
                                </td>
                                <td>
                                    <button  (click)="goSing(balance)" class="btn btn-success m-0" *ngIf="balance.status == 'PAID' && balance.payment_method?.method == 'CREDIT_CARD' && !balance.is_signed">
                                        Sing
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>

        </div>
    </div>

    <div class="layout-px-spacing-head no-balances" *ngIf="balances.length == 0">
        <img src="../../../assets/img/empty_box.svg" alt="">
        <div class="txt-no-balances">
            <h3>It´s Empty Here</h3>
            <span>You Have No Assigned Payments</span>
        </div>
    </div>

</div>
<!--  END CONTENT AREA  -->

<!-- Modal -->
<div class="modal fade" id="modalPaymentMethod" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modalPaymentMethod" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <div class="col-lg-12 layout-spacing">
                    <div class="mb-4">
                        <h5 class="modal-title mb-2" id="exampleModalLabel">
                            <strong>Payment Methods</strong>
                        </h5>
                        <span>
                            What type of payment method would you like to use?
                        </span>
                        <button type="button" class="close close-button" data-dismiss="modal" aria-label="Close">
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </button>
                    </div>

                    <a *ngFor="let method of paymentMethod.payment_methods" data-backdrop="false" y data-dismiss="modal"
                        (click)="redirectToPayment(method, paymentMethod.id)">
                        <div class="widget-content widget-content-area method-content cursor mb-2 px-3 py-2">
                            <div class="media d-flex align-items-center">
                                <img class="align-self-center rounded pr-4" src="{{method.logo}}" alt="pic1">
                                <div class="media-body">
                                    <p class="media-text">
                                        {{method.name}}<br>
                                        <b *ngIf="paymentAmount == 0 || paymentAmount == null">({{balanceTotal | currency}})</b>
                                        <b *ngIf="paymentAmount > 0">({{paymentAmount | currency}})</b>
                                    </p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-chevron-right">
                                    <polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </div>
                        </div>
                    </a>
                    <div class="row" *ngIf="!showPartialPayment && enabledPartialPayment">
                        <div class="col">
                            <b>Need to make a </b><a (click)="showPartialPayment = true">partial payment</a><b>?</b>
                        </div>
                    </div>
                    <div class="row" *ngIf="showPartialPayment">
                        <div class="col">
                            <div class="form-group mb-4">
                                <label for="exampleFormControlSelect1">Amount <span class="text-red">(up to {{(balanceTotal - paidValue) | currency}})</span></label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>
                                    </div>
                                    <input type="number" [max]="balanceTotal - paidValue" [(ngModel)]="paymentAmount" name="wd_amount" class="form-control">
                                </div>  
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter Amount.</small>      
                            </div>                                
                        </div>
                    </div>
                    <div class="row" *ngIf="showPartialPayment">
                        <div class="col">
                            <b>Make a full payment instead?</b> <a class="btn btn-success" (click)="makeFullPayment()">Make Full Payment</a>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal View Payment -->
<div class="modal fade" id="modalPaymentView" tabindex="-1" role="dialog" aria-labelledby="modalPaymentView"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-payment-view" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <div class="col-lg-12 layout-spacing">
                    <div class="mb-4">
                        <h5 class="modal-title mb-2" id="exampleModalLabel">
                            <strong>Invoice / Job: <strong>{{viewBalance.estimate_document.code}}</strong></strong>
                        </h5>
                        <!-- <span>
                            Job: <strong>NCE02134</strong> 
                        </span> -->
                        <button type="button" class="close close-button" data-dismiss="modal" aria-label="Close">
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </button>
                    </div>

                    <section class="row d-flex justify-content-center invoice">


                        <div class="invoice-container mb-3">
                            <div class="invoice-inbox col-12 pt-2 pb-2">
                                <div id="ct" class="">
                                    <div class="invoice-00001">

                                        <!-- **** Payment Successful **** -->
                                        <div class="content-section">
                                            <div class="row">
                                                <div class="col-2 pr-0 text-center container-center">
                                                    <i class="far fa-check-circle icon-payment-successful"></i>
                                                </div>
                                                <div class="col-10 pl-0">
                                                    <h4 class="m-0 text-payment-successful">
                                                        Payment Successful
                                                    </h4>
                                                    <span class="desc-payment">
                                                        Your payment has been processed! Details of transactions are incluided below
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- **** Payment Failed **** -->
                                        <!-- <div class="content-section">
                                            <div class="row">
                                                <div class="col-2 pr-0 text-center">
                                                    <i class="far fa-times-circle icon-payment-failed"></i>
                                                </div>
                                                <div class="col-10 pl-0">
                                                    <h4 class="m-0 text-payment-failed">Payment Failed</h4>
                                                    <span class="desc-payment">Your payment coundn´t be processed! Details of transactions are incluided below</span>
                                                </div>
                                            </div>                                                                                                    
                                        </div>   -->

                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-2 download-invoice">
                                <button class="btn btn-secondary">Download</button>
                            </div> -->
                        </div>

                        <div class="invoice-container">
                            <div class="invoice-inbox">

                                <div id="ct" class="">

                                    <div class="invoice-00001">
                                        <div class="content-section">

                                            <div class="inv--head-section inv--detail-section border-0 mb-2">

                                                <div class="row text-mbl-center">

                                                    <div class="col-sm-6 col-12 mr-auto">
                                                        <img class="company-logo"
                                                            src="{{environment.az_api.cdn}}/{{authService.session.workspace.id}}/media-files/{{viewBalance.company.logo}}"
                                                            alt="company">
                                                    </div>

                                                    <div class="col-sm-6 text-sm-right info-company">
                                                        <p class="inv-email-address">
                                                            {{viewBalance.company.name}}
                                                        </p>
                                                        <p class="inv-email-address">
                                                            {{viewBalance.company.address.street}}
                                                        </p>
                                                        <p class="inv-email-address">
                                                            {{viewBalance.company.address.city}},
                                                            {{viewBalance.company.address.state}}
                                                            {{viewBalance.company.address.zip_code}}
                                                        </p>
                                                        <p class="inv-email-address">
                                                            <span>ICC MC: </span>
                                                            {{viewBalance.company.license}}
                                                        </p>
                                                        <p class="inv-email-address">
                                                            <span>US D.O.T: </span>
                                                            {{viewBalance.company.dot}}
                                                        </p>
                                                    </div>

                                                </div>

                                            </div>

                                            <div class="inv--detail-section inv--customer-detail-section mt-4">

                                                <div class="row">

                                                    <div class="col-sm-6 text-left">                                                        
                                                        <p class="inv-created-date">
                                                            <span class="inv-title title-details">Invoice Date : </span>                                                           
                                                            <span class="">{{viewBalance.created | dateTime}}</span>                                                  
                                                        </p>
                                                    </div>
                                                    <div class="col-sm-6 align-self-center header-company-info invoice-to">
                                                        <p class="title-details mb-1">Invoice To</p>
                                                        <p>
                                                            <span class="inv-name">Name:</span>
                                                            <span class="invoice-to-name">{{viewBalance.customer.name}} {{viewBalance.customer.last_name}}</span>
                                                        </p>
                                                        <p>
                                                            <span class="inv-phone">Phone:</span>
                                                            <span>{{viewBalance.customer.phone}}</span>
                                                        </p>
                                                        <p>
                                                            <span class="inv-email">Email:</span>
                                                            <span class="invoice-to-email">{{viewBalance.customer.email}}</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="row mt-4">
                                                    <div class="col-sm-6 payment-info">                                           
                                                        <p class="title-details mb-1">Payment Info</p>                                                
                                                        <p>
                                                            <span class="inv-name">Concept:</span>
                                                            <span>{{viewBalance.concept}}</span>
                                                        </p>
                                                        <p>
                                                            <span class="inv-name">Payment Method:</span>
                                                            <span>{{viewBalance.payment_method?.name}}</span>
                                                        </p>
                                                        <p *ngIf="viewBalance.payment_method?.method == 'CREDIT_CARD'">
                                                            <span class="inv-name">Card Type:</span>
                                                            <span *ngIf="viewBalance.payment_transaction.card_type == ''">
                                                                ******
                                                            </span>
                                                            <span *ngIf="viewBalance.payment_transaction.card_type != ''">
                                                                {{viewBalance.payment_transaction.card_type}}
                                                            </span>

                                                        </p>
                                                        <p *ngIf="viewBalance.payment_method?.method == 'CREDIT_CARD'">
                                                            <span class="inv-name">Product:</span>
                                                            <span>{{viewBalance.payment_transaction.card_number}}</span>       
                                                        </p>   
                                                        <p *ngIf="viewBalance.payment_method?.method == 'CREDIT_CARD'">
                                                            <span class="inv-name">Transaction ID: </span>
                                                            <span *ngIf="viewBalance.payment_transaction.auth_code == ''">
                                                                ******
                                                            </span>
                                                            <span *ngIf="viewBalance.payment_transaction.auth_code != ''">
                                                                {{viewBalance.payment_transaction.auth_code}}
                                                            </span>
                                                        </p>           
                                                    </div>

                                                    <!-- Payment Successful -->
                                                    <div class="col-sm-6 align-self-end inv--payment-info">
                                                        <p>
                                                            <span class="txt-payment-status">Payment Status:</span>
                                                            <span class="txt-approved">Approved</span>
                                                        </p>
                                                        <div class="total-section-successful mt-2">
                                                            <span>Amount Paid</span> 
                                                            <span>{{viewBalance.payment?.amount | currency}}</span>
                                                        </div>
                                                    </div>

                                                    <!-- Payment Failed -->
                                                    <!-- <div class="col-sm-6 align-self-end inv--payment-info">
                                                        <p>
                                                            <span class="txt-payment-status">Payment Status:</span>
                                                            <span class="txt-failed">Denied</span>
                                                        </p>
                                                        <div class="total-section-failed mt-2">
                                                            <span>Amount Paid</span> 
                                                            <span>{{viewBalance.amount | currency}}</span>
                                                        </div>
                                                    </div> -->


                                                    <div
                                                        class="col-xl-6 col-lg-5 col-md-6 col-sm-8 col-12 order-sm-0 order-1">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row invoice-container mt-3" style="width: 750px;">
                            <div class="invoice-inbox col-12">
                                <div id="ct" class="">
                                    <div class="invoice-00001">
                                        <div class="content-section">
                                            <div class="response-payment-status">response_payment_status:</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </section>

                </div>

            </div>
        </div>
    </div>
</div>