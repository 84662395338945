<div class="form-group mb-4">
    <label class="control-label">Search:</label>
    <input #inputSearch type="text" size="50" placeholder="Enter a location" class="form-control" autocomplete="on" runat="server" [(ngModel)]="fullAddress" (input)="doInput()"/>
</div>
<ng-container *ngIf="addressFull">
    <div class="form-group mb-4">
        <label class="control-label">Property Type:</label>
        <select name="property_type" id="state" (change)="changeInfo()" [(ngModel)]="address.property_type" class="form-control">
            <option value="HOUSE">House</option>
            <option value="TOWNHOUSE">Townhouse</option>
            <option value="APARTMENT">Apartment</option>
            <option value="CONDO">Condo</option>
            <option value="GARAGE">Garage</option>
            <option value="MOBILE_HOME">Mobile Home</option>
            <option value="OFFICE">Office</option>
            <option value="STORAGE">Storage</option>
            <option value="WAREHOUSE">Warehouse</option>
            <option value="RESIDENCE">Residence</option>
            <option value="BUSINESS">Business</option>
            <option value="DEALERSHIP">Dealership</option>
            <option value="PORT">Port</option>
            <option value="TERMINAL">Terminal</option>
            <option value="AUCTION">Auction</option>
        </select>    
    </div>
    <ng-container *ngIf="isNotAutoTransport">
        <div class="form-group mb-4">
            <label class="control-label">Level:</label>
            <select name="level" id="state" (change)="changeInfo()" [(ngModel)]="address.level" class="form-control">
                <option value="GROUND">Ground</option>
                <option value="ELEVATOR">Elevator</option>
                <option value="STAIRS">Stairs</option>
                <option value="BASEMENT">Basement</option>
            </select>     
        </div>
        <div class="form-group mb-4">
            <label class="control-label">Floor:</label>
            <input type="number" size="50" min="1" (change)="changeInfo()" placeholder="Type a Floor" class="form-control" name="floor" [(ngModel)]="address.floor"/>
        </div>
        <div class="form-group mb-4">
            <label class="control-label">Apartment Number:</label>
            <input type="number" size="50" min="1" (change)="changeInfo()" placeholder="Type a Apartment Number" class="form-control" name="apartment_number" [(ngModel)]="address.apartment_number"/>
        </div>
    </ng-container>
</ng-container>
