// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    local: {
        base: 'http://localhost:4200'
    },
    app: {
        base: "https://app.mxp360.testaproject.com",
        carriers: "https://carriers.mxp360.testaproject.com"
    },
    api: {
        sockets: "ws://localhost:3000",
        base: "https://api.beta.mxp360.testaproject.com",
        app: "https://api.beta.mxp360.testaproject.com/app",
        account: "https://api.beta.mxp360.testaproject.com/my-account",
        customers: "https://api.beta.mxp360.testaproject.com/customers",
        workspace: 'https://api.beta.mxp360.testaproject.com/workspace',
        carrier: 'https://api.beta.mxp360.testaproject.com/carriers',
        company: 'https://api.beta.mxp360.testaproject.com/workspace/companies/{company_id}',
        companies: 'https://api.beta.mxp360.testaproject.com/workspace/companies/'
    },
    google_api: {
        key: "AIzaSyB3LVDbst0uGLsPuqAONs6JDWXox1HOU4o",
        client_id: "1016385548058-7803ufvape4kjb5fjl94m1ce2j4bl2kp.apps.googleusercontent.com"
    },
    authorize_api: {
        apiTest: "https://apitest.authorize.net/xml/v1/request.api",
        apiProduction: "https://api.authorize.net/xml/v1/request.api",
    },
    az_api: {
        cdn: "https://mxp360cdn.azureedge.net/files"
    }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
