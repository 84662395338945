<div class=" layout-top-spacing">

    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 align-self-center mb-4">
            <h3 style="font-style:italic;">
                Inventory Items
            </h3>
        </div>

        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 align-self-center mb-2">
            <h4>Total items: {{totalItems}}</h4>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 align-self-center mb-2">
            <h4>Total CF: {{totalCF}}</h4>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">


                <button class="btn btn-warning" (click)="saveInventory()" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-save">
                        <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                        <polyline points="17 21 17 13 7 13 7 21"></polyline>
                        <polyline points="7 3 7 8 15 8"></polyline>
                    </svg>
                    &nbsp; Save
                </button>

                <div class="btn-group dropleft" role="group">
                    <button id="btnOutline" type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"  *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                        Add Room
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-down">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                    </button>
                    <div class="dropdown-menu overflow-default" aria-labelledby="btnOutline">
                        <a href="javascript:void(0);" class="dropdown-item" (click)="addCustomContainer()">
                            <i class="flaticon-dots mr-1"></i>
                            Custom Room
                        </a>
                        <div class="divider dropdown-item"></div>
                        <a href="javascript:void(0);" class="dropdown-item" *ngFor="let room of inventoryCategories"
                            (click)="addContainer(room)">
                            <i class="flaticon-home-fill-1 mr-1"></i>
                            {{room.name}}
                        </a>
                    </div>
                    <a href="javascript:void(0);" routerLink="/job-detail/{{jobId}}"
                        class="btn btn-success btn-download">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-arrow-left-circle">
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 8 8 12 12 16"></polyline>
                            <line x1="16" y1="12" x2="8" y2="12"></line>
                        </svg>
                        Go Back
                    </a>
                </div>
            </div>


        </div>
    </div><!-- header -->

</div>

<div class="row scrumboard">

    <div class="task-list-section">
        <div class="task-list-container" data-section="s-in-progress" data-connect="sorting">

            <ng-container *ngFor="let container of containers; let i = index">
                <div class="connect-sorting">
                    <div class="task-container-header">
                        <h6 class="s-heading">
                            {{container.name | titlecase}}
                        </h6>
                        <div class="dropdown">
                            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink-1"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-more-horizontal">
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="19" cy="12" r="1"></circle>
                                    <circle cx="5" cy="12" r="1"></circle>
                                </svg>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                <a class="dropdown-item list-edit cursor-pointer" (click)="editContainer(i)">Edit</a>
                                <a class="dropdown-item list-delete cursor-pointer"
                                    (click)="deleteContainer(i)">Delete</a>
                                <a class="dropdown-item list-clear-all cursor-pointer" (click)="clearContainer(i)">Clear
                                    All</a>
                            </div>
                        </div>
                    </div>
                    <ul id="sortable_{{i}}" class="connectedSortable" attr.container="{{i}}">
                        <ng-container>
                            <li class="ui-state-default" attr.indexContainer="{{i}}" attr.idItem="{{item.id}}"
                                *ngFor="let item of container.items ; let j = index">
                                <div class="card-body">

                                    <div class="task-header">
                                        <h4 data-taskTitle="Creating a new Portfolio on Dribble">
                                            {{item.inventory_item.name}}
                                        </h4>
                                    </div>
                                    <div class="task-body">

                                        <div class="task-bottom color-text-inventory">
                                            <div class="tb-section-1">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-layers">
                                                        <polygon _ngcontent-yhr-c84=""
                                                            points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                                        <polyline _ngcontent-yhr-c84="" points="2 17 12 22 22 17">
                                                        </polyline>
                                                        <polyline _ngcontent-yhr-c84="" points="2 12 12 17 22 12">
                                                        </polyline>
                                                    </svg>
                                                    CF: {{item.inventory_item.cubic_feet}}
                                                </span>
                                                &nbsp;
                                                |
                                                &nbsp;
                                                <span (click)="lessQty(i,j)" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-minus-circle">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                                    </svg>
                                                </span>
                                                QTY: {{item.quantity}}
                                                <span (click)="moreQty(i,j)" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-plus-circle">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <line x1="12" y1="8" x2="12" y2="16"></line>
                                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div class="tb-section-2" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                                                <svg (click)="editItemQuote(i, j)" xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-edit-2 s-task-edit">
                                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                    </path>
                                                </svg>
                                                <svg (click)="deleteItemQuote(i, j)" xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-trash-2 s-task-delete">
                                                    <polyline points="3 6 5 6 21 6"></polyline>
                                                    <path
                                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                    </path>
                                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                    <div class="add-s-task">
                        <a class="addTask" (click)="addItemInventory(i)" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-plus-circle">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                            Add Item
                        </a>
                    </div>
                </div>

            </ng-container>
        </div>
    </div>
</div>

<!-- Modales -->
<div class="modal fade" id="deleteConformation" tabindex="-1" role="dialog" aria-labelledby="deleteConformationLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" id="deleteConformationLabel">
            <div class="modal-header">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-trash-2">
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                        </path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                </div>
                <h5 class="modal-title" id="exampleModalLabel">Delete the task?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>If you delete the task it will be gone forever. Are you sure you want to proceed?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" data-remove="task">Delete</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade inventoryItemModal" #inventoryItemModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div>

                <div class="modal-body">
                    <div class="compose-box">

                        <div class="compose-content content-select" *ngIf="viewInventoryItemModal == 'SELECT'">
                            <h5 class="add-task-title">Select Item</h5>

                            <div class="row">
                                <div class="col-xl-9 col-lg-10 col-md-8">
                                    <div class="search-input-group-style input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-search">
                                                    <circle cx="11" cy="11" r="8"></circle>
                                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                </svg>
                                            </span>
                                        </div>
                                        <input type="text" #searchField class="form-control" name="search_item"
                                            [(ngModel)]="searchItemByName" (click)="onKeydownSearch()"
                                            placeholder="Search here your item inventory" aria-label="Username"
                                            aria-describedby="basic-addon1">
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-2 col-md-4">
                                    <button type="button" class="btn btn-danger mb-2 btn-block"
                                        (click)="setCustomItem()">
                                        add custom Item
                                    </button>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-xl-3 col-lg-2 col-md-2 overflow-content">

                                    <h5 class="sub-title">
                                        Category
                                    </h5>

                                    <button type="button" class="btn mb-2 btn-block"
                                        (click)="filterItemsByCategory(null)"
                                        [ngClass]="{'btn-success':searchItemByCategory == null, 'btn-outline-success':searchItemByCategory != null}">
                                        All
                                    </button>

                                    <button type="button" *ngFor="let category of inventoryCategories "
                                        class="btn mb-2 btn-block" (click)="filterItemsByCategory(category.id)"
                                        [ngClass]="{'btn-success':searchItemByCategory == category.id, 'btn-outline-success':searchItemByCategory != category.id}">
                                        {{category.name}}
                                    </button>

                                </div>

                                <div class="col-xl-6 col-lg-8 col-md-8 overflow-content scroll-default">

                                    <h5 class="sub-title">
                                        Items
                                    </h5>

                                    <table class="table table-bordered table-hover mb-4">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Item
                                                </th>
                                                <th class="text-center">
                                                    Volume CF
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container
                                                *ngFor="let item of inventoryItems | search:searchItemByCategory | async | search:searchItemByName | async">
                                                <!-- [hidden]="!(searchItemByCategory == item.inventory_category_id  || searchItemByCategory== null)" -->
                                                <tr (click)="setItemTemp(item)">
                                                    <td>
                                                        {{item.name}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.cubic_feet}}
                                                    </td>
                                                </tr>
                                            </ng-container>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-xl-3 col-lg-2 col-md-2 overflow-content" #itemsContainer>

                                    <h5 class="sub-title" *ngIf="containers[itemContainerPath.container_index]">
                                        {{containers[itemContainerPath.container_index].name}}
                                    </h5>

                                    <table class="table table-bordered table-hover mb-4">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Item
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="containers[itemContainerPath.container_index]">
                                            <ng-container
                                                *ngFor="let item of containers[itemContainerPath.container_index].items; let i = index">
                                                <!-- [hidden]="!(searchItemByCategory == item.inventory_category_id  || searchItemByCategory== null)" -->
                                                <tr>
                                                    <td>
                                                        {{item.inventory_item.name}}
                                                        <br>
                                                        <span (click)="lessQty(itemContainerPath.container_index,i)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="feather feather-minus-circle">
                                                                <circle cx="12" cy="12" r="10"></circle>
                                                                <line x1="8" y1="12" x2="16" y2="12"></line>
                                                            </svg>
                                                        </span>
                                                        <input type="number" class="number-input"
                                                            name="quantityss{{item.id}}{{i}}"
                                                            [(ngModel)]="item.quantity">
                                                        <span (click)="moreQty(itemContainerPath.container_index,i)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="feather feather-plus-circle">
                                                                <circle cx="12" cy="12" r="10"></circle>
                                                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                                                <line x1="8" y1="12" x2="16" y2="12"></line>
                                                            </svg>
                                                        </span>
                                                        <svg (click)="deleteItemQuote(itemContainerPath.container_index,i)"
                                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="feather feather-trash-2 s-task-delete">
                                                            <polyline points="3 6 5 6 21 6"></polyline>
                                                            <path
                                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                            </path>
                                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                                        </svg>
                                                    </td>
                                                </tr>
                                            </ng-container>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                        <div class="compose-content content-quantity" *ngIf="viewInventoryItemModal == 'QTY'">

                            <h5 class="add-task-title">Select Quantity</h5>

                            <div class="row">
                                <div class="col-md-12">
                                    <label for="start-date" class="">Item:</label>
                                    <div class="d-flex event-title">
                                        <input type="text" placeholder="Name item" class="form-control"
                                            name="item_name_qty" [(ngModel)]="dataFormItem.inventory_item.name"
                                            readonly>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group start-date">
                                        <label for="start-date" class="">Volume CF:</label>
                                        <div class="d-flex">
                                            <input placeholder="Volume in CF" class="form-control" type="number"
                                                name="item_cf_qty" [(ngModel)]="dataFormItem.inventory_item.cubic_feet"
                                                readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group end-date">
                                        <label for="end-date" class="">Quantity:</label>
                                        <div class="d-flex">
                                            <input placeholder="Quantity from items" type="number" class="form-control"
                                                [(ngModel)]="dataFormItem.quantity" name="item_quantity_qty" min="1"
                                                required>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <p>
                                        <strong>Total Volume CF: </strong>
                                        {{dataFormItem.inventory_item.cubic_feet * dataFormItem.quantity}}
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div class="compose-content content-quantity" *ngIf="viewInventoryItemModal == 'CUSTOM_ITEM'">

                            <h5 class="add-task-title">Custom Item</h5>

                            <div class="row">
                                <div class="col-md-12">
                                    <label for="start-date" class="">Item:</label>
                                    <div class="d-flex event-title">
                                        <input type="text" placeholder="Name item" class="form-control"
                                            name="item_name_qty" [(ngModel)]="dataFormItem.inventory_item.name">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group start-date">
                                        <label for="start-date" class="">Volume CF:</label>
                                        <div class="d-flex">
                                            <input placeholder="Volume in CF" class="form-control" type="number"
                                                name="item_cf_qty" [(ngModel)]="dataFormItem.inventory_item.cubic_feet">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group end-date">
                                        <label for="end-date" class="">Quantity:</label>
                                        <div class="d-flex">
                                            <input placeholder="Quantity from items" type="number" class="form-control"
                                                [(ngModel)]="dataFormItem.quantity" name="item_quantity_qty" min="1"
                                                required>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <p>
                                        <strong>Total Volume CF: </strong>
                                        {{dataFormItem.inventory_item.cubic_feet * dataFormItem.quantity}}
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn" (click)="closeModalInventory()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                        Discard
                    </button>
                    <button data-btnfn="addTask" class="btn add-tsk" *ngIf="viewInventoryItemModal != 'SELECT'"
                        (click)="saveItemQuote(true)" type="submit">
                        Save
                    </button>
                    <div data-btnfn="addTask" class="btn add-tsk" *ngIf="viewInventoryItemModal == 'SELECT'"
                        (click)="saveAll()">
                        Save
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



<div class="modal fade customContainerModal" #customContainerModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form #entityFormContainer="ngForm" (ngSubmit)="saveContainer()" ngNativeValidate>

                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content">

                            <h5 *ngIf="dataFormContainer.id == null">
                                Add Custom Room
                            </h5>
                            <h5 *ngIf="dataFormContainer.id != null">
                                Edit Room
                            </h5>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="list-title">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-list">
                                            <line x1="8" y1="6" x2="21" y2="6"></line>
                                            <line x1="8" y1="12" x2="21" y2="12"></line>
                                            <line x1="8" y1="18" x2="21" y2="18"></line>
                                            <line x1="3" y1="6" x2="3" y2="6"></line>
                                            <line x1="3" y1="12" x2="3" y2="12"></line>
                                            <line x1="3" y1="18" x2="3" y2="18"></line>
                                        </svg>
                                        <input id="s-list-name" type="text" [(ngModel)]="dataFormContainer.name"
                                            placeholder="Name here" class="form-control" name="name_container"
                                            autocomplete="off" required>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn" data-dismiss="modal" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                        Discard
                    </button>
                    <button class="btn add-list" type="submit">
                        Save
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>