import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripTagsHtml'
})
export class StripTagsHtmlPipe implements PipeTransform {

    transform(tagsHtml: string): string {

        // validamos que haya algo para transformar
        if (tagsHtml === null || tagsHtml === '') {
            return  null;
        }

        // eliminamos las etiquetas html que este tenga
        return String(tagsHtml).replace(/<[^>]*>/g, '');
    }

}
