import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Customer } from '../entities/customer';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
export class CustomersService {

  constructor(private http: HttpClient) {

  }

  getAll(): Promise<Array<Customer>> {
      return this.http.get<Array<Customer>>(environment.api.customers + '/customers').toPromise();
  }

  getById(id: string) {
      return this.http.get<Customer>(environment.api.customers + '/customers/' + id).toPromise();
  }

  getEstimatesCustomer(id: string) {
      return this.http.get<any>(environment.api.customers + '/customers/' + id + '/estimates').toPromise();
  }

  save(customer: Customer): Promise<any> {
      if (customer.id === null) {
          return this.add(customer);
      }
      return this.edit(customer);
  }

  add(customer: Customer) {
      return this.http.post(environment.api.customers + '/customers', customer).toPromise();
  }

  edit(customer: Customer) {
      return this.http.put(environment.api.customers + '/customers/' + customer.id, customer).toPromise();
  }

  remove(id: string) {
      return this.http.delete(environment.api.customers + '/customers/' + id).toPromise();
  }

  getFiltered(search: string) {
    return this.http.get<Array<Customer>>(environment.api.customers + '/customers/' + search + '/filtered').toPromise();
  }
}
