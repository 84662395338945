export class PaymentTransaction {
    id: string;
    code: string;
    reference: string;
    auth_code: string;
    status: 'PAID' | 'DECLINED' | 'ERROR' | 'HELD_FOR_REVIEW';
    card_number: string;
    card_type: string;
    payment_id: string;
    created: number;

    constructor() {
        this.id = null;
        this.code = null;
        this.reference = null;
        this.auth_code = null;
        this.status = null;
        this.card_number = null;
        this.card_type = null;
        this.payment_id = null;
        this.created = null;
    }
}
