import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import {
    HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';

declare const jQuery;

@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService {
    constructor(
        private injector: Injector
    ) { }

    /**
     * Permite verificar si se esta haciendo una peticion a la api del proyecto
     *
     * @param urlRequest Url de solicitud
     */
    private isRequestToApi(urlRequest: string): boolean {

        const apiUrl = new URL(environment.api.base);
        const urlReq = new URL(urlRequest);

        // verificamos si el host es el mismo del host de la api
        if (apiUrl.hostname === urlReq.hostname) {

            // verificamos si se esta haciendo uso de la api como tal
            if (urlRequest.includes(apiUrl.href)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Permite organizar el request que va dirigido a la api para adicionarle el access token
     *
     * @param req Url de solicitud
     */
    private cloneRequestToApi(request: HttpRequest<any>): HttpRequest<any> {

        const authService = this.injector.get(AuthService);
        // Si no hay session, se retorna el request
        if (authService.session === undefined || authService.session.token === null || authService.session.token === undefined) {
            return request;
        }

        // Se clona el request y se adiciona el access token
        const clonReq = request.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                Authorization: authService.session.token
            },
            setParams: {
                Authorization: authService.session.token
            }
        });
        return clonReq;
    }

    /**
     * Metodo principal que se ejecuta por cada llamado de un http Client
     *
     * @param req Url de solicitud
     * @param next Handler
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // en caso de que no sea una peticion al servidor api dejamos la solicitud tal cual
        if (!this.isRequestToApi(request.url)) {
            return next.handle(request);
        }

        // como la peticion es para el servidor api, se hace un clon de la solicitud
        // y se le adiciona el access token para las credenciales
        return next.handle(this.cloneRequestToApi(request))
            .pipe(
                tap(
                    event => {
                        // logging the http response to browser's console in case of a success
                        if (event instanceof HttpResponse) {
                            // console.log('api call success : ', event);
                        }
                    },
                    error => {
                        // logging the http response to browser's console in case of a failuer
                        if (error instanceof HttpResponse) {
                            console.error('api call error : ', error);
                        } else {
                            // The backend returned an unsuccessful response code.
                            // The response body may contain clues as to what went wrong,
                            console.error(`Backend returned code ${error.status}, ` + `body was:`, error);

                            if (error.status === 401) {
                                const authService = this.injector.get(AuthService);
                                const router = this.injector.get(Router);

                                authService.closeSession();
                                router.navigate(['/login']);
                            }
                        }
                    }
                ));
    }
}
