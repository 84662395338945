import { Injectable } from '@angular/core';
import { EstimateDocument } from '../entities/estimate-document';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewBindingService {

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getDocument(idJob: string) {
    const params = { company_id: this.authService.session.company.id };
    
    return this.http.get<EstimateDocument>(environment.api.customers + '/estimates/' + idJob + '/new-binding', { params: params } ).toPromise();
  }

  signDocument(idJob: string, data) {
    data.company_id = this.authService.session.company.id;
    
    return this.http.put<EstimateDocument>(environment.api.customers + '/estimates/' + idJob + '/new-binding/signature/' + data.type, data).toPromise();
  }
}
