export class CustomerId {
    public id: string;
    public number_id: string;
    public expiration_date: string;

    constructor() {
        this.id = null;
        this.number_id = null;
        this.expiration_date = null;
    }
}
