import { Address } from 'src/app/entities/address';

import { BankInformation } from './bank-information';
import { CardHolder } from './card-holder';
import { CreditCard } from './credit-card';
import { CustomerId } from './customer-id';

export class PaymentProfile {
    public id: string;
    public customer_id: string;
    public carrier_id: string;
    public card_holder: CardHolder;
    public credit_card: CreditCard;
    public billing_address: Address;
    public bank_information: BankInformation;
    public customer_identification: CustomerId;

    constructor() {
        this.id = null;
        this.customer_id = null;
        this.carrier_id = null;
        this.card_holder = new CardHolder();
        this.credit_card = new CreditCard();
        this.billing_address = new Address();
        this.bank_information = new BankInformation();
        this.customer_identification = new CustomerId();
    }
}
