import { Geospatial } from './geospatial';

export class Address {

    public id: string;
    public full_address: string;
    public street: string;
    public city: string;
    public state: string;
    public country: string;
    public zip_code: string;
    public geospatial: Geospatial;
    public property_type: 'HOUSE' | 'TOWNHOUSE' | 'APARTMENT' | 'CONDO' | 'GARAGE' | 'MOBILE_HOME' | 'OFFICE' | 'STORAGE' | 'WAREHOUSE' | 'RESIDENCE' | 'BUSINESS' | 'DEALERSHIP' | 'PORT' | 'TERMINAL' | 'AUCTION';
    public level: 'GROUND' | 'ELEVATOR' | 'STAIRS' | 'BASEMENT';
    public floor: number;
    public apartment_number: number;

    constructor() {
        this.id = null;
        this.full_address = null;
        this.street = null;
        this.city = null;
        this.state = null;
        this.country = null;
        this.zip_code = null;
        this.geospatial = new Geospatial();
        this.property_type = null;
        this.level = null;
        this.floor = null;
        this.apartment_number = null;
    }

}
