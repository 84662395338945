import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BalanceView } from 'src/app/entities/balance-view';
import { ReportPaymentView } from 'src/app/entities/report-payment-view';
import { AuthService } from 'src/app/services/auth.service';
import { BalancesCustomerService } from 'src/app/services/balances-customer.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-balances-view',
  templateUrl: './balances-view.component.html',
  styleUrls: ['./balances-view.component.scss']
})
export class BalancesViewComponent implements OnInit {
    readonly environment = environment;

    public currentrowBalance: BalanceView;
    public payment: ReportPaymentView;
    private helperService: HelperService
  
  constructor(
    private balancesCustomerService: BalancesCustomerService,
    private currentRoute: ActivatedRoute,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.currentRoute.params.subscribe(params => {
        if (typeof params.id !== 'undefined') {
          this.load(params.id);
        }
      });
  }

  
  /**
   * consulta el arreglo del listado de pagos
   */
  load(id) {
    // this.helperService.showLoadingMxpro360();
    this.balancesCustomerService
      .getByIdCustomer(id)
      .then((response) => {
        this.currentrowBalance = response;
        this.loadPayments(this.currentrowBalance.id);
      })
      .catch((error) => {
        this.helperService.showMessageError(error, id);
    })
      .finally(() => {
        // this.helperService.hideLoadingMxpro360();
      });
  }

  
  /**
   * Carga los payments de un balance
   */
  loadPayments(idBalance: string) {
    this.balancesCustomerService
      .getPaymentsByBalanceCustomer(idBalance)
      .then((response) => {
        if (response.length > 0) {
          this.payment = response[0];
        }
      })
      .catch((error) => {
        this.helperService.showMessageError(error, idBalance);
      })
      .finally(() => {
      });
  }

}
