import { CustomerCredential } from 'src/app/entities/customer-credential';
import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { AuthService } from 'src/app/services/auth.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConstantsMessages } from '../../constants-messages';
import { HelperService } from 'src/app/services/helper.service';
import { RecurringCustomer } from 'src/app/entities/recurring-customer';
import { RecurringCustomersService } from 'src/app/services/recurring-customers.service';

declare const jQuery;
declare const swal;
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public codeEstimate: string;
    private workspace: string;
    private publicIP: string;
    public token: string;
    public requestToken: boolean;
    private estimateId: string;

    constructor(
        public router: Router,
        private currentRoute: ActivatedRoute,
        private authService: AuthService,
        private recurringCustomersService: RecurringCustomersService,
        private helperService: HelperService
    ) {
        this.requestToken = false;
        this.estimateId = '';
    }

    ngOnInit(): void {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            try {
                if (params.id_customer_estimate) {
                    this.loginCustomer(params.id_customer_estimate);
                }

            } catch (error) {
                console.error('Error: ', error);
                swal(
                    'Error',
                    error.error.message,
                    'warning'
                );
            }
        });

        // verficamos si se esta editando
        this.currentRoute.queryParams.subscribe(params => {
            // validamos si existe el parametro solicitado
            if ('workspace' in params) {
                // Asignamos el workspace
                this.workspace = params.workspace;
            }
        });

        this.authService.getPublicIp().then((response: any) => {
            if (response.ip) {
                this.publicIP = response.ip;
            }
        });
    }

    sendToken() {

        const data = {
            workspace: this.workspace,
            code_estimate: this.codeEstimate
        };

        this.authService
            .tokenCustomer(data)
            .then(() => {
                this.helperService.showMessageSnackbar(ConstantsMessages.SEND_TOKEN);
            })
            .catch((error) => {
                console.error('Error', error);
                this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SEND_TOKEN);
                this.helperService.showMessageError(error, data);

            })
            .finally(() => {
            });
    }

    loginCustomer(idCustomerEstimate) {
        this.authService
            .loginCustomer(idCustomerEstimate)
            .then((response: CustomerEstimate) => {

                this.requestToken = response.request_token;
                this.estimateId = response.estimate_id;
                const recurringCustomer = new RecurringCustomer();
                recurringCustomer.estimates_id = response.estimate_id;
                recurringCustomer.company_id = this.authService.session.company.id;
        
                this.recurringCustomersService
                .add(recurringCustomer);

                if (this.requestToken) {
                    return;
                }
                this.router.navigateByUrl('/job-detail/' + response.estimate_id);
                this.helperService.showMessageSnackbar(ConstantsMessages.SESSION_STARTED);
            })
            .catch((error) => {
                console.error('Error', error);
                this.helperService.showMessageError(error, idCustomerEstimate);
            })
            .finally(() => {
            });
    }

    loginToken(){
        this.authService
        .getEstimatesEmail(this.estimateId, this.token)
        .then((response) => {
            this.router.navigateByUrl('/job-detail/' + this.estimateId);
            this.helperService.showMessageSnackbar(ConstantsMessages.SESSION_STARTED);
        })
        .catch((error) => {
            console.error('Error', error);
            this.helperService.showMessageError(error, this.estimateId);
        })
        .finally(() => {
        });
        
    }

    verifyToken() {

        const data = {
            ip: this.publicIP,
            token: this.token,
            workspace: this.workspace,
            code_estimate: this.codeEstimate
        };

        this.authService
            .verifyToken(data)
            .then((response: any) => {
                this.authService.session.token = response.token;
                this.authService.session.customer = response.customer;
                this.authService.session.company = response.company;
                this.authService.session.workspace = response.workspace;

                const customerEstimate = new CustomerEstimate();
                customerEstimate.id = response.customer_estimate.id;
                customerEstimate.workspace_id = response.customer_estimate.workspace_id;
                customerEstimate.customer_id = response.customer_estimate.customer_id;
                customerEstimate.estimate_id = response.customer_estimate.estimate_id;
                customerEstimate.estimate_document_id = response.customer_estimate.estimate_document_id;
                localStorage.setItem('customer_estimate', JSON.stringify(customerEstimate));
                localStorage.setItem('session', JSON.stringify(this.authService.session));

                this.helperService.showMessageSnackbar(ConstantsMessages.SESSION_STARTED);
                this.router.navigateByUrl('/job-detail/' + response.customer_estimate.estimate_id);
            })
            .catch((error) => {
                console.error('Error', error);
                this.helperService.showMessageError(error, data);
            })
            .finally(() => {
                console.info('Finish')
            });
    }

}
