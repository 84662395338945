import { Address } from 'src/app/entities/address';

import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

declare var google;

@Component({
    selector: 'app-search-address',
    templateUrl: './search-address.component.html',
    styleUrls: ['./search-address.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchAddressComponent),
            multi: true
        }
    ]

})
export class SearchAddressComponent implements OnInit, ControlValueAccessor {

    @Output() onChange: EventEmitter<any>;

    @ViewChild('inputSearch')
    inputSearch: ElementRef;

    @Input() addressFull: boolean;
    @Input() isNotAutoTransport: boolean;
    address: Address;

    constructor() {
        this.onChange = new EventEmitter();
        this.address = new Address();
    }

    @Input() fullAddress: string;

    @Input() oldAddress: string;

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        if (this.isNotAutoTransport === null) {
            this.isNotAutoTransport = true;
        }
        const that = this;
        const input = this.inputSearch.nativeElement;
        const autocomplete = new google.maps.places.Autocomplete(input);
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            console.info("lugar encontrado: ", place);
            that.fillInAddress(place);
            // retorna el valor asignado
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.oldAddress) {
            this.address = changes.oldAddress.currentValue;
        }
        if (changes.isNotAutoTransport) {
            this.isNotAutoTransport = changes.isNotAutoTransport.currentValue;
        }
    }

    private change(value: string) { }

    fillInAddress(place) {
        this.address = new Address();
        // Se agregan las longitudes y latitudes de la direccion buscada
        if (place.geometry.location.lat() && place.geometry.location.lng()) {
                this.address.geospatial.coordinates.push(place.geometry.location.lat(), place.geometry.location.lng());
        }

        for (const component of place.address_components) {
            const addressType = component.types[0];

            if (addressType === 'street_number') {
                this.address.street = component.short_name;
            }

            if (addressType === 'route') {
                this.address.street = this.address.street ? this.address.street + ' ' + component.long_name : component.long_name;
            }

            if (addressType === 'locality') {
                this.address.city = component.long_name;
            }

            if (addressType === 'country') {
                this.address.country = component.long_name;
            }

            if (addressType === 'postal_code') {
                this.address.zip_code = component.short_name;
            }

            if (addressType === 'administrative_area_level_1') {
                this.address.state = component.short_name;
            }

        }

        this.address.full_address = place.formatted_address;

        this.onChange.emit(this.address);
    }

    changeInfo() {
        this.onChange.emit(this.address);
    }

    doInput() {
        this.change(this.fullAddress);
    }

    writeValue(value: any) {
        this.fullAddress = value;
    }

    registerOnChange(change: (value: string) => void) {
        this.change = change;
    }
    registerOnTouched() { }



}
