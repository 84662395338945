<div class="background-media-files">

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center block-upload-file">

        <div (click)="openUploadModal()" class="justify-content-between widget-header">
            <div class="icon-media-files">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                    <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M165.1,4332.4l-65.1-65.1V1134v-3133.3l65.1-65.1l65.1-65.1H3773h3542.9l61.3,55.5l63.2,55.5l5.7,3142.9l3.8,3142.9l-65.1,65.1l-65.1,65.1H3775H230.2L165.1,4332.4z M7038.4,3972.5c5.7-13.4,7.7-1148.4,5.7-2522.7l-5.7-2497.8L6096.7,829.7C5197.1,2625,5153.1,2709.3,5086.1,2739.9c-78.5,38.3-157,28.7-216.3-26.8c-21.1-19.1-375.2-625.9-788.6-1347.5c-411.5-721.6-754.1-1313-761.8-1313c-7.7,0-254.6,241.2-549.3,535.9c-507.2,507.2-541.7,535.9-606.8,535.9c-40.2,0-91.9-9.6-118.7-21.1c-30.6-15.3-285.2-392.4-790.5-1179L511.5-1231.7L505.8,1358c-1.9,1424,0,2601.2,5.7,2614.6C524.9,4007,7025,4007,7038.4,3972.5z"/><path d="M2073.4,3543.8c-306.2-82.3-562.7-317.7-685.2-633.5c-44-114.8-51.7-156.9-51.7-350.3s7.7-235.4,51.7-350.3c59.3-155,141.6-277.5,260.3-392.4c380.9-365.6,1008.7-363.7,1397.2,3.8c350.3,331.1,419.2,882.4,158.9,1284.3c-137.8,214.4-359.8,377.1-599.1,440.2C2473.4,3582.1,2207.4,3580.1,2073.4,3543.8z"/><path d="M7951.4,2184.8c-103.4-72.7-99.5,21.1-99.5-2113.1s-3.8-2040.4,99.5-2113.1c63.1-45.9,176.1-38.3,233.5,13.4c61.2,51.7,1693.9,2850,1709.2,2924.7c24.9,124.4,11.5,135.9-899.6,742.6C8073.9,2251.8,8058.6,2259.5,7951.4,2184.8z"/><path d="M7459.5-2500.8c-93.8-40.2-116.8-40.2-1506.3-49.8l-1412.6-9.6l-51.7-53.6c-72.7-72.7-76.6-201-5.7-269.9c74.6-70.8,2296.8-1293.9,2352.4-1293.9c111,0,147.4,49.8,560.8,756c415.4,712,434.5,756,380.9,859.4c-28.7,51.7-128.2,109.1-187.6,105.3C7570.5-2458.6,7513.1-2477.8,7459.5-2500.8z"/></g></g>
                </svg>
            </div>
            <div class="txt-files">
                Media Files
            </div>
            <div class="txt-maximun">
                Maximun Upload File size: 50 MB
            </div>            
            <button class="btn btn-success btn-select-files">
                Select Files
            </button>
        </div>

    </div>


    <div class="row" id="media-file-style">
        <div class="col-md-12">
            <ng-container *ngFor="let jobFile of mediaFile">

                <app-file-previewer path="/moving-files" [file]="jobFile.file"></app-file-previewer>

            </ng-container>
        </div>
    </div>
</div>