<div id="content" class="main-content">
    <div class="layout-px-spacing">
        
        <div class="page-header">            
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <div class="title">
                    <h3>Payment Details</h3>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Balance</a></li>
                    <li class="breadcrumb-item active" aria-current="page"><a href="javascript:void(0);">View</a></li>
                </ol>
            </nav>
        </div>

        <div class="row invoice layout-top-spacing layout-spacing">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                
                <div class="doc-container">

                    <div class="row">

                        <div class="col-xl-7">

                            <div class="invoice-container">
                                <div class="invoice-inbox">
                                    
                                    <div id="ct" class="">
                                        
                                        <div class="invoice-00001">
                                            <div class="content-section">

                                                <div class="inv--head-section inv--detail-section border-0 mb-2">
                                                
                                                    <div class="row">
                                                    
                                                        <div class="col-sm-6 col-12 mr-auto">
                                                            <img class="company-logo" src="{{environment.az_api.cdn}}/{{authService.session.workspace.id}}/media-files/{{authService.session.company.logo}}" alt="company">
                                                            
                                                        </div>

                                                        <div class="col-sm-6 text-sm-right">
                                                            <p class="inv-email-address">{{authService.session.company.name}}</p>
                                                            <p class="inv-email-address">{{authService.session.company.address.street}}</p>
                                                            <p class="inv-email-address">{{authService.session.company.address.city}}, {{authService.session.company.address.state}} {{authService.session.company.address.zip_code}}</p>
                                                            <p class="inv-email-address">IC MC: 54165 </p>
                                                            <p class="inv-email-address">US D.O.T: {{authService.session.company.dot}}</p>
                                                        </div>
                                                        
                                                    
                                                    </div>
                                                    
                                                </div>


                                                <div class="inv--detail-section inv--customer-detail-section">                                                   


                                                        <div class="row">

                                                            <div class="col-sm-6 align-self-center header-company-info invoice-to">
                                                                <p class="title-details mb-1">Invoice To</p>
                                                                <p><span class="inv-email-address">Name:</span> <span>{{authService.session.customer.name}} {{authService.session.customer.last_name}}</span></p>
                                                                <p><span class="inv-email-address">Phone:</span> <span>{{authService.session.customer.phone}}</span></p>
                                                                <p><span class="inv-email-address">Email:</span> <span>{{authService.session.customer.email}}</span></p>
                                                            </div>
                                                            <div class="col-sm-6 text-sm-right">
                                                                <ng-container   *ngIf="payment && payment.payments_transactions.length > 0">
                                                                    <p class="inv-created-date mb-0"><span class="inv-title">Transaction ID : </span> <span class="inv-date">{{payment.payments_transactions[0].code}}</span></p>
                                                                    <p class="inv-created-date mb-0"><span class="inv-title">Invoice Date : </span> <span class="inv-date">{{payment.payments_transactions[0].created}}</span></p>
                                                                </ng-container>  
                                                            </div>
                                                        </div>

                                                        <div class="row mt-4">                                                                                                                                                 
                                                            <div class="col-sm-6">
                                                                <div class="inv--payment-info"  *ngIf="payment && payment.payments_transactions.length > 0">
                                                                    <p class="title-details mb-1">Payment Info</p>
                                                                    <p class=""><span class=" inv-subtitle">Concept:</span> <span>{{currentrowBalance.concept}}</span></p>
                                                                    <p *ngIf="payment.payments_transactions[0].card_type"><span class=" inv-subtitle">Payment Type: </span> <span>{{payment.payments_transactions[0].card_type}}</span></p>
                                                                    <p  *ngIf="payment.payments_transactions[0].card_number"><span class=" inv-subtitle">Product:</span> <span>{{payment.payments_transactions[0].card_number}}</span></p>
                                                                    <!-- <p><span class=" inv-subtitle">Transaction ID:</span> <span>40034629016</span></p> -->
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6 align-self-end text-right">
                                                                <p class="inv-created-date mb-0 pr-3"><span class="inv-date payment-approved ">Payment Approved</span></p>
                                                                <div class="total-section mt-2 pr-3">Amount Paid {{currentrowBalance.amount | currency}}</div>
                                                                <!-- <p class="inv-list-number"><span class="inv-title">Invoice : </span> <span class="inv-number">#0001</span></p>
                                                                <p class="inv-list-number"><span class="inv-title">Invoice : </span> <span class="inv-number">#0001</span></p> -->
                                                            </div>  
                                                            
                                                            
                                                            <div class="col-xl-6 col-lg-5 col-md-6 col-sm-8 col-12 order-sm-0 order-1">
                                                                
                                                            </div>
                                                        </div>

                                                    
                                                </div>


                                            </div>
                                        </div> 
                                        
                                    </div>


                                </div>

                            </div>

                        </div>

                        <div class="col-xl-3">
                            <div class="invoice-actions-btn mt-0">
                                <div class="invoice-action-btn">
                                    <div class="row">
                                        <div class="col-xl-12 col-md-3 col-sm-6">
                                            <a href="javascript:void(0);" class="btn btn-success btn-download">Download</a>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>


                    </div>
                    
                    
                </div>

            </div>
        </div>
            
    </div>
</div>



