import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TicketView } from '../entities/ticket-view';
import { Ticket } from '../entities/ticket';
import { TicketType } from '../entities/ticket-type';

@Injectable({
    providedIn: 'root'
})
export class TicketsService {

    constructor(private http: HttpClient) {

    }

    getAllWithFullView(data: any): Promise<Array<TicketView>> {
        return this.http.get<Array<TicketView>>(environment.api.customers + '/tickets', { params: data }).toPromise();
    }

    getAll(): Promise<Array<Ticket>> {
        return this.http.get<Array<Ticket>>(environment.api.customers + '/tickets').toPromise();
    }

    getAllTypes(): Promise<Array<TicketType>> {
        return this.http.get<Array<TicketType>>(environment.api.customers + '/tickets-types').toPromise();
      }

    getById(id: string) {
        return this.http.get<Ticket>(environment.api.customers + '/tickets/' + id).toPromise();
    }

    save(dataForm: Ticket): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Ticket) {
        return this.http.post(environment.api.customers + '/tickets', dataForm).toPromise();
    }

    edit(dataForm: Ticket) {
        return this.http.put(environment.api.customers + '/tickets/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.customers + '/tickets/' + id).toPromise();
    }

    getExpiredTickets() {
        return this.http.get<Array<Ticket>>(environment.api.customers + '/dashboard/pending-tickets',
        { params: { 'full-view': '1' } }).toPromise();
    }
}
