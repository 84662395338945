export class Balance {
    public id: string;
    public allocated_to_payments_storage: number;
    public amount_paid: number;
    public amount: number;
    public carrier_id: string;
    public cc_fee: number;
    public certified_founds: boolean;
    public comissioning_employee_id: string;
    public company_id: string;
    public concept: string;
    public customer_id: string;
    public employee_id: string;
    public expiration_date: number;
    public is_signed: boolean;
    public job_amount: number;
    public job_id: string;
    public paid_to: string;
    public payment_allocation: string;
    public payment_gateway_setting_id: string;
    public payment_methods_enabled_id: Array<string>;
    public status: 'PENDING' | 'PROCESSING' | 'PAID' | 'PENDING_REVIEW' | 'PARTIAL_PENDING_REVIEW' | 'CERTIFIED_FOUNDS' | 'PARTIAL_PAID' | 'REFUNDED';
    public storage_service_id: string;
    public target: 'STORAGE' | 'OPERATIONS' | 'NEW_BOOKING' | 'QUALITY_ASSURANCE' | 'CARRIER_TO_BROKER';
    public created: number;
    public updated: number;


    constructor() {
        this.id = null;
        this.allocated_to_payments_storage = null;
        this.amount_paid = null;
        this.amount = null;
        this.carrier_id = null;
        this.cc_fee = 0;
        this.certified_founds = null;
        this.comissioning_employee_id = null;
        this.company_id = null;
        this.concept = null;
        this.customer_id = null;
        this.employee_id = null;
        this.expiration_date = null;
        this.is_signed = null;
        this.job_amount = null;
        this.job_id = null;
        this.paid_to = null;
        this.payment_allocation = null;
        this.payment_gateway_setting_id = null;
        this.payment_methods_enabled_id = [];
        this.status = 'PENDING';
        this.storage_service_id = null;
        this.target = 'NEW_BOOKING';
        this.created = null;
        this.updated = null;
    }
}
