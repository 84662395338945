export class CustomerEstimate {
    public id: string;
    public workspace_id: string;
    public company_id: string;
    public customer_id: string;
    public estimate_id: string;
    public estimate_document_id: string;
    public request_token: boolean;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.workspace_id = null;
        this.company_id = null;
        this.customer_id = null;
        this.estimate_id = null;
        this.estimate_document_id = null;
        this.request_token = false;
        this.created = null;
        this.updated = null;
    }
}
