<!-- CONTENT BREADCRUMB -->
<div class="breadcrumb-four mt-1">
    <ul class="breadcrumb">        
        <li>
            <a routerLink="/job-detail/{{customerEstimate.estimate_id}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
            </a>
        </li>
        <li class="text-breadcrumb">
            <a class="d-flex" aria-expanded="false" routerLink="/tickets" > 
                <span>Tickets</span>
            </a>
        </li>
        <li class="text-breadcrumb"><span>{{ticket.document.code}}</span></li>
    </ul>
  </div>
  <!-- CONTENT BREADCRUMB -->



<div class="layout-spacing layout-top-spacing">


    <div class="row sections-view-ticket">
        <div class="col-12 col-lg-8">
            <div class="user-profile layout-spacing">
                <div class="widget-content widget-content-area">

                    <div class="timeline-simple">
                        <p class="timeline-title">
                            <ng-container *ngFor="let typeTicket of ticketTypes">
                                <ng-container *ngIf="typeTicket.id == ticket.ticket_type_id">
                                    {{typeTicket.name | titlecase}}
                                </ng-container>
                            </ng-container>
                        </p>
    
                        <div class="timeline-list">

                            <div class="timeline-post-content post-gallery ">
                                <div class="user-profile ">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                </div>
                                <div >
                                    <h4>
                                        {{customer.name | titlecase}} {{customer.last_name | titlecase}}
                                    </h4>
                                    <p class="meta-time-date ">
                                        {{ticket.created | calendarDateTime}}
                                    </p>
                                    <div >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg> 
                                        &nbsp;
                                        <h6 >Remark</h6>
                                        <p class="post-text ">
                                            {{ticket.description}}
                                        </p>
                                        <div class="post-gallery-img" *ngIf="ticket.files.length > 0">
                                            <ng-container *ngFor="let file of ticket.files">
                                                <a [appS3Link]="'/tickets/' + file.display_name" target="_blank">
                                                    <img [appS3Link]="'/tickets/' + file.display_name"  alt="timeline ">
                                                </a>
                                            </ng-container> 
                                        </div>
                                    </div>
                                </div>
                            </div>
    
    
                            <div class="timeline-post-content post-gallery" *ngFor="let remark of remarks">
                                <div class="user-profile ">
                                    <!-- <img src="assets/img/90x90.jpg " alt="avatar "> -->
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                </div>
                                <div >
                                    <h4 *ngIf="remark.customer_id">
                                        {{remark.customer.name | titlecase}} {{remark.customer.last_name | titlecase}}
                                        <svg *ngIf="remark.private" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                    </h4>
                                    <h4 *ngIf="remark.employee_id">
                                        {{remark.employee.name | titlecase}}
                                        <svg *ngIf="remark.private" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                    </h4>
                                    <p class="meta-time-date ">
                                        {{remark.created | calendarDateTime}}
                                    </p>
                                    <div >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg> 
                                        &nbsp;
                                        <h6 >Company Reply</h6>
                                        <p class="post-text ">
                                            {{remark.remarks}}
                                        </p>
                                        <div class="post-gallery-img" *ngIf="remark.files.length > 0">
                                            <ng-container *ngFor="let file of remark.files">
                                                <a [appS3Link]="'/tickets/' + file.display_name" target="_blank">
                                                    <img [appS3Link]="'/tickets/' + file.display_name" alt="timeline ">
                                                </a>
                                            </ng-container> 
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
    
                    <div class="text-center">
                        <button class="btn btn-success btn-reply"  data-toggle="modal" data-target="#ticketResponse"> 
                            <i class="fa-solid fa-reply"></i>
                            Reply
                        </button>
                    </div>
    
                </div>
            </div>
            
        </div>
        <div class="col-12 col-lg-4">
            <div class="user-profile layout-spacing">
                <div class="widget-content widget-content-area">
                    <div class="d-flex justify-content-between">
                        <h3 >Info</h3>
                    </div>
                    <div class="user-info-list">
                        <div>
                            <ul class="contacts-block list-unstyled">

                                <li class="contacts-block__item">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                                        <strong>Ticket:</strong>
                                    </span>
                                    <span>
                                        {{ticket.document.code}}
                                    </span>
                                </li>

                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                                    <strong>Estimate: </strong> {{estimate['estimate'].document.code}}
                                </li>

                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>
                                    <strong>Status: </strong> {{ticket.status}}
                                </li>

                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    <strong>Created: </strong> {{ticket.created | dateTime}}
                                </li>
                      
                                
                            </ul>
                        </div>                                    
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->
</div>


<!-- Modal Response Tickets -->
<div class="modal fade" id="ticketResponse" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                    New Remark
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
            </div> -->
            <div class="modal-body">               
                <h5 class="modal-title" id="exampleModalCenterTitle">
                    Add Reply
                </h5>                
                
                <!-- <div class="upload mt-4">
                    <input type="file" id="input-file-max-fs" [appDropify] (onChange)="onChangeLogo($event)" class="dropify" 
                    data-max-file-size="2M" />
                    <div class="post-gallery-img" *ngIf="dataForm.files.length > 0">
                        <div *ngFor="let fil of dataForm.files">
                            {{fil.display_name}}
                        </div>
                    </div>
                </div> -->

                <div class="form-group mt-4">
                    <label class="control-label">Upload Images or Files</label>
                    <input type="file" id="input-file-max-fs" multiple [appDropify] (onChange)="onChangeLogo($event)" class="dropify" 
                        data-max-file-size="2M" />
                    <div class="post-gallery-img d-flex mt-2" *ngIf="dataForm.files.length > 0">
                        <div class="box-preview-image" *ngFor="let fil of dataForm.files">                    
                        <div class="delete-button-file" (click)="deleteImageItem(fil)">
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                        <img src="{{environment.api.base}}/tmp/{{fil.display_name}}" alt="avatar">  
                        </div>
                    </div>
                </div>

                <div class="form-group mt-4">
                    <label for="">
                        Reply 
                    </label>
                    <textarea type="text" placeholder="Type here..." rows="6" [(ngModel)]="dataForm.remarks" name="description" class="form-control textarea-remark"></textarea>
                </div>
            
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal"><i class="flaticon-cancel-12"></i> Discard</button>
                <button type="button" (click)="addRemarks()" class="btn btn-warning mr-3" data-dismiss="modal">Save</button>
            </div>
        </div>
    </div>
</div>