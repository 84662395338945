import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { ElectronicSignature } from 'src/app/entities/electronic-signature';
import { Estimate } from 'src/app/entities/estimate';
import { EstimateCustomerRemark } from 'src/app/entities/estimate-customer-remark';
import { EstimateCustomerRemarkView } from 'src/app/entities/estimate-customer-remark-view';
import { EstimateDocument } from 'src/app/entities/estimate-document';
import { EstimateEmail } from 'src/app/entities/estimate-email';
import { AuthService } from 'src/app/services/auth.service';
import { CustomersJobsService } from 'src/app/services/customers-jobs.service';
import {
  EstimatesCustomerRemarksService
} from 'src/app/services/estimates-customer-remarks.service';
import { EstimatesEmailsCustomerService } from 'src/app/services/estimates-emails-customer.service';
import { EstimatesService } from 'src/app/services/estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConstantsMessages } from '../../constants-messages';
import { DocumentPoaService } from '../../services/document-poa.service';

declare var jQuery;
declare const SignaturePad;
declare const swal;
declare const Snackbar;

@Component({
  selector: 'app-document-poa',
  templateUrl: './document-poa.component.html',
  styleUrls: ['./document-poa.component.scss']
})
export class DocumentPoaComponent implements OnInit {

  job: Estimate;
  document: any;
  signature: File;
  signatured: boolean;
  flagButtonSignature: string;
  public jobId: string;
  readonly environment = environment;
  public remark: string;
  public estimateCustomerRemark: EstimateCustomerRemark;
  public estimateCustomerRemarks: Array<EstimateCustomerRemarkView>;
  public customerEstimate: CustomerEstimate;

  public estimateEmail: EstimateEmail;

  public designate_fullname: string;

  public customer_fullname: string;
  public designate_phonenumber: string;

  @ViewChild('signModal')
  signModal: ElementRef;

  constructor(
    private currentRoute: ActivatedRoute,
    private customersJobsService: CustomersJobsService,
    public authService: AuthService,
    private estimatesCustomerRemarksService: EstimatesCustomerRemarksService,
    private router: Router,
    private estimatesService: EstimatesService,
    private helperService: HelperService,
    private estimatesEmailsCustomerService: EstimatesEmailsCustomerService,
    private documentPoaService: DocumentPoaService
  ) {
    this.job = new Estimate();
    this.document = '';
    this.jobId = '';
    this.remark = '';
    this.estimateCustomerRemark = new EstimateCustomerRemark();
    this.estimateCustomerRemarks = [];
    this.flagButtonSignature = '';
    this.estimateEmail = new EstimateEmail();
    this.signature = null;
  }

  ngOnInit(): void {
    // verficamos si se esta editando
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id_estimate !== 'undefined') {
        this.loadDocument(params.id_estimate);
        this.jobId = params.id_estimate;
      }
    });

    this.customerEstimate = this.helperService.loadCustomerServiceLocalStorage();
  }

  loadDocument(idJob: string): void {
    // this.helperService.showLoadingMxpro360();
    this.flagButtonSignature = '';

    this.documentPoaService
      .getDocumentPOA(idJob)
      .then(response => {
        this.loadEstimateEmail(idJob);
        this.customer_fullname = this.authService.session.customer.name + ' ' + this.authService.session.customer.last_name;
        this.document = response['document'];
        
        // this.estimatePdf(customerEstimate.id);

        jQuery('#estimatePdf').attr('src', environment.az_api.cdn + '/' + this.authService.session.workspace.id + '/documents/' + response.document.file.display_name);
      })
      .catch((error) => {
        this.helperService.showMessageError(error, idJob);
      })
      .finally(() => {
        // this.helperService.hideLoadingMxpro360();
      });
  }

  
  loadEstimateEmail(idJob): void {
    this.helperService.showLoadingMxpro360();
    this.estimatesEmailsCustomerService
        .getEstimatesEmail(idJob)
        .then(response => {
            this.estimateEmail = response;
            if (this.document.text.indexOf('@CUSTOMER_SIGNATURE') >= 0 && this.estimateEmail.request_poa == false) {
                this.flagButtonSignature = 'SIGNATURE_NOT_AVAILABLE';
            } else if (this.document.text.indexOf('@CUSTOMER_SIGNATURE') < 0 && this.estimateEmail.request_poa == false) {
                this.flagButtonSignature = 'SIGNED';
            } else {
                this.flagButtonSignature = 'NOT_SIGNED';
            }
            
        })
        .catch((error) => {
            this.helperService.showMessageError(error, this.job.id);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    // this.helperService.showLoadingMxpro360();
}

  openSignature() {
    this.signature = null;
    jQuery(this.signModal.nativeElement).modal('show');
  }

  closeSignature() {
    this.signature = null;
    jQuery(this.signModal.nativeElement).modal('hide');
  }

  print() {
    const printContent = document.getElementById("printer");
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

  downloadPdf() {
    this.helperService.downloadReport('CONTRACT_INFORMATION', null, this.document);
  }


  async save() {
    this.helperService.showLoadingMxpro360();
    const formData = new FormData();
    formData.append('files[]', this.signature);
    jQuery.ajax({
      url: environment.api.base + '/files',
      type: 'post',
      dataType: 'json',
      data: formData,
      cache: false,
      contentType: false,
      processData: false
    })
      .done((sign) => {

        let esignature: ElectronicSignature;
        esignature = new ElectronicSignature();
        esignature.esignature_file.name = sign.files[0];
        esignature.esignature_file.display_name = sign.files[0];
        esignature.internet_browser = this.helperService.detectBrowser();

        const data: { electronic_signature: ElectronicSignature, customer_fullname: string, designate_fullname: string, designate_phonenumber: string } = {
          electronic_signature: esignature,
          customer_fullname: this.customer_fullname,
          designate_fullname: this.designate_fullname,
          designate_phonenumber : this.designate_phonenumber
        }
        // this.helperService.showLoadingMxpro360();
        this.documentPoaService
          .putDocumentPOA(this.jobId, data)
          .then(response => {

            this.loadDocument(this.jobId);
            
          })
          .catch((error) => {
            this.helperService.showMessageError(error, this.jobId);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      });
  }

  // Crea el pdf en la vista
  estimatePdf(customerEstimate) {

    if (this.job.service === 'AUTO_TRANSPORT') {
      jQuery('#estimatePdf').attr('src', environment.api.customers + '/estimates/' + customerEstimate +
        '/export-pdf-autotransport?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token);
    }

    if (this.job.service === 'LONG') {
      jQuery('#estimatePdf').attr('src', environment.api.customers + '/estimates/' + customerEstimate + '/export-pdf-quote?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token);
    }

    if (this.job.service === 'LOCAL') {
      jQuery('#estimatePdf').attr('src', environment.api.customers + '/estimates/' + customerEstimate +
        '/export-pdf-estimate-local?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token);
    }
  }

  /**
   * Permite escuchar la firma
   */
  onSignature(signature: File) {
    this.signature = signature;
    this.helperService.showMessageSnackbar(ConstantsMessages.SIGNED);
  }

}
