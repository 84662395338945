// import { LeadView } from 'src/customers/entities/lead-view';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Customer } from '../entities/customer';
import { Estimate } from '../entities/estimate';
import { EstimateTransportVehicles } from '../entities/estimate-transport-vehicles';
import { EstimateView } from '../entities/estimate-view';

@Injectable({
    providedIn: 'root'
})
export class EstimatesService {

    constructor(private http: HttpClient) {

    }
    getAllWithFullView(): Promise<Array<EstimateView>> {
        return this.http.get<Array<EstimateView>>(environment.api.customers + '/estimates', { params: { "full-view": "1" } }).toPromise();
    }

    getAllAutoWithFullView(): Promise<Array<EstimateView>> {
        return this.http.get<Array<EstimateView>>(environment.api.customers + '/auto-estimates', { params: { "full-view": "1" } }).toPromise();
    }

    getAll(): Promise<Array<Estimate>> {
        return this.http.get<Array<Estimate>>(environment.api.customers + '/estimates').toPromise();
    }

    getById(id: string) {
        return this.http.get<EstimateView>(environment.api.customers + '/estimates/' + id).toPromise();
    }

    getAllWithFullViewById(data: any, id): Promise<Array<any>> {
        return this.http.get<any>(environment.api.customers + '/jobs/' + id).toPromise();
    }

    getByEstimateCode(code: string) {
        return this.http.get<Estimate | {message:string}>(environment.api.customers + '/estimates/search/' + code).toPromise();
    }

    toCreateByLead(id: string) {
        return this.http.post(environment.api.customers + '/leads/' + id + '/estimates', {}).toPromise();
    }

    duplicateLead(id: string) {
        return this.http.post(environment.api.customers + '/estimates/' + id + '/duplicate', {}).toPromise();
    }

    save(dataForm: Estimate): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Estimate) {
        return this.http.post(environment.api.customers + '/estimates', dataForm).toPromise();
    }

    edit(dataForm: Estimate) {
        return this.http.put(environment.api.customers + '/estimates/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.customers + '/estimates/' + id).toPromise();
    }

    patchEntity(id:string, dataForm: any) {
        return this.http.patch(environment.api.customers + '/estimates/' + id, dataForm).toPromise();
    }

    updatePickupAndDeliveryAddress(id:string, dataForm: any) {
        return this.http.put(environment.api.customers + '/estimates/' + id + '/update-pickup-and-delivery-address', dataForm).toPromise();
    }
    
    // CreateLead(data: LeadView) {
    //     return this.http.post(environment.api.customers + '/estimates', data).toPromise();
    // }

    getAutoTransport(id: string): Promise<Array<EstimateTransportVehicles>> {
        return this.http.get<Array<EstimateTransportVehicles>>(environment.api.customers + '/estimates/' + id + '/transport-vehicles').toPromise();
    }

    saveAutoTransport(estimate_id: string, estimateTransportVehicle:EstimateTransportVehicles) {
        return this.http.post<Array<EstimateTransportVehicles>>(environment.api.customers + '/estimates/' + estimate_id + '/transport-vehicles', estimateTransportVehicle).toPromise();
    }

    editAutoTransport(estimate_id: string, estimateTransportVehicle:EstimateTransportVehicles) {
        const autoTransportId = estimateTransportVehicle.id
        return this.http.put(environment.api.customers + '/estimates/' + estimate_id + '/transport-vehicles/' + autoTransportId, estimateTransportVehicle).toPromise();
    }
    removeAutoTransport(estimate_id: string, autoTransportId: string) {
        return this.http.delete(environment.api.customers + '/estimates/' + estimate_id + '/transport-vehicles/' + autoTransportId).toPromise();
    }
    bookEstimate(id: string) {
        return this.http.post(environment.api.customers + '/estimates/'+ id + '/toggle-book',{}).toPromise();
    }

    calculateLiabilityAmount(estimate_id: string) {
        return this.http.get(environment.api.customers + '/estimates/' + estimate_id + '/calculate-liability-amount').toPromise();
    }

    createNewContact(estimate_id: string, contact: Customer) {
        return this.http.post(environment.api.customers + '/estimates/' + estimate_id + '/create-contact', contact).toPromise();
    }

    loadEstimateDocument(estimate_id: string) {
        return this.http.get(environment.api.customers + '/estimates/' + estimate_id ).toPromise();
    }

}
