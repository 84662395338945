import { BalanceView } from 'src/app/entities/balance-view';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { PaymentMethod } from '../../entities/payment-method';
import { PaymentMethodView } from '../../entities/payment-method-view';
import { BalancesService } from '../../services/balances.service';
import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { Router } from '@angular/router';

declare const jQuery: any;
declare const swal: any;
@Component({
  selector: 'app-balances',
  templateUrl: './balances.component.html',
  styleUrls: ['./balances.component.scss']
})
export class BalancesComponent implements OnInit {

  public balances: Array<BalanceView>;
  public balanceTotal: number;
  public paidValue: number;
  public customerEstimate: CustomerEstimate;
  public enabledPartialPayment: boolean;
  public paymentAmount: number;
  public paymentMethod: PaymentMethodView;
  public showPartialPayment: boolean;
  public viewBalance: BalanceView;
  readonly environment = environment;

  constructor(
    private balancesService: BalancesService,
    private helperService: HelperService,
    private router: Router,
    public authService: AuthService,
  ) {
    this.balances = [];
    this.balanceTotal = 0;
    this.paidValue = 0;
    this.enabledPartialPayment = false;
    this.paymentAmount = null;
    this.paymentMethod = new PaymentMethodView();
    this.showPartialPayment = false;
    this.viewBalance = new BalanceView();
  }

  ngOnInit(): void {
    this.load();
    this.customerEstimate = this.helperService.loadCustomerServiceLocalStorage();
  }

  ngAfterViewInit(): void {
    
  }

  /**
   * consulta el arreglo del listado de pagos
   */
  load() {
    const customerEstimate = this.helperService.loadCustomerServiceLocalStorage();
    this.helperService.showLoadingMxpro360();
    const data = {
      company_id: this.authService.session.company.id,
      estimate_id: customerEstimate.estimate_id
    };

    this.balancesService
      .getAllCustomer(data)
      .then((response) => {
        this.balances = response;        
        this.rowCollapse();

        setTimeout(() => {          
          this.toggleCollapse();          
        }, 100);
        
      })
      .catch((error) => {
        this.helperService.showMessageError(error, data);

      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  loadPaymentMethods(balance: BalanceView) {
    this.paymentAmount = balance.amount - balance.amount_paid;
    this.paidValue = balance.amount_paid;
    this.paymentMethod.id = balance.id;
    this.paymentMethod.payment_methods = balance.payment_methods;
    this.balanceTotal = balance.amount + balance.cc_fee;
    this.enabledPartialPayment = balance.storage_service_id !== null;

    for (const paymentMethod of balance.payment_methods) {
      switch (paymentMethod.method) {
        case 'CREDIT_CARD':
          paymentMethod['logo'] = 'assets/img/credit-card-method.png';
          break;

        case 'BANK_TRANSFER_ONLINE':
          if (paymentMethod.name.toLowerCase() === 'ach') {
            paymentMethod['logo'] = 'assets/img/ach-method.png';
          } else {
            paymentMethod['logo'] = 'assets/img/bank-transfer-method.png';
          }
          break;

        case 'BANK_TRANSFER_OFFLINE':
          if (paymentMethod.name.toLowerCase() === 'zelle') {
            paymentMethod['logo'] = 'assets/img/zelle-method.png';
          } else {
            paymentMethod['logo'] = 'assets/img/bank-transfer-method.png';
          }
          break;

        case 'CHECK':
          paymentMethod['logo'] = 'assets/img/check-method.png';
          break;
      }
    }

  }

  makeFullPayment() {
    this.showPartialPayment = false;
    this.paymentAmount = null;
    const balanceId = this.paymentMethod.id;
    const balance = this.balances.find(balance => balance.id == balanceId);
    this.paymentAmount = balance.amount - balance.amount_paid;
  }

  public redirectToPayment(paymentMethod: PaymentMethod, idBalance: string) {
    const upTo = this.balanceTotal - this.paidValue;
    if (this.paymentAmount > upTo) {
      swal(
        'Attention!',
        'Please enter a payment amount up to $' + upTo.toFixed(2),
        'warning'
      );
      return;
    } else {
      this.helperService.redirectToPayment(paymentMethod, idBalance, this.paymentAmount);
    }
  }

  loadPayment(id: string, paymentId: string) {
    this.helperService.showLoadingMxpro360();
    this.balancesService
      .getDetailBalancePaymentById(id, paymentId)
      .then((response) => {
        this.viewBalance = response;
      })
      .catch((error) => {
        console.error('error', error);
        this.helperService.showMessageError(error, id);
    })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  /**
     * Se alterna entre agregar y eliminar el Collapse a los row
     */
  private toggleCollapse() {
    jQuery('#invoice-list tbody').on('click', '.headerCollapse', function () {
        jQuery(this).nextUntil('tr.header').slideToggle(500);
        jQuery(this).children('.rotate').toggleClass('down')
    });

    jQuery(window).resize(function () {
        if (jQuery(window).width() > 1024) {                
            jQuery("table tbody tr td").removeAttr("style");
        }
        else {
            jQuery(".tdHeader").addClass("headerCollapse");
            jQuery(".rowCollapse").css("display", "none");  
        }
    }); 
  }

  /**
   * Se define como estado inicial el Collapse para los row
   */
  private rowCollapse(){
      setTimeout(() => {
          if (jQuery(window).width() < 1024) {                
                  jQuery(".tdHeader").addClass("headerCollapse");
                  jQuery(".rowCollapse").css("display", "none");                
          }
          else {
              jQuery(".tdHeader").removeClass("headerCollapse");
              jQuery("table tbody tr td").removeAttr("style");
          }
      }, 50);
  }

  goSing(balance){
    this.router.navigateByUrl('/payment/' + balance.id + '/sign-payment');
  }
}
