import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { ElectronicSignature } from 'src/app/entities/electronic-signature';
import { Estimate } from 'src/app/entities/estimate';
import { EstimateEmail } from 'src/app/entities/estimate-email';
import { AuthService } from 'src/app/services/auth.service';
import { CustomersJobsService } from 'src/app/services/customers-jobs.service';
import { DocumentBolService } from 'src/app/services/document-bol.service';
import { EstimatesEmailsCustomerService } from 'src/app/services/estimates-emails-customer.service';
import { EstimatesService } from 'src/app/services/estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
declare var jQuery;
declare var swal;
@Component({
    selector: 'app-document-bol',
    templateUrl: './document-bol.component.html',
    styleUrls: ['./document-bol.component.scss']
})
export class DocumentBolComponent implements OnInit {

    job: Estimate;
    document: any;
    signature: File;
    signatured: boolean;
    public flagSignPriorPickup: string; // Signature Prior to Pickup
    public flagSignDuringPickup: string; // Signature During Pickup
    public flagSignDelivery: string; // Signature for Delivery
    public jobId: string;
    readonly environment = environment;
    public remark: string;
    public customerEstimate: CustomerEstimate;
    public estimateEmail: EstimateEmail;
    public designate_fullname: string;
    public customer_fullname: string;
    public designate_phonenumber: string;
    private bolSignatureRequested: string

    @ViewChild('signaturePriorPickupModal')
    signaturePriorPickupModal: ElementRef;


    constructor(
        private currentRoute: ActivatedRoute,
        private customersJobsService: CustomersJobsService,
        public authService: AuthService,
        private router: Router,
        private estimatesService: EstimatesService,
        private helperService: HelperService,
        private estimatesEmailsCustomerService: EstimatesEmailsCustomerService,
        private documentBolService: DocumentBolService
    ) {
        this.job = new Estimate();
        this.document = '';
        this.jobId = '';
        this.remark = '';
        this.flagSignPriorPickup = 'SIGNATURE_NOT_AVAILABLE';
        this.flagSignDuringPickup = 'SIGNATURE_NOT_AVAILABLE';
        this.flagSignDelivery = 'SIGNATURE_NOT_AVAILABLE';
        this.bolSignatureRequested = "";
        this.estimateEmail = new EstimateEmail();
        this.signature = null;
    }

    ngOnInit(): void {
        this.helperService.showLoadingMxpro360();
        this.customerEstimate = this.helperService.loadCustomerServiceLocalStorage();

        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id_estimate !== 'undefined') {
                this.jobId = params.id_estimate;
                this.customersJobsService.getById(this.customerEstimate.estimate_id, this.authService.session.company.id, this.customerEstimate.id)
                    .then((response) => {
                        this.job = response['estimate'];
                        this.loadDocument(params.id_estimate);
                    });
            } else {
                this.helperService.hideLoadingMxpro360();
            }
        });

    }

    loadDocument(idJob: string): void {
        this.flagSignPriorPickup = '';
        this.documentBolService
            .getDocumentBOL(idJob)
            .then(response => {
                this.loadestimateEmail(idJob);
                this.customer_fullname = this.authService.session.customer.name + ' ' + this.authService.session.customer.last_name;
                this.document = response['document'];
                //this.estimatePdf(this.customerEstimate.id);
                jQuery('#estimatePdf').attr('src', environment.az_api.cdn + '/' + this.authService.session.workspace.id + '/documents/' + response.document.file.display_name);
            })
            .catch((error) => {
                console.error("Error: ", error);
                if (error.status == 422) {
                    swal({
                        title: 'Warning!',
                        text: error.error.message,
                        type: 'warning',
                        padding: '2em'
                    })
                } else {
                    this.helperService.showMessageError(error, idJob);
                }

                this.helperService.hideLoadingMxpro360();
            });
    }


    loadestimateEmail(idJob): void {
        this.helperService.showLoadingMxpro360();
        this.estimatesEmailsCustomerService
            .getEstimatesEmail(idJob)
            .then(response => {
                this.estimateEmail = response;

                // validaciones para Sign Document (Prior to Pickup)
                if (this.document.esignatures.main) {
                    this.flagSignPriorPickup = 'SIGNED';
                } else if (this.estimateEmail.bol_signature_requested === "PRE_PICKUP") {
                    this.flagSignPriorPickup = 'NOT_SIGNED';
                } else if (this.document.esignatures.main === null) {
                    this.flagSignPriorPickup = 'SIGNATURE_NOT_AVAILABLE';
                }

                // validaciones para Sign Document (During Pickup)
                if (this.document.esignatures.second) {
                    this.flagSignDuringPickup = 'SIGNED';
                } else if (this.document.esignatures.second === null && this.estimateEmail.bol_signature_requested === "ON_SITE") {
                    this.flagSignDuringPickup = 'NOT_SIGNED';
                } else if (this.document.esignatures.second === null) {
                    this.flagSignDuringPickup = 'SIGNATURE_NOT_AVAILABLE';
                }

                // validaciones para Sign Document (For Delivery)
                if (this.document.esignatures.third) {
                    this.flagSignDelivery = 'SIGNED';
                } else if (this.document.esignatures.third === null && this.estimateEmail.bol_signature_requested === "DELIVERY") {
                    this.flagSignDelivery = 'NOT_SIGNED';
                } else if (this.document.esignatures.third === null) {
                    this.flagSignDelivery = 'SIGNATURE_NOT_AVAILABLE';
                }

            })
            .catch((error) => {
                this.helperService.showMessageError(error, this.job.id);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        // this.helperService.showLoadingMxpro360();
    }

    // Modales para las firmas del BOL
    // Document Prior to Pickup
    openSignatureBol(type: string) {
        this.signature = null;
        this.bolSignatureRequested = type;
        jQuery(this.signaturePriorPickupModal.nativeElement).modal('show');
    }

    closeSignature() {
        this.signature = null;
        jQuery(this.signaturePriorPickupModal.nativeElement).modal('hide');
    }

    print() {
        const printContent = document.getElementById("printer");
        const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
        WindowPrt.close();
    }

    downloadPdf() {
        this.helperService.downloadReport('CONTRACT_INFORMATION', null, this.document);
    }


    async save() {
        this.helperService.showLoadingMxpro360();
        const formData = new FormData();

        formData.append('files[]', this.signature);
        jQuery.ajax({
            url: environment.api.base + '/files',
            type: 'post',
            dataType: 'json',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        })
            .done((sign) => {

                let esignature: ElectronicSignature;
                esignature = new ElectronicSignature();
                esignature.esignature_file.name = sign.files[0];
                esignature.esignature_file.display_name = sign.files[0];
                esignature.internet_browser = this.helperService.detectBrowser();

                const data = {
                    electronic_signature: esignature,
                    bol_signature_requested: this.bolSignatureRequested
                }
                // this.helperService.showLoadingMxpro360();
                this.documentBolService
                    .putDocumentBOL(this.jobId, data)
                    .then(response => {
                        this.loadDocument(this.jobId);
                    })
                    .catch((error) => {
                        this.helperService.showMessageError(error, this.jobId);
                        this.helperService.hideLoadingMxpro360();
                    });
            });
    }

    // Crea el pdf en la vista
    estimatePdf(customerEstimate) {

        let urlPdf = "null";

        if (this.job.service === 'AUTO_TRANSPORT') {
            urlPdf = environment.api.customers + '/estimates/' + customerEstimate + '/export-pdf-autotransport?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token;
        }

        if (this.job.service === 'LONG') {
            urlPdf = environment.api.customers + '/estimates/' + customerEstimate + '/export-pdf-quote?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token;
        }

        if (this.job.service === 'LOCAL') {
            urlPdf = environment.api.customers + '/estimates/' + customerEstimate + '/export-pdf-estimate-local?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token;
        }

        if (this.job.service === 'FREIGHT') {
            urlPdf = environment.api.customers + '/estimates/' + customerEstimate + '/export-pdf-freight?company_id=' + this.authService.session.company.id + '&Authorization=' + this.authService.session.token;
        }

        jQuery('#estimatePdf').attr('src', urlPdf);
    }

    /**
     * Permite escuchar la firma
     */
    onSignature(signature: File) {
        this.signature = signature;
        this.helperService.showMessageSnackbar(ConstantsMessages.SIGNED);
    }

}
