import { Pipe, PipeTransform } from '@angular/core';

import { BetweenDates } from '../entities/between-dates';

@Pipe({
    name: 'filterBetween'
})
export class FilterBetweenPipe implements PipeTransform {

    transform(items: Array<any>, filterBetween: BetweenDates = null, filterField: string = null): Array<any> {
        // filtramos para devolver
        return items.filter(item => {
            return this.filter(filterBetween, filterField, item);
        });
    }
    // valida que la fecha segun el campo seleccionado este entre el rango de fechas del filtro
    private filter(filterBetween: BetweenDates, filterField: string, item: {}) {
        // si no hay fechas se devuelve el dato 
        if (filterBetween == null || filterField == null) {
            return item;
        }
        if (item[filterField] >= filterBetween.dateStart && item[filterField] <= filterBetween.dateEnd) {
            return item;
        }
    }

}
