import { BalanceListView } from './balance-list-view';
import { Carrier } from './carrier';
import { Company } from './company';
import { Customer } from './customer';
import { Document } from './document';
import { Payment } from './payment';
import { PaymentMethod } from './payment-method';
import { PaymentTransaction } from './payment-transaction';

export class BalanceView extends BalanceListView {

    company: Company;
    carrier: Carrier;
    payment: Payment;
    estimate_document: Document;
    customer: Customer;
    pickup_date: number;
    payment_method: PaymentMethod;
    payment_methods: Array<PaymentMethod>;
    payment_transaction: PaymentTransaction;

    constructor() {
        super();
        this.carrier = new Carrier;
        this.company = new Company;
        this.payment = new Payment;
        this.estimate_document = new Document();
        this.estimate_document = new Document();
        this.customer = new Customer();
        this.pickup_date = null;
        this.payment_method = new PaymentMethod();
        this.payment_methods = [];
        this.payment_transaction = new PaymentTransaction();
    }
}