import { Address } from './address';

export class Carrier {
    public id: string;
    public logo: string;
    public name: string;
    public address: Address;
    public fax: string;
    public website: string;
    public dot: string;
    public iccmc: string;
    public insurance_due_date: number;
    public active: boolean;
    public federal_tax_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.logo = null;
        this.name = null;
        this.address = new Address();
        this.fax = null;
        this.website = null;
        this.dot = null;
        this.iccmc = null;
        this.insurance_due_date = null;
        this.active = null;
        this.federal_tax_id = null;
        this.created = null;
        this.updated = null;
    }
}
