<div id="tabsIcons" class="col-lg-12 col-12 layout-spacing container-sign p-0">
    
    <div class="title-add-signature">Use one of the following options to sign the document</div>

    <div class="statbox box box-shadow pt-4">

        <div class="widget-content widget-content-area icon-tab size-container-signature p-0 border-0">
            <ul class="nav nav-tabs justify-content-start" id="iconTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="icon-home-tab" data-toggle="tab" (click)="setSection('TYPE')" href="#sign-type" role="tab"
                        aria-controls="icon-home" aria-selected="true">
                        <svg aria-hidden="true" focusable="false"
                            data-prefix="fas" data-icon="keyboard" class="svg-inline--fa fa-keyboard fa-w-18" role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                            <path fill="currentColor"
                                d="M528 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h480c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM128 180v-40c0-6.627-5.373-12-12-12H76c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm-336 96v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm-336 96v-40c0-6.627-5.373-12-12-12H76c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm288 0v-40c0-6.627-5.373-12-12-12H172c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h232c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12z">
                            </path>
                        </svg> 
                        Type
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="icon-contact-tab" data-toggle="tab" (click)="setSection('DRAW')" href="#sign-draw" role="tab"
                        aria-controls="icon-contact" aria-selected="false">
                        <svg aria-hidden="true" focusable="false"
                            data-prefix="fas" data-icon="pen-nib" class="svg-inline--fa fa-pen-nib fa-w-16" role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor"
                                d="M136.6 138.79a64.003 64.003 0 0 0-43.31 41.35L0 460l14.69 14.69L164.8 324.58c-2.99-6.26-4.8-13.18-4.8-20.58 0-26.51 21.49-48 48-48s48 21.49 48 48-21.49 48-48 48c-7.4 0-14.32-1.81-20.58-4.8L37.31 497.31 52 512l279.86-93.29a64.003 64.003 0 0 0 41.35-43.31L416 224 288 96l-151.4 42.79zm361.34-64.62l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.75 18.75-49.15 0-67.91z">
                            </path>
                        </svg> 
                        Draw
                    </a>
                </li>
            </ul>
            <div class="tab-content" id="iconTabContent-1">
                <div class="tab-pane fade show active" id="sign-type" role="tabpanel" aria-labelledby="icon-home-tab">
                    <label for="">Type Your Full Name</label>
                    <input class="form-control" type="text" [(ngModel)]="textSignature" (keyup)="onBlurTextSignature()" (keydown)="disableSaveButton()" placeholder="e.g. John Turner Doe">
                    <br>
                    <div class="col-12">
                        <p class="preview-signature-text" #elemTextSignaturePreview >
                           <span>{{textSignature | titlecase}}</span>
                        </p>
                    </div>
                </div>
                <div class="tab-pane fade" id="sign-draw" role="tabpanel" aria-labelledby="icon-contact-tab">
                    <label for="">Draw you Signature</label>                    
                    <div class="wrapper-sign">
                        <button class="btn btn-danger clean-signature" (click)="cleanSignaturePad()">
                            <i class="fa-solid fa-xmark"></i>
                        </button>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="signature"
                        class="svg-inline--fa fa-signature fa-w-20" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                            <path fill="currentColor" d="M623.2 192c-51.8 3.5-125.7 54.7-163.1 71.5-29.1 13.1-54.2 24.4-76.1 24.4-22.6 0-26-16.2-21.3-51.9 1.1-8 11.7-79.2-42.7-76.1-25.1 1.5-64.3 24.8-169.5 126L192 182.2c30.4-75.9-53.2-151.5-129.7-102.8L7.4 116.3C0 121-2.2 130.9 2.5 138.4l17.2 27c4.7 7.5 14.6 9.7 22.1 4.9l58-38.9c18.4-11.7 40.7 7.2 32.7 27.1L34.3 404.1C27.5 421 37 448 64 448c8.3 0 16.5-3.2 22.6-9.4 42.2-42.2 154.7-150.7 211.2-195.8-2.2 28.5-2.1 58.9 20.6 83.8 15.3 16.8 37.3 25.3 65.5 25.3 35.6 0 68-14.6 102.3-30 33-14.8 99-62.6 138.4-65.8 8.5-.7 15.2-7.3 15.2-15.8v-32.1c.2-9.1-7.5-16.8-16.6-16.2z">                                
                            </path>
                        </svg>
                        <canvas #signCanvas width="100%" height="100px"></canvas>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>