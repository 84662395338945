import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'teamEmployeeFilter'
})
export class TeamEmployeeFilterPipe implements PipeTransform {

  transform(items: Array<any>, team, employee): Array<any> {
    return items.filter(item => {
      return this.filter(team, employee, item);
    });
  }
  // valida que la fecha segun el campo seleccionado este entre el rango de fechas del filtro
  private filter(team, employee, item: {}) {
    // si no hay fechas se devuelve el dato 
    if (team.id == '' && employee.id == '') {
      return item;
    }
    if (team.id != '' && team.id == item[team.fieldName]) {
      return item;
    }
    if (employee.id !== '' && employee.id == item[employee.fieldName]) {
      return item;
    }
  }

}
