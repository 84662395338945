export class RecurringCustomer {
    public id: string;
    public estimates_id: string;
    public ip: string;
    public user_agent: string;
    public company_id: string;
    public created: Date;

    constructor() {
        this.id = null;
        this.estimates_id = null;
        this.ip = null;
        this.user_agent = null;
        this.company_id = null;
        this.created = new Date();
    }
}
