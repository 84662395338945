import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Estimate } from 'src/app/entities/estimate';
import { EstimateInventoryContainer } from 'src/app/entities/estimate-inventory-container';
import { EstimateInventoryContainerItem } from 'src/app/entities/estimate-inventory-container-item';
import { InventoryCategory } from 'src/app/entities/inventory-category';
import { InventoryContainer } from 'src/app/entities/inventory-container';
import { InventoryItem } from 'src/app/entities/inventory-item';
import { ItemContainerPath } from 'src/app/entities/item-container-path';
import { CustomersInventoryCategoriesService } from 'src/app/services/customers-inventory-categories.service';
import { EstimatesService } from 'src/app/services/estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { InventoryCategoriesService } from 'src/app/services/inventory-categories.service';
import { InventoryItemsService } from 'src/app/services/inventory-items.service';
import { QuotesItemsContainersService } from 'src/app/services/quotes-items-containers.service';
declare const jQuery: any;
declare const swal;

@Component({
  selector: 'app-inventory-item',
  templateUrl: './inventory-item.component.html',
  styleUrls: ['./inventory-item.component.scss']
})
export class InventoryItemComponent implements OnInit {

  @ViewChild('inventoryItemModal') inventoryItemModal: ElementRef;
  @ViewChild('searchField') searchField: ElementRef;
  @ViewChild('customContainerModal') customContainerModal: ElementRef;
  @ViewChild('itemsContainer') itemsContainer: ElementRef;

  public jobId: string;
  estimate: Estimate;
  inventoryItems: Array<InventoryItem>;
  inventoryCategories: Array<InventoryCategory>;
  itemContainerPath: ItemContainerPath;
  dataFormItem: EstimateInventoryContainerItem;
  viewInventoryItemModal: 'SELECT' | 'CUSTOM_ITEM' | 'QTY';
  containers: Array<EstimateInventoryContainer>;
  dataFormContainer: EstimateInventoryContainer;

  public totalItems: number;
  public totalCF: number;
  searchItemByName: string;
  searchItemByCategory: string;

  /*
  Constantes que contiene el tipo de mensaje a mostrar
  */
  constantsMessages = ConstantsMessages;

  constructor(
    private currentRoute: ActivatedRoute,
    private estimatesService: EstimatesService,
    private customersInventoryCategoriesService: CustomersInventoryCategoriesService,
    private ref: ChangeDetectorRef,
    private inventoryItemsQuotesService: InventoryItemsService,
    private quoteItemContainersService: QuotesItemsContainersService,
    private helperService: HelperService,
  ) {
    this.estimate = new Estimate();
    this.dataFormContainer = new EstimateInventoryContainer();
    this.dataFormItem = new EstimateInventoryContainerItem();

    this.inventoryItems = [];
    this.inventoryCategories = [];
    this.containers = [];

    this.searchItemByName = null;
    this.searchItemByCategory = null;
    this.viewInventoryItemModal = 'SELECT';
    this.itemContainerPath = new ItemContainerPath();
    this.totalItems = 0;
    this.totalCF = 0;
    this.jobId = '';
  }

  ngOnInit(): void {
    // verficamos si se esta editando
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id_estimate !== 'undefined') {
        this.jobId = params.id_estimate;
        this.loadInventory(params.id_estimate);
        this.loadEstimate(params.id_estimate)
      }
    });

  }

  ngOnDestroy() {
    if (localStorage.getItem('inventory_' + this.estimate.id)) {
      swal({
        title: 'You have a current inventory',
        text: 'Would you like to save?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes'
      })
        .then((result) => {
          if (result.value) {
            this.saveInventory();
          }
        });
    } else {
      this.containers = undefined; // unset
      delete (this.containers); // this removes the variable completely
      this.estimate = undefined; // unset
      delete (this.estimate); // this removes the variable completely
    }

    this.dataFormContainer = undefined; // unset
    delete (this.dataFormContainer); // this removes the variable completely

    this.dataFormItem = undefined; // unset
    delete (this.dataFormItem); // this removes the variable completely

    this.inventoryItems = undefined; // unset
    delete (this.inventoryItems); // this removes the variable completely

    this.inventoryCategories = undefined; // unset
    delete (this.inventoryCategories); // this removes the variable completely

    this.searchItemByName = undefined; // unset
    delete (this.searchItemByName); // this removes the variable completely

    this.searchItemByCategory = undefined; // unset
    delete (this.searchItemByCategory); // this removes the variable completely

    this.viewInventoryItemModal = undefined; // unset
    delete (this.viewInventoryItemModal); // this removes the variable completely

    this.itemContainerPath = undefined; // unset
    delete (this.itemContainerPath); // this removes the variable completely
  }

  private loadEstimate(id: string) {
    this.helperService.showLoadingMxpro360();
    this.estimatesService
      .getById(id)
      .then((response: any) => {
        this.estimate = response.estimate;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  ngAfterViewInit(): void {
    this.loadInventoryCategories();
    this.loadInventoryItems();

    // verficamos si se esta editando
    this.currentRoute.parent.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.loadEstimate(params.id);
        this.enableSortable();
      }
    });
  }

  loadInventory(estimate_id: string) {
    this.helperService.showLoadingMxpro360();
    if (estimate_id == null) {
      estimate_id = this.jobId;
    }
    this.totalItems = 0;
    let autoSaveInventory = JSON.parse(localStorage.getItem('inventory_' + estimate_id));
    this.quoteItemContainersService
      .get(estimate_id)
      .then((response) => {

        if (autoSaveInventory) {
          swal({
            title: 'You have a recent inventory for this job',
            text: 'Would you like to continue?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes'
          })
            .then((result) => {              
              if (result.value) {
                this.containers = autoSaveInventory;
                this.calculateTotals();
                this.enableSortable();
                this.reloadMasonry();

              } else {
                localStorage.removeItem('inventory_' + this.estimate.id);
                this.containers = response;
                this.calculateTotals();
                this.enableSortable();
                this.reloadMasonry();
              }
            });
        } else {
          this.containers = response;
          this.calculateTotals();
          this.enableSortable();
          this.reloadMasonry();
        }

      })
      .catch((error) => {
        swal({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private loadInventoryItems() {
    this.helperService.showLoadingMxpro360();
    this.inventoryItemsQuotesService
      .getAll()
      .then((response) => {
        this.inventoryItems = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  saveInventory() {
    this.helperService.showLoadingMxpro360();
    const customerEstimate: any = JSON.parse(localStorage.getItem('customer_estimate'));

    this.quoteItemContainersService
      .set(this.containers, customerEstimate.estimate_id)
      .then((response) => {
        localStorage.removeItem('inventory_' + this.estimate.id);

        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
        // this.helperService.goToCompanyRouterLink('/moving/' + this.estimate.id + '/estimate');
      })
      .catch((error) => {
        swal({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  addCustomContainer() {
    this.dataFormContainer.id = null;
    this.dataFormContainer.name = null;
    jQuery(this.customContainerModal.nativeElement).modal('show');
  }

  addItemInventory(containerIndex: number) {
    this.itemContainerPath.container_index = containerIndex;
    this.itemContainerPath.item_index = null;
    this.dataFormItem = new EstimateInventoryContainerItem();
    this.viewInventoryItemModal = 'SELECT';
    this.searchItemByName = null;
    this.searchItemByCategory = null;

    // Realiza la busqueda el container el cual se esta abriendo para filtrar
    // los items por su categoria
    for (const iterator of this.inventoryCategories) {
      if (iterator.name === this.containers[containerIndex].name) {
        this.searchItemByCategory = iterator.id;
      }
    }

    jQuery(this.inventoryItemModal.nativeElement).modal('show');
    this.enableSortable();
    setTimeout(() => {
      this.searchField.nativeElement.focus();
    }, 700);
  }

  private loadInventoryCategories() {

    this.helperService.showLoadingMxpro360();
    this.customersInventoryCategoriesService
      .getAll()
      .then((response) => {
        this.inventoryCategories = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private enableSortable() {
    const that = this;
    let idContainers = '';

    // Se agregan todos los ids de los containers
    // for (const iterator of this.containers) {
    for (let index in this.containers) {
      idContainers += '#sortable_' + index + ',';
    }

    // Se elimina la ultima coma de la cadena de ids de los containers
    idContainers = idContainers.substring(0, idContainers.length - 1);
    setTimeout(() => {

      jQuery(idContainers).sortable({
        connectWith: '.connectedSortable',
        stop: (event, ui) => {
          const indexContainer = ui.item[0].attributes.indexContainer.value;
          const idItem = ui.item[0].attributes.idItem.value;

          const positionReciverContainer = ui.item[0].parentNode.attributes.container.value;

          for (const item of that.containers[indexContainer].items) {
            if (item.id === idItem && indexContainer !== positionReciverContainer) {
              that.containers[positionReciverContainer].items.push(item);
              ui.item.remove();
              that.containers[indexContainer].items = that.containers[indexContainer].items.filter(item =>
                item.id !== idItem);
            }
          }
          that.ref.detectChanges();
          that.reloadMasonry();
          // that.saveInventory();
          that.saveTmpInventory();
        }
      }).disableSelection();

      jQuery('.task-list-section').masonry({
        // options
        itemSelector: '.connect-sorting',
        columnWidth: 400
      });
    }, 50);
  }

  reloadMasonry() {
    setTimeout(() => {
      jQuery('.task-list-section').masonry('reloadItems');
      jQuery('.task-list-section').masonry('layout');
    }, 100);
  }

  saveTmpInventory() {
    localStorage.setItem('inventory_' + this.estimate.id, JSON.stringify(this.containers));
    this.enableSortable();
    this.calculateTotals();
    this.reloadMasonry();

  }

  public calculateTotals(): void {
    this.totalCF = 0;
    this.totalItems = 0;
    for (const container of this.containers) {
      for (const item of container.items) {
        this.totalCF = this.totalCF + (item.inventory_item.cubic_feet * item.quantity);
        this.totalItems = this.totalItems + item.quantity;
      }
    }
    // determina si se debe sumar redondear la cantidad de pies cubicos
    const whole = Math.floor(this.totalCF);
    const fraction = this.totalCF - whole;
    if (fraction > 0) {
      // siempre redondeo por encima
      this.totalCF = whole + 1;
    }
  }

  deleteItemQuote(containerIndex: number, itemIndex: number) {
    this.containers[containerIndex].items.splice(itemIndex, 1);
    this.saveTmpInventory();
  }

  editItemQuote(containerIndex: number, itemIndex: number) {
    this.viewInventoryItemModal = 'QTY';
    this.itemContainerPath.container_index = containerIndex;
    this.itemContainerPath.item_index = itemIndex;
    this.dataFormItem = this.containers[containerIndex].items[itemIndex];
    jQuery(this.inventoryItemModal.nativeElement).modal('show');
    this.searchItemByName = undefined;
  }

  moreQty(Container, Qi) {
    this.containers[Container].items[Qi].quantity++;
    this.calculateTotals();
    this.saveTmpInventory();
  }

  lessQty(Container, Qi) {
    if (this.containers[Container].items[Qi].quantity > 1) {
      this.containers[Container].items[Qi].quantity--;
      this.calculateTotals();
      this.saveTmpInventory();
    }
  }
  clearContainer(index: number) {
    this.containers[index].items = [];
    this.saveTmpInventory();
  }

  deleteContainer(index: number) {
    this.containers.splice(index, 1);
    this.saveTmpInventory();
  }

  editContainer(index: string) {
    this.dataFormContainer.id = index;
    this.dataFormContainer.name = this.containers[index].name;
    jQuery(this.customContainerModal.nativeElement).modal('show');
  }

  addContainer(defaultContainer: InventoryContainer) {

    const container = new EstimateInventoryContainer();
    container.name = defaultContainer.name;

    this.containers.push(container);
    this.saveTmpInventory();
  }

  onKeydownSearch() {
    this.searchItemByCategory = null;
    this.searchItemByName = null;
  }
  saveContainer() {
    if (this.dataFormContainer.id == null) {
      const container = new EstimateInventoryContainer();
      container.name = this.dataFormContainer.name;
      this.containers.push(container);
    } else {
      this.containers[this.dataFormContainer.id].name = this.dataFormContainer.name;
    }
    this.saveTmpInventory();
    jQuery(this.customContainerModal.nativeElement).modal('hide');
  }

  saveAll() {
    this.saveItemQuote(true);
    jQuery(this.inventoryItemModal.nativeElement).modal('hide');
  }

  saveItemQuote(update) {

    if (!update) {
      for (let item of this.containers[this.itemContainerPath.container_index].items) {
        if (item.inventory_item.name == this.dataFormItem.inventory_item.name) {
          item.quantity++;
          this.dataFormItem = new EstimateInventoryContainerItem();
          return;
        }
      }
    }

    if (this.itemContainerPath.item_index == null) {

      // No se agrega un inventory container item si viene nulo
      if (this.dataFormItem.inventory_item.name !== null && this.dataFormItem.inventory_item.cubic_feet > 0) {
        this.containers[this.itemContainerPath.container_index].items.push(this.dataFormItem);
      }
    } else {
      this.containers[this.itemContainerPath.container_index].items[this.itemContainerPath.item_index] = this.dataFormItem;
    }

    // Se verifica si se va a actualizar un item
    if (update) {

      // Se verifica el tipo de modal
      if (this.viewInventoryItemModal === 'CUSTOM_ITEM') {
        this.viewInventoryItemModal = 'SELECT';
        jQuery(this.inventoryItemModal.nativeElement).modal('show');
      }
      else {
        jQuery(this.inventoryItemModal.nativeElement).modal('hide');
      }
      // Se actualiza el inventario
      this.saveTmpInventory();
    }

    // Se inicializa de nuevo los items
    this.dataFormItem = new EstimateInventoryContainerItem();
    setTimeout(() => {
      this.itemsContainer.nativeElement.scrollTop = 9999;
    }, 50);

  }

  closeModalInventory() {
    // Se verifica el tipo de modal
    if (this.viewInventoryItemModal === 'CUSTOM_ITEM') {
      this.viewInventoryItemModal = 'SELECT';
      this.searchItemByName = undefined;
      jQuery(this.inventoryItemModal.nativeElement).modal('show');
    }
    else {
      jQuery(this.inventoryItemModal.nativeElement).modal('hide');
      this.loadInventory(this.estimate.id);
    }
  }

  setItemTemp(item: InventoryItem) {
    this.dataFormItem.inventory_item = item;
    this.dataFormItem.quantity = 1;
    this.saveItemQuote(false);
  }

  filterItemsByCategory(category: string) {
    const getScrollContainer = document.querySelector('.scroll-default');
    getScrollContainer.scrollTop = 0;
    this.searchItemByCategory = category;
  }

  setCustomItem() {
    this.viewInventoryItemModal = 'CUSTOM_ITEM';
    this.dataFormItem = new EstimateInventoryContainerItem();
    this.dataFormItem.quantity = 1;
  }

}
