import { Capability } from './capability';
export class Rol {
    public id: string;
    public name: string;
    public capabilities: Array<Capability>;
    public created: Date;
    public updated: Date;

    constructor() {
        this.id = null;
        this.name = null;
        this.capabilities = null;
        this.created = null;
        this.updated = null;
    }
}
