import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { HelperService } from 'src/app/services/helper.service';
import { RecurringCustomer } from 'src/app/entities/recurring-customer';
import { RecurringCustomersService } from 'src/app/services/recurring-customers.service';

declare const swal;

@Component({
  selector: 'app-sign-from-storage',
  templateUrl: './sign-from-storage.component.html',
  styleUrls: ['./sign-from-storage.component.scss']
})
export class SignFromStorageComponent implements OnInit {

  private balanceId: string;
  constructor(
    private authService: AuthService,
    private currentRoute: ActivatedRoute,
    private helperService: HelperService,
    private recurringCustomersService: RecurringCustomersService,
    public router: Router,
  ) { }

  ngOnInit(): void {
      // verficamos si se esta editando
      this.currentRoute.params.subscribe(params => {
        try {
            if (params.id_customer_estimate) {
                this.loginCustomer(params.id_customer_estimate);
            }

        } catch (error) {
            console.error('Error: ', error);
            swal(
                'Error',
                error.error.message,
                'warning'
            );
        }
    });
  }

  loginCustomer(idCustomerEstimate) {
    this.authService
        .loginCustomer(idCustomerEstimate)
        .then((response: CustomerEstimate) => {
            const recurringCustomer = new RecurringCustomer();
            recurringCustomer.estimates_id = response.estimate_id;
            recurringCustomer.company_id = this.authService.session.company.id;
    
            this.recurringCustomersService
            .add(recurringCustomer);

            this.router.navigateByUrl('/balances');
            this.helperService.showMessageSnackbar(ConstantsMessages.SESSION_STARTED);
        })
        .catch((error) => {
            console.error('Error', error);
            this.helperService.showMessageError(error, idCustomerEstimate);
        })
        .finally(() => {
        });
}

}
