import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'zeroFill'
})
export class ZeroFillPipe implements PipeTransform {

    transform(value: number | string): string {
        return String(value).padStart(5, "0");
    }

}
