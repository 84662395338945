import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { CustomersJobsService } from 'src/app/services/customers-jobs.service';
import { DocumentPPWService } from 'src/app/services/document-ppw.service';
import { environment } from 'src/environments/environment';
import { Estimate } from 'src/app/entities/estimate';
import { EstimateEmail } from 'src/app/entities/estimate-email';
import { EstimatesEmailsCustomerService } from 'src/app/services/estimates-emails-customer.service';
// import { File } from 'src/app/entities/file';
import { HelperService } from 'src/app/services/helper.service';
import { ConstantsMessages } from 'src/app/constants-messages';
import { EstimateDocument } from 'src/app/entities/estimate-document';

declare const jQuery: any;
declare const swal: any;

@Component({
  selector: 'app-document-ppw',
  templateUrl: './document-ppw.component.html',
  styleUrls: ['./document-ppw.component.scss']
})
export class DocumentPPWComponent implements OnInit {
  public customerEstimate: CustomerEstimate;

  private estimateDocumentId: string
  private jobId: string;
  public document: any;
  public estimateEmail: EstimateEmail;
  public job: Estimate;
  public signature: File;
  public signatured: boolean;

  @ViewChild('signModal') signModal: ElementRef;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private customersJobsService: CustomersJobsService,
    private documentPPWService: DocumentPPWService,
    private estimatesEmailsCustomerService: EstimatesEmailsCustomerService,
    private helperService: HelperService,
  ) {
    this.estimateDocumentId = '';
    this.jobId = ''
  }

  ngOnInit(): void {
    this.helperService.showLoadingMxpro360();
      this.customerEstimate = this.helperService.loadCustomerServiceLocalStorage();

      // verficamos si se esta editando
      this.activatedRoute.params.subscribe(params => {
          if (typeof params.id_estimate !== 'undefined') {
              this.jobId = params.id_estimate;
              this.customersJobsService.getById(this.customerEstimate.estimate_id, this.authService.session.company.id, this.customerEstimate.id)
                  .then((response) => {
                      this.job = response['estimate'];
                      this.loadestimateEmail();
                  });
          } else {
              this.helperService.hideLoadingMxpro360();
          }
      });
  }

  loadDocument(idJob: string, documentTypeId: string): void {
    this.documentPPWService
    .getDocumentPPW(idJob, documentTypeId)
    .then(response => {
        // this.customer_fullname = this.authService.session.customer.name + ' ' + this.authService.session.customer.last_name;
        this.estimateDocumentId = response.id;
        this.document = response.document;
        this.signatured = this.document.esignatures.main !== null;
        jQuery('#estimatePdf').attr('src', environment.az_api.cdn + '/' + this.authService.session.workspace.id + '/documents/' + response.document.file.display_name);
    })
    .catch((error) => {
        console.error("Error1: ", error);
        if (error.status == 422) {
            swal({
                title: 'Warning!',
                text: error.error.message,
                type: 'warning',
                padding: '2em'
            })
        } else {
            this.helperService.showMessageError(error, idJob);
        }

        this.helperService.hideLoadingMxpro360();
    });
  }

  loadestimateEmail(): void {
    this.helperService.showLoadingMxpro360();
    this.estimatesEmailsCustomerService
        .getPPWEstimatesEmail(this.jobId)
        .then(response => {
            this.estimateEmail = response;
            this.loadDocument(this.jobId, this.estimateEmail.ppw_document_type_id);
        })
        .catch((error) => {
          console.error("Error2: ", error);
            this.helperService.showMessageError(error, this.job.id);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    // this.helperService.showLoadingMxpro360();
  }


  openSignature() {
    this.signature = null;
    jQuery(this.signModal.nativeElement).modal('show');
  }

  closeSignature() {
    this.signature = null;
    jQuery(this.signModal.nativeElement).modal('hide');
  }

  /**
     * Permite escuchar la firma
     */
  onSignature(signature: File) {
    this.signature = signature;
    this.helperService.showMessageSnackbar(ConstantsMessages.SIGNED);
  }

  async save() {

    const that = this;
    const formData = new FormData();
    formData.append('files[]', this.signature);
    jQuery.ajax({
        url: environment.api.base + '/files',
        type: 'post',
        dataType: 'json',
        data: formData,
        cache: false,
        contentType: false,
        processData: false
    })
    .done((sign) => {
      let data: EstimateDocument;
      data = new EstimateDocument();
      data.document.esignature.esignature_file.name = sign.files[0];
      data.document.esignature.esignature_file.display_name = sign.files[0];
      data.document.esignature.internet_browser = this.helperService.detectBrowser();

      that.helperService.showLoadingMxpro360();
      that.documentPPWService
        .putDocumentPPW(that.job.id, that.estimateDocumentId, data)
        .then(() => {
            that.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
            that.signatured = true;
            that.ngOnInit();
        })
        .catch((error) => {
            console.error('error', error);
            that.helperService.showMessageError(error, {jobNumber: that.job.id, data: data, companyId: that.authService.session.company.id});
        })
        .finally(() => {
            that.helperService.hideLoadingMxpro360();
        });
    });
  }
}
