import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EstimateMediaFile } from 'src/app/entities/estimate-media-file';
import { EstimatesMediaFilesService } from 'src/app/services/estimates-media-files.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';
import { File } from '../../entities/file';
declare var jQuery;

@Component({
  selector: 'app-moving-media-files',
  templateUrl: './moving-media-files.component.html',
  styleUrls: ['./moving-media-files.component.scss']
})
export class MovingMediaFilesComponent implements OnInit {

  estimate_id: string;
  mediaFile: Array<EstimateMediaFile>

  constructor(
      private uploadFilesService: UploadFilesService,
      private estimatesMediaFilesService: EstimatesMediaFilesService,
      private currentRoute: ActivatedRoute,

  ) { }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
      // verficamos si se esta editando
      this.currentRoute
          .params.subscribe(params => {
              if (typeof params.id !== 'undefined') {
                  //this.loadAllData(params.id);
                  this.estimate_id = params.id;
                  this.load();
              }
          });
  }

  load() {
      jQuery.fancybox.showLoading();
      this.estimatesMediaFilesService
          .getAll(this.estimate_id)
          .then((response) => {
              this.mediaFile = response;
              // console.info("job: ", this.job);

              // asignamos informacion temporalmente
              // this.customer = this.job.estimate.customer;
              // this.loadStatusHistoryCharge(this.job.id);
              // this.loadNotes();
          })
          .catch((error) => {
              console.error('error', error);
          })
          .finally(() => {
              jQuery.fancybox.hideLoading();
          });
  }

  public openUploadModal(): void {
      const that = this;
      this.uploadFilesService.openModal().then((response: Array<File>) => {
          // guardar datos que se obtienen del modal
          jQuery.fancybox.showLoading();
          this.estimatesMediaFilesService
              .add(this.estimate_id, response)
              .then(() => {
                  that.load();
              })
              .catch((error) => {
              })
              .finally(() => {
                  jQuery.fancybox.hideLoading();
              });
      });
  }


}
