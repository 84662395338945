<header class="header navbar navbar-expand-sm">

    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></a>

    <div class="nav-logo align-self-center">
        <a class="navbar-brand" routerLink="/job-detail/{{customerEstimate.estimate_id}}">
            <img alt="logo" src="{{environment.az_api.cdn}}/{{authService.session.workspace.id}}/media-files/{{logoEstimate}}" class="navbar-logo pr-2 isotipo" alt="logo">
        </a>
    </div>

    <ul class="navbar-item ml-auto topbar-navigation snip1211">
        
        <!--  BEGIN TOPBAR  -->
        <div class="topbar-nav header navbar" role="banner">
            <nav id="topbar">
                <ul class="navbar-nav theme-brand flex-row  text-center">
                    <li class="nav-item theme-logo">
                        <a routerLink="/job-detail/{{customerEstimate.estimate_id}}">
                            
                            <img alt="logo" src="{{environment.az_api.cdn}}/{{authService.session.workspace.id}}/media-files/{{logoEstimate}}" class="navbar-logo pr-2 isotipo" alt="logo">
                        </a>
                    </li>
                </ul>

                <ul class="list-unstyled menu-categories marg-r40" id="topAccordion">

                    <li class="menu single-menu">
                        <a class="row" routerLink="/job-detail/{{customerEstimate.estimate_id}}" routerLinkActive="active">
                            <div class="col-12 media align-items-center item-menu">
                                <i class="fa-solid fa-briefcase d-wb-none"></i>
                                <div class="media-body d-web-none">
                                    <span class="menu-text">Job Details</span>
                                </div>
                                <i class="fa-solid fa-angle-right d-wb-none"></i>
                            </div>
                        </a>
                    </li>

                    <li class="menu single-menu">
                        <a class="row" routerLink="/balances" routerLinkActive="active">
                            <div class="col-12 media align-items-center item-menu">
                                <i class="fa-solid fa-wallet d-wb-none"></i>
                                <div class="media-body d-web-none">
                                    <span class="menu-text">Balances</span>
                                </div>
                                <i class="fa-solid fa-angle-right d-wb-none"></i>
                            </div>
                        </a>
                    </li>

                    <li class="menu single-menu">
                        <a class="row" aria-expanded="false" routerLink="/tickets" routerLinkActive="active">                            
                            
                            <div class="col-12 media align-items-center item-menu">
                                <i class="fa-solid fa-bell-concierge d-wb-none"></i>
                                <div class="media-body d-web-none">
                                    <span class="menu-text">Tickets</span>
                                </div>
                                <i class="fa-solid fa-angle-right d-wb-none"></i>
                            </div>
                        </a>
                    </li>
                                        
                </ul>
            </nav> 
        </div>
        <!--  END TOPBAR  -->

    </ul>

    <ul class="navbar-item flex-row nav-dropdowns">

        <li class="nav-item dropdown user-profile-dropdown order-lg-0 order-1">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="user-profile-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="media">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                </div>
            </a>
            <div class="dropdown-menu position-absolute logout-button" aria-labelledby="userProfileDropdown">
                <div class="dropdown-item">
                    <a href="#" (click)="logout()" class="btn btn-danger m-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> <span>Log Out</span>
                    </a>
                </div>
            </div>

        </li>
    </ul>
</header>