<div class="row">
  <div class="col-sm-12">
    <img
      src="../../../assets/img/headerRed_Vaucher.png"
      alt=""
      class="header-vaucher"
    />

    <div class="color-payment text-center pay-fail marg-top20">
      Payment Failed !
    </div>
    <div class="text-center marg-top20 color-gray">
      There was a problem whith the payment service.
    </div>
    <div class="text-center color-gray">
      Please select a different payment method or try again later.
    </div>

    <div class="row col-sm-12 marg-pad0">
      <div class="marg-top30 back-ticket-vaucher col-sm-12 pad-bot60">
        <div class="marg-top80">
          <div class="text-center amount">$ 2460.00</div>
        </div>

        <div class="col-sm-12 marg-top50">
          <div class="row pay-details">
            <div>Concept:</div>
            <div>Initial Payment</div>
          </div>
          <div class="row pay-details">
            <div>Destination</div>
            <div>New City Moving</div>
          </div>
          <br />
          <div class="row pay-details">
            <div>Payment Type:</div>
            <div>VISA</div>
          </div>
          <div class="row pay-details">
            <div>Bank:</div>
            <div>HDFC</div>
          </div>
          <div class="row pay-details">
            <div>Bank:</div>
            <div>HDFC</div>
          </div>
          <div class="row pay-details">
            <div>Product:</div>
            <div>xxxx-xxxx-8957</div>
          </div>
          <div class="row pay-details">
            <div>Order Ref:</div>
            <div>8965124</div>
          </div>
          <br />
          <div class="row pay-details">
            <div>Date:</div>
            <div>28 Oct 2021 12:35 pm</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
