import { PaymentMethod } from './payment-method';

export class PaymentMethodView {

    public id: string;
    public payment_methods: Array<PaymentMethod>;
    public methods: Array<{method: string, logo: string}>;

    constructor() {
        this.id = null;
        this.payment_methods = [];
        this.methods = [];
    }
}
