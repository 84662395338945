import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InventoryItem } from '../entities/inventory-item';

@Injectable({
  providedIn: 'root'
})
export class InventoryItemsService {

  constructor(private http: HttpClient) { }

  getAll(): Promise<Array<InventoryItem>> {
    return this.http.get<Array<InventoryItem>>(environment.api.customers + '/inventory-items').toPromise();
  }
}
