import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Account } from '../entities/account';
import { Credential } from '../entities/credential';
import { CustomerCredential } from '../entities/customer-credential';
import { CustomerEstimate } from '../entities/customer-estimate';
import { Session } from '../entities/session';
import { EstimateEmail } from '../entities/estimate-email';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public session: Session;

    constructor(private http: HttpClient) {
        this.session = new Session();
        this.load();
    }

    /**
     * permite cargar la informacion de la session guardada localmente
     */
    load() {
        const session = localStorage.getItem('session');
        if (session !== null) {
            this.session = JSON.parse(session);
        }
    }

    /**
     * permite guardar la session localmente
     */
    save() {
        if (this.session !== null) {
            localStorage.setItem('session', JSON.stringify(this.session));
        }
    }

    /**
     * permite iniciar session a los usuarios
     * @param dataForm
     */
    login(dataForm: Credential) {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/login', dataForm)
                .subscribe((response) => {
                    resolve(this.createSession(response));
                }, (error) => {
                    console.info("error login: ", error);
                    reject(error.error);
                });
        });
    }

    createSession(response) {
        this.session = new Session();
        this.session.token = response['token'];
        this.session.customer = response['customer'];
        this.session.company = response['company'] ? response['company'] : response['account_companies'][0]['company'];
        this.session.role = response['role'] ? response['role'] : response['account_companies'][0]['role'];
        // guardamos la session
        this.save();
    }

    /**
     * permite realizar registos a usuarios nuevos
     * @param dataForm
     */
    signin(dataForm: Account) {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/sign-in', dataForm)
                .subscribe((response) => {

                    this.session = new Session();
                    this.session.token = response['token'];
                    this.session.account = response['account'];

                    // guardamos la session
                    this.save();
                    resolve(true);
                }, (error) => {
                    console.info("error sign-in: ", error);
                    reject(error.error);
                });
        });
    }

    /**
     * permite cerrar la session en el servidor
     */
    logout() {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/logout', {})
                .subscribe((response) => {
                    this.closeSession();
                    resolve(true);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Permite cerrar la session localmente en el frontend
     */
    closeSession() {
        this.session = new Session();
        localStorage.removeItem('session');
        localStorage.removeItem('auth');
    }

    /**
     * permite iniciar en el sistema al cliente con el codigo enviado al celular
     *
     * @param dataForm
     */
    loginCustomer(customerEstimateId: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.api.base + '/login-customers/' + customerEstimateId, {})
                .subscribe((response: any) => {
                    this.session = new Session();
                    this.session.token = response.token;
                    this.session.customer = response.customer;
                    this.session.company = response.company;
                    this.session.workspace = response.workspace;

                    const customerEstimate = new CustomerEstimate();
                    customerEstimate.id = response.customer_estimate.id;
                    customerEstimate.workspace_id = response.customer_estimate.workspace_id;
                    customerEstimate.customer_id = response.customer_estimate.customer_id;
                    customerEstimate.estimate_id = response.customer_estimate.estimate_id;
                    customerEstimate.estimate_document_id = response.customer_estimate.estimate_document_id;
                    localStorage.setItem('customer_estimate', JSON.stringify(customerEstimate));

                    // guardamos la sesion
                    this.save();
                    resolve(response.customer_estimate);
                }, (error) => {
                    console.info("error login: ", error);
                    reject(error.error);
                });
        });
    }

    /**
     * permite enviar un codigo al celular del cliente,
     * esto es para poder verificar que el cliente si
     * es el usuario que esta intentando ingresar al sistema
     *
     * @param dataForm
     */
    public tokenCustomer(dataForm) {
        return this.http.post(environment.api.base + '/generate-token-customer', dataForm).toPromise();
    }

    public getPublicIp() {
        return this.http.get('https://api.ipify.org?format=json&callback=?').toPromise();
    }

    public verifyToken(dataForm) {
        return this.http.post(environment.api.base + '/login/customers/verify-token', dataForm).toPromise();
    }

    getEstimatesEmail(estimateDocumentId: string, token: string) {
        return this.http.get<EstimateEmail>(environment.api.customers + '/estimates/' + estimateDocumentId + '/emails/' + token).toPromise();
    }

}
