import { BalanceView } from 'src/app/entities/balance-view';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReportPaymentView } from '../entities/report-payment-view';


@Injectable({
  providedIn: 'root'
})
export class BalancesCustomerService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Metodo que obtiene todos los balances en la web de customers
   *
   * @param Id de la company
   */
  getAllCustomer(data) {
    return this.http.get<Array<BalanceView>>(environment.api.customers + '/balances', { params: data }).toPromise();
  }

  /**
   * Metodo que obtiene el balance de un customer por id
   */
  getByIdCustomer(idBalance: string) {
    return this.http.get<BalanceView>(environment.api.customers + '/balances/' + idBalance).toPromise();
  }

  /**
   * Metodo que obtiene el pago por el id del balance
   */
  getPaymentsByBalanceCustomer(idBalance: string) {
    return this.http.get<Array<ReportPaymentView>>(environment.api.customers + '/payments-by-balance/' + idBalance).toPromise();
  }
}
