import { Rol } from 'src/app/entities/rol';

export class Employee {

    id: string;
    name: string;
    nickname: string;
    phone: string;
    email: string;
    photo: string;
    role: Rol;
    is_owner: boolean;
    allow_access: boolean;
    account_company_id: string;
    created: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.nickname = null;
        this.phone = null;
        this.email = null;
        this.photo = null;
        this.role = new Rol();
        this.is_owner = null;
        this.allow_access = false;
        this.account_company_id = null;
        this.created = null;
    }

}
