import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { EstimateDocument } from '../entities/estimate-document';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentPPWService {

  constructor(
    private http: HttpClient
  ) { }

  getDocumentPPW(documentId: string, documentTypeId: string) {
    return this.http.get<EstimateDocument>(environment.api.customers + '/estimates/' + documentId +
      '/document-ppw/' + documentTypeId).toPromise();
  }

  putDocumentPPW(estimateId:string, documentId: string, data) {
    return this.http.put<EstimateDocument>(environment.api.customers + '/estimates/' + estimateId +
      '/document-ppw/' + documentId, data).toPromise();
  }
}
