export class MollieSetting {
    id: string;
    api_key: string;
    partner_id: string;

    constructor() {
        this.id = null;
        this.api_key = null;
        this.partner_id = null;
    }
}
