import { EstimateDocument } from 'src/app/entities/estimate-document';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocumentPoaService {

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getDocumentPOA(idJob: string) {
    return this.http.get<EstimateDocument>(environment.api.customers + '/estimates/' + idJob +
      '/document-poa/' + this.authService.session.company.id).toPromise();
  }

  putDocumentPOA(idJob: string, data) {
    return this.http.put<EstimateDocument>(environment.api.customers + '/estimates/' + idJob +
      '/document-poa/' + this.authService.session.company.id, data).toPromise();
  }
}
