export class SquareSetting {

    public app_id: string;
    public location_id: string;
    public access_token: string;

    constructor() {
        this.app_id = null; // sandbox-sq0idb-qY_yT3wdCP95V5DTMwT13Q
        this.location_id = null; // LZCWB6ZYM90TB
        this.access_token = null; // EAAAEPyqxp1o3oKnQ2KKWBVm1eENe9irtqxNwkTSJO_XcOOGusljo6LEroRIc5-7
    }
}
