import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EstimateInventoryContainer } from '../entities/estimate-inventory-container';

@Injectable({
  providedIn: 'root'
})
export class QuotesItemsContainersService {

  constructor(private http: HttpClient) {

  }

  get(estimate_id: string): Promise<Array<EstimateInventoryContainer>> {
      return this.http.get<Array<EstimateInventoryContainer>>(environment.api.customers +
          '/estimates/' + estimate_id + '/inventory-containers').toPromise();
  }

  set(dataForm: Array<EstimateInventoryContainer>, estimate_id: string) {
      return this.http.post(environment.api.customers + '/estimates/' + estimate_id + '/inventory-containers', dataForm).toPromise();
  }
}
