
import { Address } from './address';

export class EstimateDelivery {
    public id: string;
    public deliver_immediately: boolean;
    public range_start: number;
    public range_end: number;
    public address: Address;

    /**
     * Constructor de la clase
     */
    constructor() {
        this.id = null;
        this.deliver_immediately = false;
        this.range_start = null;
        this.range_end = null;
        this.address = new Address();
    }
}
