<div class="modal fade" id="FileUploadModal" #FileUploadModal data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="FileUploadModalLabel" aria-hidden="true" >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="FileUploadModalLabel">
                    Upload Files
                </h5>
            <!--
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg _ngcontent-spa-c7="" class="feather feather-x" fill="none" height="24" stroke="currentColor"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <line _ngcontent-spa-c7="" x1="18" x2="6" y1="6" y2="18"></line>
                        <line _ngcontent-spa-c7="" x1="6" x2="18" y1="6" y2="18"></line>
                    </svg>
                </button>
            -->
            </div>
            <div class="modal-body dropzone-body">

                <div class="dropzone" #dropZoneJs>

                </div>

                <!-- wrapper files to upload -->
                <div class="table table-striped files previews" #previewFile>

                    <!-- template for each file -->
                    <div class="d-flex template" #templateFile>
                        <!-- This is used as the file preview template -->
                        <div class="col-1 col-md-2 img-uploaded-file">
                            <span class="preview">
                                <img data-dz-thumbnail />
                            </span>
                        </div>
                        <div class="col-5 col-md-3 center-block name-uploaded-file">
                            <p class="name" data-dz-name></p>
                            <strong class="error text-danger" data-dz-errormessage></strong>                            
                        </div>
                        <div class="col-10 col-md-5 progress-uploaded-file">
                            <p class="size" data-dz-size></p>
                            <div class="progress progress-striped active" role="progressbar" aria-valuemin="0"
                                aria-valuemax="100" aria-valuenow="0">
                                <div class="progress-bar progress-bar-success" style="width:0%;" data-dz-uploadprogress>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 col-md-2 center-block remove-uploaded-file">
                            <!--
                                <button class="btn btn-primary start">
                                    <i class="glyphicon glyphicon-upload"></i>
                                    <span>Start</span>
                                </button>
                            -->
                            <!-- <button data-dz-remove class="btn btn-danger cancel">
                                <i class="glyphicon glyphicon-ban-circle"></i>
                                <span>Cancel</span>
                            </button> -->
                            
                            <button data-dz-remove class="btn btn-danger btn-circle cancel">
                                <i class="fa fa-times"></i>
                            </button>
                            <!--
                                <button data-dz-remove class="btn btn-danger delete">
                                    <i class="glyphicon glyphicon-trash"></i>
                                    <span>Delete</span>
                                </button>
                            -->
                        </div>
                    </div>

                </div>



            </div>
            <div class="modal-footer">
                <button class="btn" (click)="close()" [disabled]="isUploading">
                    <i class="flaticon-cancel-12"></i>
                    Cancel
                </button>
                <button type="button" class="btn btn-warning" (click)="save()" [disabled]="isUploading">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
