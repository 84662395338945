export class EstimateCustomerRemark {
    id: string;
    remarks: string;
    estimate_id: string;
    employee_id: string;
    customer_id: string;
    created: number;

    constructor() {
        this.id = null;
        this.remarks = null;
        this.estimate_id = null;
        this.employee_id = null;
        this.customer_id = null;
        this.created = null;
    }
}
