export class AuthorizeSetting {
    id: string;
    api_login_id: string;
    key: string;
    transaction_key: string;

    constructor() {
        this.id = null;
        this.api_login_id = null;
        this.key = null;
        this.transaction_key = null;
    }
}
