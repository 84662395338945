export class Geospatial {
    public id: string;
    public type: string;
    public coordinates: any;

    constructor() {
        this.id = null;
        this.type = null;
        this.coordinates = [];
    }
}
