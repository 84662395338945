<!-- CONTENT BREADCRUMB -->
<div class="breadcrumb-four mt-1">
    <ul class="breadcrumb">        
        <li>
            <a routerLink="/job-detail/{{customerEstimate.estimate_id}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
            </a>
        </li>
        <li class="text-breadcrumb"><span>Special Power Attorney</span></li>
    </ul>
</div>
<!-- CONTENT BREADCRUMB -->

<div id="content" class="main-content">
    <div class="layout-px-spacing">

        <div class="row invoice layout-top-spacing layout-spacing">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 padd-0">

                <div class="doc-container">

                    <div class="row">
                        <div class="col-xl-9">
                            <!-- web -->
                            <div class="invoice-content poa-pdf">
                                <div class="invoice-detail-body">
                                    <iframe class="signature-mobile" id="estimatePdf" style="width:100%; height:650px;"
                                        title="Autorization">
                                    </iframe>
                                </div>
                            </div>
                            <!-- mobile -->
                            <div class="container-download-poa invoice-actions-btn mb-4">                                
                                <div class="download-poa">                                
                                    <i class="fas fa-file-pdf"></i> 
                                    <span class="text-center pt-1">POA Document</span>
                                    <button class="btn btn-success mt-2" (click)="downloadPdf()">View Here</button>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3">

                            <div class="main-container-btn-opt">

                                <div class="invoice-actions-btn container-btn-opt download-btn-bg">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div class="col-12 job-code title-btn-opt px-4">
                                                <span>Interstate Bill Of Lading Contract</span> 
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons download-btn-opt">                                            
                                                <a href="javascript:void(0);" (click)="downloadPdf()" class="btn btn-secondary btn-download">
                                                    <i class="fa-solid fa-cloud-arrow-down d-wb-none"></i>
                                                    Download Document
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="container-hr">
                                    <hr class="hr-text" data-content="Signatures">
                                </div>

                                <div class="invoice-actions-btn container-btn-opt signature-btn-bg">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div [ngClass]="{'signed': flagSignPriorPickup == 'SIGNED', 'pending-signature': flagSignPriorPickup == 'NOT_SIGNED', 'signature-not-available': flagSignPriorPickup == 'SIGNATURE_NOT_AVAILABLE'}" 
                                                class="col-12 job-code title-btn-opt px-4">
                                                <span>Sign Document (Prior to Pickup)</span> 
                                                <i class="fa-solid fa-1"></i>
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons signature-btn-opt">
                                                <!-- el documento ya esta firmado -->
                                                <a href="javascript:void(0);" class="btn btn-success" disabled *ngIf="flagSignPriorPickup == 'SIGNED'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span>Signed Document</span>
                                                </a>
                                                <!-- no esta habilitado desde el email -->
                                                <a href="javascript:void(0);" class="btn btn-disabled" disabled *ngIf="flagSignPriorPickup == 'SIGNATURE_NOT_AVAILABLE'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span>Signature Not Available</span>
                                                </a>
                                                <!-- No esta firmado -->
                                                <a href="javascript:void(0);" (click)="openSignatureBol('PRE_PICKUP')" class="btn btn-warning" *ngIf="flagSignPriorPickup == 'NOT_SIGNED'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span>Pending Signature</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="invoice-actions-btn container-btn-opt signature-btn-bg">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div [ngClass]="{'signed': flagSignDuringPickup == 'SIGNED', 'pending-signature': flagSignDuringPickup == 'NOT_SIGNED', 'signature-not-available': flagSignDuringPickup == 'SIGNATURE_NOT_AVAILABLE'}" 
                                                class="col-12 job-code title-btn-opt px-4">
                                                <span>Sign Document (During Pickup)</span> 
                                                <i class="fa-solid fa-2"></i>
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons signature-btn-opt">
                                                <a href="javascript:void(0);" class="btn btn-success" disabled *ngIf="flagSignDuringPickup == 'SIGNED'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span>Signed Document</span>
                                                </a>
                                                <a href="javascript:void(0);" class="btn btn-disabled" disabled *ngIf="flagSignDuringPickup == 'SIGNATURE_NOT_AVAILABLE'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span>Signature Not Available</span>
                                                </a>
                                                <a href="javascript:void(0);" (click)="openSignatureBol('ONSITE')" class="btn btn-warning" *ngIf="flagSignDuringPickup == 'NOT_SIGNED'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span>Pending Signature</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="invoice-actions-btn container-btn-opt signature-btn-bg">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div [ngClass]="{'signed': flagSignDelivery == 'SIGNED', 'pending-signature': flagSignDelivery == 'NOT_SIGNED', 'signature-not-available': flagSignDelivery == 'SIGNATURE_NOT_AVAILABLE'}" 
                                                class="col-12 job-code title-btn-opt px-4">
                                                <span>Sign Document (For Delivery)</span> 
                                                <i class="fa-solid fa-3"></i>
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons signature-btn-opt">
                                                <a href="javascript:void(0);" class="btn btn-success" disabled *ngIf="flagSignDelivery == 'SIGNED'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span>Signed Document</span>
                                                </a>
                                                <a href="javascript:void(0);" class="btn btn-disabled" disabled *ngIf="flagSignDelivery == 'SIGNATURE_NOT_AVAILABLE'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span>Signature Not Available</span>
                                                </a>
                                                <a href="javascript:void(0);" (click)="openSignatureBol('DELIVERY')" class="btn btn-warning" *ngIf="flagSignDelivery == 'NOT_SIGNED'">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span>Pending Signature</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                                    
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<!-- Modal Add Signature - Prior to Pickup -->
<div class="modal fade" id="signaturePriorPickupModal" #signaturePriorPickupModal tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered"  style="max-width: 1150px; margin: 1.75rem auto;" role="document">
        <div class="modal-content">
            <div class="modal-body">

                <div class="mb-4 title-header">
                    <h5 class="modal-title mb-2 ml-3">
                        <strong>Add Signature - Document (During Pickup)</strong>
                    </h5>
                </div>

                <div class="widget-content widget-content-area icon-tab size-container-signature" style="margin: 15px;">

                    <div class="row">
                        <!-- <div class="col-md-6">

                            <div class="form-group">
                                <label for="company_dot">Customer Fullname</label>
                                <input type="text" class="form-control mb-4" name="company_dot"
                                    placeholder="Enter here the name" [(ngModel)]="customer_fullname" style="width: 385px;" disabled>
                            </div>

                            <div class="form-group">
                                <label for="company_dot">Designate Fullname</label>
                                <input type="text" class="form-control mb-4" name="company_name"
                                    placeholder="Enter here the name" [(ngModel)]="designate_fullname" style="width: 385px;">
                            </div>

                            <div class="form-group">
                                <label for="company_dot">Phone</label>
                                <input type="text" class="form-control mb-4" name="company_phone"
                                    placeholder="Enter here the Phone" [(ngModel)]="designate_phonenumber" style="width: 385px;">
                            </div>
                        </div> -->

                        <div class="col-md-12">
                            <app-sign (onSignature)="onSignature($event)"></app-sign>
                        </div>
                    </div>

                    <div class="row">
                        
                    </div>
                </div>
                
            </div>
            <div class="modal-footer mr-4">
                <button (click)="closeSignature()" class="btn" data-dismiss="modal">
                    Discard
                </button>
                <button [disabled]="!signature" (click)="save()" data-dismiss="modal" class="btn btn-warning">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>