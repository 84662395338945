import { Pipe, PipeTransform } from '@angular/core';

declare const moment;

@Pipe({
    name: 'calendarDateTime'
})
export class CalendarDateTimePipe implements PipeTransform {

    transform(milliseconds: number): string {
        if (milliseconds == null) {
            return null;
        }
        return moment(milliseconds).calendar();
    }

}
