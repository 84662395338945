import { Directive, Input, ElementRef, SimpleChanges } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

declare var jQuery;

@Directive({
    selector: '[appSelect2]'
})
export class Select2Directive {

    @Input()
    appSelect2: string;

    @Input('place')
    placeholder: string;

    @Input('theme')
    theme: string;

    constructor(
        private element: ElementRef,
        private ngControl: NgControl
    ) {
        this.theme = "mxp360";// mxp360, mxp360-form
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.loadPlugin();
        },2000);
    }

    ngOnChanges(changes: SimpleChanges) {
        
    }

    private loadPlugin() {

        const that = this;

        jQuery(this.element.nativeElement).select2({
            placeholder: this.placeholder,
            theme: this.theme,
        });

        jQuery(that.element.nativeElement).on('select2:select', function (e) {
            const ctrl = that.ngControl.control;
            ctrl.setValue(that.element.nativeElement.value);
        });

        jQuery(this.element.nativeElement).hide();
    }

}
