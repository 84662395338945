<!-- CONTENT BREADCRUMB -->
<div class="breadcrumb-four mt-1">
    <ul class="breadcrumb">        
        <li>
            <a routerLink="/job-detail/{{customerEstimate.estimate_id}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
            </a>
        </li>
        <li class="text-breadcrumb"><span>PPW</span></li>
    </ul>
</div>
<!-- CONTENT BREADCRUMB -->

<div id="content" class="main-content">
    <div class="layout-px-spacing">

        <div class="row invoice layout-top-spacing layout-spacing">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 padd-0">

                <div class="doc-container">

                    <div class="row">
                        <div class="col-xl-9">
                            <!-- web -->
                            <div class="invoice-content poa-pdf">
                                <div class="invoice-detail-body">
                                    <iframe class="signature-mobile" id="estimatePdf" style="width:100%; height:650px;"
                                        title="Autorization">
                                    </iframe>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3">

                            <div class="main-container-btn-opt">

                                <div class="container-hr">
                                    <hr class="hr-text" data-content="Signatures">
                                </div>

                                <div class="invoice-actions-btn container-btn-opt signature-btn-bg">
                                    <div class="invoice-action-btn">
                                        <div class="row">
                                            <div [ngClass]="{'signed': document.esignatures.main !== null, 'pending-signature': document.esignatures.main == null}" 
                                                class="col-12 job-code title-btn-opt px-4">
                                                <span>Sign Document</span> 
                                            </div>
                                            <div class="col-xl-12 col-md-4 margin-buttons signature-btn-opt">
                                                <!-- el documento ya esta firmado -->
                                                <a href="javascript:void(0);" class="btn btn-success" disabled *ngIf="document.esignatures.main !== null">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span>Signed Document</span>
                                                </a>
                                                <!-- No esta firmado -->
                                                <a href="javascript:void(0);" (click)="openSignature()" class="btn btn-warning" *ngIf="document.esignatures.main == null">
                                                    <i class="fa-solid fa-signature d-wb-none"></i>
                                                    <span>Pending Signature</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<!-- Modal Add Signature - Prior to Pickup -->
<div class="modal fade" id="signModal" #signModal tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered"  style="max-width: 1150px; margin: 1.75rem auto;" role="document">
        <div class="modal-content">
            <div class="modal-body">

                <div class="mb-4 title-header">
                    <h5 class="modal-title mb-2 ml-3">
                        <strong>Add Signature</strong>
                    </h5>
                </div>

                <div class="widget-content widget-content-area icon-tab size-container-signature" style="margin: 15px;">

                    <div class="row">

                        <div class="col-md-12">
                            <app-sign (onSignature)="onSignature($event)"></app-sign>
                        </div>
                    </div>

                    <div class="row">
                        
                    </div>
                </div>
                
            </div>
            <div class="modal-footer mr-4">
                <button (click)="closeSignature()" class="btn" data-dismiss="modal">
                    Discard
                </button>
                <button [disabled]="!signature" (click)="save()" data-dismiss="modal" class="btn btn-warning">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>