import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ConstantsMessages } from '../constants-messages';
import { CustomerEstimate } from '../entities/customer-estimate';
import { PaymentMethod } from '../entities/payment-method';
import { AuthService } from './auth.service';
import { Session } from '../entities/session';

declare var jQuery;
declare var Snackbar;
declare var swal;
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  session: Session;
  /*
  Constantes que contiene el tipo de mensaje a mostrar
  */
  constantsMessages = ConstantsMessages;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.loadDataSession();
  }




  private loadDataSession() {

    // obtenemos informacion del storage
    const session = localStorage.getItem('session');

    // si devuelve algo lo procesamos
    if (session !== null) {
        this.session = JSON.parse(session);
    }
  }

  /**
     * Muestra el loading de Moverxpro360
     */   
   showLoadingMxpro360(): void {
    let mxpro360Loading = document.getElementById('mxpro360-loading');
    mxpro360Loading.style.display = 'flex';
  };

  /**
  * Oculta el loading de Moverxpro360
  */
  hideLoadingMxpro360(): void {
      let mxpro360Loading = document.getElementById('mxpro360-loading');
      mxpro360Loading.style.display = 'none';
  }




  /**
   * Muestra un Snackbar indicando la accion realizada
   */
  showMessageSnackbar(message: string): void {
    // Se elije el color del Snackbar
    let backgroundColor: string;
    switch (message) {
      case this.constantsMessages.SIGNED:
      case this.constantsMessages.SAVED:
      case this.constantsMessages.DELETED:
      case this.constantsMessages.CHANGE_COMPANY_SUCCESS:
      case this.constantsMessages.COPIED:
      case this.constantsMessages.LOAD_DOCUMENT:
      case this.constantsMessages.SEND_TOKEN:
      case this.constantsMessages.SESSION_STARTED:
      case this.constantsMessages.SESSION_CLOSED:
        backgroundColor = '#8dbf42';
        break;
      default:
        backgroundColor = '#e7515a';
        break;
    }
    Snackbar.show({
      text: message,
      pos: 'top-center',
      actionTextColor: '#fff',
      backgroundColor,
      duration: 1500,
      showAction: false
    });
  }

  

  /**
   * Muestra un Snackbar indicando la accion realizada
   */
  showMessageError(error, data): void {

    // Se elije el color del Snackbar
    swal({
        title: 'Error', 
        /* html:  '<table class="table table-sm "> <tbody><tr><th>Url: '+ error.url +'</th></tr><tr><th>Request: ' + JSON.stringify(data) + '</th></tr><tr><th>Response: ' + JSON.stringify(error) + '</th></tr></tbody></table>', */
        html:  `<span class="subtitle">Something went wrong!</span>
                <br>     
                <div id="accordion">                 
                    <div id="headingOne">
                      <h5 class="mb-0">
                        <div class="server-error">
                          <span>Server Error:</span>
                          ${error.status} ${error.statusText}
                        </div>               
                        <button id="errorDetails" class="btn btn-outline-danger" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Show Details
                        </button>
                      </h5>
                    </div>
                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                      <div class="card-body">                      
                        <table class="table table-sm ">
                          <tbody>
                            <tr>
                              <th>
                                <span>URL:</span>
                                ${error.url}
                              </th>
                            </tr>
                            <tr>
                              <th>
                                <span>Request:</span>                                
                                ${JSON.stringify(data)}
                              </th>
                            </tr>
                            <tr>
                              <th>
                                <span>Response:</span>
                                ${JSON.stringify(error)}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>              
                </div>`,
        type: 'error',
        customClass: "swal-message-error"
    });

    jQuery('#errorDetails').click(function(){

      jQuery(this).toggleClass('active');

      jQuery(this).text(function(i, text){
        return text === "Hide Details" ? "Show Details" : "Hide Details";
      })

   });

  }

  



  /**
   * Elimina el loading de jquery cuando ocurre un error
   */
  hideLoading(): void {
    setTimeout(() => {
      // this.helperService.hideLoadingMxpro360();
    }, 1000);
  }


  /**
   * Metodo que realiza la descarga de los reportes
   *
   * @param routeDownload Ruta para descargar el archivo que contiene el reporte. El archivo puede ser pdf o xml
   * @param id Id del estimate o del job
   * @param data Datos que puede contener el reporte
   */
  downloadReport(routeDownload: string, id?: string, data?: any): void {
    try {
      switch (routeDownload) {

        case 'AUTO_TRANSPORT_CUSTOMERS': {
          window.open(environment.api.base + '/customers/estimates/' + id +
            '/export-pdf-autotransport?company_id=' + this.authService.session.company.id + '&Authorization=' +
            this.authService.session.token, '_blank');
          break;
        }

        case 'LONG_ESTIMATES_CUSTOMER': {
          window.open(environment.api.base + '/customers/estimates/' + id +
            '/export-pdf-quote?company_id=' + this.authService.session.company.id + '&Authorization=' +
            this.authService.session.token, '_blank');
          break;
        }

        case 'LOCAL_ESTIMATES_CUSTOMER': {
          window.open(environment.api.base + '/customers/estimates/' + id +
            '/export-pdf-estimate-local?company_id=' + this.authService.session.company.id + '&Authorization=' +
            this.authService.session.token, '_blank');
          break;
        }

        case 'CONTRACT_INFORMATION': {
          window.open(environment.az_api.cdn + '/' + this.authService.session.workspace.id + '/documents/' + data.file.display_name);
          break;
        }

      }
    } catch (error) {
      this.showMessageSnackbar(this.constantsMessages.ERROR_DOWNLOAD);
    }
  }

  loadCustomerServiceLocalStorage() {
    let customerEstimateEntity: CustomerEstimate = new CustomerEstimate();
    const customerEstimate = localStorage.getItem('customer_estimate');
    if (customerEstimate !== null) {
      customerEstimateEntity = JSON.parse(customerEstimate);
    }

    return customerEstimateEntity;
  }

  /**
   * Metodo que detecta el navegador en el que se esta trabajando
   */
  public detectBrowser(): string {
    // Verifica si el navegador es internet explorer
    if (navigator.userAgent.search('MSIE') >= 0) {
      return 'MSIE';
    } else if (navigator.userAgent.search('Chrome') >= 0) {
      // Verifica si el navegador es internet Chrome
      return 'Chrome';
    } else if (navigator.userAgent.search('Firefox') >= 0) {
      // Verifica si el navegador es Firefox
      return 'Firefox';
    } else if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
      // Verifica si el navegador es Safari
      return 'Safari';
    } else if (navigator.userAgent.search('Opera') >= 0) {
      // Verifica si el navegador es Opera
      return 'Opera';
    }
  }

  public redirectToPayment(typePayment: PaymentMethod, idBalance: string, paymentAmount: number = null) {
    let module = '';
    switch (typePayment.method) {
      case 'CASH':
      case 'MONEY_ORDER':
      case 'CHECK':
      case 'BANK_TRANSFER_OFFLINE':
        module = 'transfer-offline';
        break;
      case 'CREDIT_CARD':
        module = 'credit-card';
        break;
      case 'BANK_TRANSFER_ONLINE':
        module = 'transfer-online';
        break;
    }
    let route = `/payment/${idBalance}/${module}/${typePayment.id}`;
    if (paymentAmount !== null) {
      route += '/' + paymentAmount;
    }
    this.router.navigateByUrl(route);

  }

  getMimeType(fileName: string) {
    // obtiene la extension del archivo
    const ext = fileName.split('.').pop();
    // obtiene mimetype
    let mime = '';
    switch (ext) {
      case 'html':
      case 'htm':
      case 'shtml': {
        mime = 'text/html';
        break;
      }
      case 'css': {
        mime = 'text/css';
        break;
      }
      case 'xml': {
        mime = 'text/xml';
        break;
      }
      case 'gif': {
        mime = 'image/gif';
        break;
      }
      case 'jpeg':
      case 'jpg': {
        mime = 'image/jpeg';
        break;
      }
      case 'js': {
        mime = 'application/x-javascript';
        break;
      }
      case 'atom': {
        mime = 'application/atom+xml';
        break;
      }
      case 'rss': {
        mime = 'application/rss+xml';
        break;
      }
      case 'mml': {
        mime = 'text/mathml';
        break;
      }
      case 'txt': {
        mime = 'text/plain';
        break;
      }
      case 'jad': {
        mime = 'text/vnd.sun.j2me.app-descriptor';
        break;
      }
      case 'wml': {
        mime = 'text/vnd.wap.wml';
        break;
      }
      case 'htc': {
        mime = 'text/x-component';
        break;
      }
      case 'png': {
        mime = 'image/png';
        break;
      }
      case 'tif':
      case 'tiff': {
        mime = 'image/tiff';
        break;
      }
      case 'wbmp': {
        mime = 'image/vnd.wap.wbmp';
        break;
      }
      case 'ico': {
        mime = 'image/x-icon';
        break;
      }
      case 'jng': {
        mime = 'image/x-jng';
        break;
      }
      case 'bmp': {
        mime = 'image/x-ms-bmp';
        break;
      }
      case 'svg': {
        mime = 'image/svg+xml';
        break;
      }
      case 'webp': {
        mime = 'image/webp';
        break;
      }
      case 'jar':
      case 'war':
      case 'ear': {
        mime = 'application/java-archive';
        break;
      }
      case 'hqx': {
        mime = 'application/mac-binhex40';
        break;
      }
      case 'doc': {
        mime = 'application/msword';
        break;
      }
      case 'pdf': {
        mime = 'application/pdf';
        break;
      }
      case 'ps':
      case 'eps':
      case 'ai': {
        mime = 'application/postscript';
        break;
      }
      case 'rtf': {
        mime = 'application/rtf';
        break;
      }
      case 'xls': {
        mime = 'application/vnd.ms-excel';
        break;
      }
      case 'ppt': {
        mime = 'application/vnd.ms-powerpoint';
        break;
      }
      case 'wmlc': {
        mime = 'application/vnd.wap.wmlc';
        break;
      }
      case 'kml': {
        mime = 'application/vnd.google-earth.kml+xml';
        break;
      }
      case 'kmz': {
        mime = 'application/vnd.google-earth.kmz';
        break;
      }
      case '7z': {
        mime = 'application/x-7z-compressed';
        break;
      }
      case 'cco': {
        mime = 'application/x-cocoa';
        break;
      }
      case 'jardiff': {
        mime = 'application/x-java-archive-diff';
        break;
      }
      case 'jnlp': {
        mime = 'application/x-java-jnlp-file';
        break;
      }
      case 'run': {
        mime = 'application/x-makeself';
        break;
      }
      case 'pl':
      case 'pm': {
        mime = 'application/x-perl';
        break;
      }
      case 'prc':
      case 'pdb': {
        mime = 'application/x-pilot';
        break;
      }
      case 'rar': {
        mime = 'application/x-rar-compressed';
        break;
      }
      case 'rpm': {
        mime = 'application/x-redhat-package-manager';
        break;
      }
      case 'sea': {
        mime = 'application/x-sea';
        break;
      }
      case 'swf': {
        mime = 'application/x-shockwave-flash';
        break;
      }
      case 'sit': {
        mime = 'application/x-stuffit';
        break;
      }
      case 'tcl':
      case 'tk': {
        mime = 'application/x-tcl';
        break;
      }
      case 'der':
      case 'pem':
      case 'crt': {
        mime = 'application/x-x509-ca-cert';
        break;
      }
      case 'xpi': {
        mime = 'application/x-xpinstall';
        break;
      }
      case 'xhtml': {
        mime = 'application/xhtml+xml';
        break;
      }
      case 'zip': {
        mime = 'application/zip';
        break;
      }
      case 'bin':
      case 'exe':
      case 'dll': {
        mime = 'application/octet-stream';
        break;
      }
      case 'deb': {
        mime = 'application/octet-stream';
        break;
      }
      case 'dmg': {
        mime = 'application/octet-stream';
        break;
      }
      case 'eot': {
        mime = 'application/octet-stream';
        break;
      }
      case 'iso':
      case 'img': {
        mime = 'application/octet-stream';
        break;
      }
      case 'msi':
      case 'msp':
      case 'msm': {
        mime = 'application/octet-stream';
        break;
      }
      case 'mid':
      case 'midi':
      case 'kar': {
        mime = 'audio/midi';
        break;
      }
      case 'mp3': {
        mime = 'audio/mpeg';
        break;
      }
      case 'ogg': {
        mime = 'audio/ogg';
        break;
      }
      case 'ra': {
        mime = 'audio/x-realaudio';
        break;
      }
      case '3gpp':
      case '3gp': {
        mime = 'video/3gpp';
        break;
      }
      case 'mpeg':
      case 'mpg': {
        mime = 'video/mpeg';
        break;
      }
      case 'mov': {
        mime = 'video/quicktime';
        break;
      }
      case 'flv': {
        mime = 'video/x-flv';
        break;
      }
      case 'mng': {
        mime = 'video/x-mng';
        break;
      }
      case 'asx':
      case 'asf': {
        mime = 'video/x-ms-asf';
        break;
      }
      case 'wmv': {
        mime = 'video/x-ms-wmv';
        break;
      }
      case 'avi': {
        mime = 'video/x-msvideo';
        break;
      }
      case 'm4v':
      case 'mp4': {
        mime = 'video/mp4';
        break;
      }
      default: {
        mime = ext;
      }
    }
    return mime;
  }

}
