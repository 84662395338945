import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingsService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  get(): Promise<any> {
    return this.http.get<any>(environment.api.customers + '/general-settings/' + this.authService.session.company.id).toPromise();
  }

}
