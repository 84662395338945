<div id="content" class="main-content d-flex justify-content-center">
    <div class="container justify-content-center">
        <div class="container">
            <div class="row layout-top-spacing" id="cancel-row">


                <div class="col-lg-12 layout-spacing">
                    <div class="statbox widget box box-shadow">
                        <div class="widget-header">
                            <div class="row">
                                <div class="col-xl-12 col-md-12 col-sm-12 col-12 title-payment-method">
                                    <h4>Payment Credit Card</h4>
                                </div>
                            </div>
                        </div>

                        <div id="payment-status-container"></div>

                        <div class="widget-content widget-content-area">
                            <!-- steps -->
                            <ul>
                                <li class="{{tabCard}}">
                                    <!-- Circulo e icono -->
                                    <span class="marker-number"><i class="far fa-credit-card fa-2x icon"></i></span>
                                    <!-- Linea -->
                                    <span class="marker-line"></span>
                                    <!-- Texto debajo del circulo -->
                                    <!-- <span class="marker-text"></span> -->
                                </li>
                                <li class="{{tabCustomer}}">
                                    <!-- Circulo e icono -->
                                    <span class="marker-number"><i class="far fa-address-card fa-2x icon"></i></span>
                                    <!-- Linea -->
                                    <span class="marker-line"></span>
                                    <!-- Texto debajo del circulo -->
                                    <!-- <span class="marker-text"></span> -->
                                </li>
                                <li class="{{tabSign}}">
                                    <!-- Circulo e icono -->
                                    <span class="marker-number"><i class="fa-solid fa-signature fa-2x icon"></i></span>
                                    <!-- Linea -->
                                    <span class="marker-line"></span>
                                    <!-- Texto debajo del circulo -->
                                    <!-- <span class="marker-text"></span> -->
                                </li>
                                <li class="{{tabResult}}">
                                    <!-- Circulo e icono -->
                                    <span class="marker-number"><i class="fa-solid fa-file-invoice fa-2x icon"></i></span>
                                    <!-- Linea -->
                                    <!-- <span class="marker-line"></span> -->
                                    <!-- Texto debajo del circulo -->
                                    <!-- <span class="marker-text"></span> -->
                                </li>
                            </ul>
                            <!-- fin de steps -->

                            <!-- Seccion de pago con tarjeta de credito -->
                            <ng-container *ngIf="tabCard == 'active'">
                                <!-- <h3>Credit Card Information</h3> -->
                                <section class="row">
                                    <div class="col-6 container-image-credit-card">

                                        <div class="tab-content" id="borderTopContent">
                                            <!-- inicio seccion credit card -->
                                            <div class="tab-pane fade show active" id="border-top-credit-card"
                                                role="tabpanel" aria-labelledby="border-top-credit-card-tab">
                                                <div class="credit-card-information">
                                                    <div class="container-card preload">
                                                        <div class="creditcard">
                                                            <div class="front">
                                                                <div id="ccsingle"></div>
                                                                <svg version="1.1" id="cardfront"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                                                    y="0px" viewBox="0 0 750 471"
                                                                    style="enable-background:new 0 0 750 471;"
                                                                    xml:space="preserve">
                                                                    <g id="Front">
                                                                        <g id="CardBackground">
                                                                            <g id="Page-1_1_">
                                                                                <g id="amex_1_">
                                                                                    <path id="Rectangle-1_1_"
                                                                                        class="lightcolor grey" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                                                                     C0,17.9,17.9,0,40,0z" />
                                                                                </g>
                                                                            </g>
                                                                            <path class="darkcolor greydark"
                                                                                d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z" />
                                                                        </g>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 60.106 295.0121)"
                                                                            id="svgnumber" class="st2 st3 st4">0123
                                                                            4567 8910 1112</text>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 54.1064 428.1723)"
                                                                            id="svgname" class="st2 st5 st6">JOHN
                                                                            DOE</text>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 54.1074 389.8793)"
                                                                            class="st7 st5 st8">cardholder
                                                                            name</text>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 479.7754 388.8793)"
                                                                            class="st7 st5 st8">expiration</text>
                                                                        <text transform="matrix(1 0 0 1 65.1054 241.5)"
                                                                            class="st7 st5 st8">card number</text>
                                                                        <g>
                                                                            <text
                                                                                transform="matrix(1 0 0 1 574.4219 433.8095)"
                                                                                id="svgexpire"
                                                                                class="st2 st5 st9">01/23</text>
                                                                            <text
                                                                                transform="matrix(1 0 0 1 479.3848 417.0097)"
                                                                                class="st2 st10 st11">VALID</text>
                                                                            <text
                                                                                transform="matrix(1 0 0 1 479.3848 435.6762)"
                                                                                class="st2 st10 st11">THRU</text>
                                                                            <polygon class="st2"
                                                                                points="554.5,421 540.4,414.2 540.4,427.9 		" />
                                                                        </g>
                                                                        <g id="cchip">
                                                                            <g>
                                                                                <path class="st2"
                                                                                    d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                                                                 c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z" />
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="82" y="70" class="st12"
                                                                                        width="1.5" height="60" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="167.4" y="70" class="st12"
                                                                                        width="1.5" height="60" />
                                                                                </g>
                                                                                <g>
                                                                                    <path class="st12"
                                                                                        d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                                                                     c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                                                                     C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                                                                     c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                                                                     c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="82.8" y="82.1" class="st12"
                                                                                        width="25.8" height="1.5" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="82.8" y="117.9"
                                                                                        class="st12" width="26.1"
                                                                                        height="1.5" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="142.4" y="82.1"
                                                                                        class="st12" width="25.8"
                                                                                        height="1.5" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="142" y="117.9" class="st12"
                                                                                        width="26.2" height="1.5" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Back">
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <div class="back">
                                                                <svg version="1.1" id="cardback"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                                                    y="0px" viewBox="0 0 750 471"
                                                                    style="enable-background:new 0 0 750 471;"
                                                                    xml:space="preserve">
                                                                    <g id="Front">
                                                                        <line class="st0" x1="35.3" y1="10.4" x2="36.7"
                                                                            y2="11" />
                                                                    </g>
                                                                    <g id="Back">
                                                                        <g id="Page-1_2_">
                                                                            <g id="amex_2_">
                                                                                <path id="Rectangle-1_2_"
                                                                                    class="darkcolor greydark" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                                                                 C0,17.9,17.9,0,40,0z" />
                                                                            </g>
                                                                        </g>
                                                                        <rect y="61.6" class="st2" width="750"
                                                                            height="78" />
                                                                        <g>
                                                                            <path class="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                                                             C707.1,246.4,704.4,249.1,701.1,249.1z" />
                                                                            <rect x="42.9" y="198.6" class="st4"
                                                                                width="664.1" height="10.5" />
                                                                            <rect x="42.9" y="224.5" class="st4"
                                                                                width="664.1" height="10.5" />
                                                                            <path class="st5"
                                                                                d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
                                                                        </g>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 621.999 227.2734)"
                                                                            id="svgsecurity" class="st6 st7">985</text>
                                                                        <g class="st8">
                                                                            <text
                                                                                transform="matrix(1 0 0 1 518.083 280.0879)"
                                                                                class="st9 st6 st10">security
                                                                                code</text>
                                                                        </g>
                                                                        <rect x="58.1" y="378.6" class="st11"
                                                                            width="375.5" height="13.5" />
                                                                        <rect x="58.1" y="405.6" class="st11"
                                                                            width="421.7" height="13.5" />
                                                                        <text
                                                                            transform="matrix(1 0 0 1 59.5073 228.6099)"
                                                                            id="svgnameback" class="st12 st13">John
                                                                            Doe</text>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- fin seccion credit card -->
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-12 col-lg-6 container-form-credit-card">

                                        <div class="mt-5 mb-3 section-info">
                                            <h4 class="m-0">Credit Card Information</h4>
                                            <span>Fill out the following fields with the required information</span>
                                        </div>

                                        <form>
                                            <div class="row col-12 form-credit-card form-padd">

                                                <div class="col-12 form-group mb-3">
                                                    <label for="name">Name on Card</label>
                                                    <input type="text" class="form-control" [(ngModel)]="paymentProfile.credit_card.holder_name"
                                                    id="name_credit_card" placeholder="Name" name="name_credit_card">
                                                </div>

                                                <div class="method-square" [hidden]="paymentGatewaySetting .name !== 'SQUARE'">                                                
                                                    <div class="col-12 form-group mb-3">
                                                        <form id="payment-form">
                                                            <div id="card-container"></div>
                                                            <button id="card-button" type="button" style="display: none;">Pay $1.00</button>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div class="col-12 form-group mb-3" [hidden]="paymentGatewaySetting .name === 'SQUARE'">
                                                    <label for="cardNumber">Card Number (no spaces or dashes)</label>
                                                    <input type="text" class="form-control" [(ngModel)]="paymentProfile.credit_card.number" id="cardNumber_own"
                                                        inputmode="numeric" placeholder="0000 0000 0000 0000" maxlength="19" (ngModelChange)="setCardNumber()"
                                                        name="cardNumber">
                                                    <svg id="ccicon" class="ccicon" width="750" height="471" viewBox="0 0 750 471" version="1.1"
                                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                    </svg>
                                                </div>
                                                <div class="col-4 form-group mb-3 pr-3" [hidden]="paymentGatewaySetting .name === 'SQUARE'">
                                                    <label for="">Expiration</label>
                                                    <input type="text" class="form-control" id="expiration_date" inputmode="numeric" placeholder="MM" maxlength="2"
                                                        name="expiration_date" [(ngModel)]="paymentProfile.credit_card.expiration_date.month">
                                                </div>
                                                <div class="col-4 form-group mb-3 pl-3 expiration-date-yy" [hidden]="paymentGatewaySetting .name === 'SQUARE'">
                                                    <label for=""></label>
                                                    <input type="text" class="form-control" id="expiration_date_yy" inputmode="numeric" placeholder="YY" maxlength="2"
                                                        name="expiration_date_yy" [(ngModel)]="paymentProfile.credit_card.expiration_date.year">
                                                </div>
                                                
                                                <div class="col-4 form-group mb-3" [hidden]="paymentGatewaySetting .name === 'SQUARE'">
                                                    <label for="securitycode">CVV</label>
                                                    <input type="text" class="form-control" pattern="[0-9]*" inputmode="numeric"
                                                        [(ngModel)]="paymentProfile.credit_card.security_code" id="securitycode" placeholder="123" maxlength="4"
                                                        name="securitycode">
                                                </div>
                                                <div class="col-12 form-group m-0">
                                                    <hr class="separator-credit-card">
                                                </div>

                                                <div class="col-12 col-sm-8 form-group mb-3">
                                                    <label for="">Street</label>
                                                    <input type="text"
                                                        [(ngModel)]="paymentProfile.billing_address.street"
                                                        class="form-control" id="address" placeholder="address"
                                                        name="address">
                                                </div>

                                                <div class="col-12 col-sm-4 form-group mb-3">
                                                    <label class="control-label">State</label>
                                                    <select name="state" id="state"
                                                        [(ngModel)]="paymentProfile.billing_address.state"
                                                        class="form-control">
                                                        <option value="AK">Alaska</option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NC">North-carolina</option>
                                                        <option value="ND">North-dakota</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NH">New-hampshire</option>
                                                        <option value="NJ">New-jersey</option>
                                                        <option value="NM">New-mexico</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NY">New-york</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode-island</option>
                                                        <option value="SC">South-carolina</option>
                                                        <option value="SD">South-dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="WI">Washington</option>
                                                        <option value="NM">Wisconsin</option>
                                                        <option value="WV">West-virginia</option>
                                                        <option value="WY">Wyoming</option>
                                                    </select>
                                                </div>

                                                <div class="col-12 col-sm-8 form-group mb-3">
                                                    <label for="city">City</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="paymentProfile.billing_address.city" id="city"
                                                        placeholder="city" name="city">
                                                </div>

                                                <div class="col-12 col-sm-4 form-group mb-3">
                                                    <label for="zipCode">Zip Code</label>
                                                    <input type="text" class="form-control" id="zip_code"
                                                        [(ngModel)]="paymentProfile.billing_address.zip_code"
                                                        inputmode="numeric" pattern="[0-9]*"
                                                        name="zip_code" placeholder="zip code">
                                                </div>

                                            </div>
                                        </form>
                                    </div>

                                </section>
                            </ng-container>
                            <!-- Fin de seccion de pago con tarjeta de credito -->

                            <!-- Seccion de informacion de la persona que esta pagando -->
                            <ng-container *ngIf="tabCustomer == 'active'">
                                <!-- <h3>Contact information</h3> -->
                                <section class="row">
                                    <div class="col-6 container-image-credit-card">

                                        <div class="tab-content" id="borderTopContent">
                                            <!-- inicio seccion credit card -->
                                            <div class="tab-pane fade show active" id="border-top-credit-card"
                                                role="tabpanel" aria-labelledby="border-top-credit-card-tab">
                                                <div class="credit-card-information">
                                                    <div class="container-card preload">
                                                        <div class="creditcard">
                                                            <div class="front">
                                                                <div id="ccsingle"></div>
                                                                <svg version="1.1" id="cardfront"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                                                    y="0px" viewBox="0 0 750 471"
                                                                    style="enable-background:new 0 0 750 471;"
                                                                    xml:space="preserve">
                                                                    <g id="Front">
                                                                        <g id="CardBackground">
                                                                            <g id="Page-1_1_">
                                                                                <g id="amex_1_">
                                                                                    <path id="Rectangle-1_1_"
                                                                                        class="lightcolor grey" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                                                                     C0,17.9,17.9,0,40,0z" />
                                                                                </g>
                                                                            </g>
                                                                            <path class="darkcolor greydark"
                                                                                d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z" />
                                                                        </g>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 60.106 295.0121)"
                                                                            id="svgnumber" class="st2 st3 st4">0123
                                                                            4567 8910 1112</text>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 54.1064 428.1723)"
                                                                            id="svgname" class="st2 st5 st6">JOHN
                                                                            DOE</text>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 54.1074 389.8793)"
                                                                            class="st7 st5 st8">cardholder
                                                                            name</text>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 479.7754 388.8793)"
                                                                            class="st7 st5 st8">expiration</text>
                                                                        <text transform="matrix(1 0 0 1 65.1054 241.5)"
                                                                            class="st7 st5 st8">card number</text>
                                                                        <g>
                                                                            <text
                                                                                transform="matrix(1 0 0 1 574.4219 433.8095)"
                                                                                id="svgexpire"
                                                                                class="st2 st5 st9">01/23</text>
                                                                            <text
                                                                                transform="matrix(1 0 0 1 479.3848 417.0097)"
                                                                                class="st2 st10 st11">VALID</text>
                                                                            <text
                                                                                transform="matrix(1 0 0 1 479.3848 435.6762)"
                                                                                class="st2 st10 st11">THRU</text>
                                                                            <polygon class="st2"
                                                                                points="554.5,421 540.4,414.2 540.4,427.9 		" />
                                                                        </g>
                                                                        <g id="cchip">
                                                                            <g>
                                                                                <path class="st2"
                                                                                    d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                                                                 c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z" />
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="82" y="70" class="st12"
                                                                                        width="1.5" height="60" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="167.4" y="70" class="st12"
                                                                                        width="1.5" height="60" />
                                                                                </g>
                                                                                <g>
                                                                                    <path class="st12"
                                                                                        d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                                                                     c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                                                                     C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                                                                     c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                                                                     c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="82.8" y="82.1" class="st12"
                                                                                        width="25.8" height="1.5" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="82.8" y="117.9"
                                                                                        class="st12" width="26.1"
                                                                                        height="1.5" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="142.4" y="82.1"
                                                                                        class="st12" width="25.8"
                                                                                        height="1.5" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="142" y="117.9" class="st12"
                                                                                        width="26.2" height="1.5" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Back">
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <div class="back">
                                                                <svg version="1.1" id="cardback"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                                                    y="0px" viewBox="0 0 750 471"
                                                                    style="enable-background:new 0 0 750 471;"
                                                                    xml:space="preserve">
                                                                    <g id="Front">
                                                                        <line class="st0" x1="35.3" y1="10.4" x2="36.7"
                                                                            y2="11" />
                                                                    </g>
                                                                    <g id="Back">
                                                                        <g id="Page-1_2_">
                                                                            <g id="amex_2_">
                                                                                <path id="Rectangle-1_2_"
                                                                                    class="darkcolor greydark" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                                                                 C0,17.9,17.9,0,40,0z" />
                                                                            </g>
                                                                        </g>
                                                                        <rect y="61.6" class="st2" width="750"
                                                                            height="78" />
                                                                        <g>
                                                                            <path class="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                                                             C707.1,246.4,704.4,249.1,701.1,249.1z" />
                                                                            <rect x="42.9" y="198.6" class="st4"
                                                                                width="664.1" height="10.5" />
                                                                            <rect x="42.9" y="224.5" class="st4"
                                                                                width="664.1" height="10.5" />
                                                                            <path class="st5"
                                                                                d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
                                                                        </g>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 621.999 227.2734)"
                                                                            id="svgsecurity" class="st6 st7">985</text>
                                                                        <g class="st8">
                                                                            <text
                                                                                transform="matrix(1 0 0 1 518.083 280.0879)"
                                                                                class="st9 st6 st10">security
                                                                                code</text>
                                                                        </g>
                                                                        <rect x="58.1" y="378.6" class="st11"
                                                                            width="375.5" height="13.5" />
                                                                        <rect x="58.1" y="405.6" class="st11"
                                                                            width="421.7" height="13.5" />
                                                                        <text
                                                                            transform="matrix(1 0 0 1 59.5073 228.6099)"
                                                                            id="svgnameback" class="st12 st13">John
                                                                            Doe</text>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- fin seccion credit card -->
                                        </div>

                                    </div>

                                    <div class="col-12 col-md-12 col-lg-6 container-form-credit-card">

                                        <div class="mt-5 mb-3 section-info">
                                            <h4 class="m-0">Contact Information</h4>
                                            <span>Fill out the following fields with the required information</span>
                                        </div>

                                        <div class="row col-12 form-credit-card form-padd">

                                            <div class="col-12 form-group mb-3">
                                                <label for="name">Name</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="paymentProfile.card_holder.name" id="name_card_holder"
                                                    placeholder="Name">
                                            </div>
                                            <div class="col-12 form-group mb-3">
                                                <label for="name">Last Name</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="paymentProfile.card_holder.last_name"
                                                    id="name_card_holder" placeholder="Last Name">
                                            </div>
                                            <div class="col-12 form-group mb-3">
                                                <label for="name">Email</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="paymentProfile.card_holder.email"
                                                    id="email_card_holder" placeholder="Email">
                                            </div>
                                            <div class="col-12 form-group mb-3">
                                                <label for="name">Phone</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="paymentProfile.card_holder.phone"
                                                    id="phone_card_holder" placeholder="Phone">
                                            </div>
                                            <div class="col-12 form-group mb-3" *ngIf="companySettings.customer_birthday_for_cc">
                                                <label for="holderBirthday">Holder Birthday</label>
                                                <input type="date" class="form-control" id="holderBirthday" placeholder="dd/mm/aaaa"
                                                    [(ngModel)]="paymentProfile.card_holder.birth_date">
                                            </div>
                                            <div class="col-12 form-group mb-3" *ngIf="companySettings.require_customer_id">
                                                <label for="customer_id">Customer Id</label>
                                                <input type="text" class="form-control" id="customer_id" required ="Customer Id"
                                                    [(ngModel)]="paymentProfile.customer_identification.number_id">
                                            </div>
                                            <div class="col-12 form-group mb-3" *ngIf="companySettings.require_customer_id">
                                                <label for="id_expiration_date">ID Expiration Date</label>
                                                <input type="date" class="form-control" id="id_expiration_date" required placeholder="dd/mm/aaaa"
                                                    [(ngModel)]="paymentProfile.customer_identification.expiration_date">
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </ng-container>
                            <!-- Fin de la eccion de informacion de la persona que esta pagando -->

                            <!-- Seccion de firma del authorize -->
                            <ng-container *ngIf="tabSign == 'active'">
                                <!-- <h3>Authorization</h3> -->
                                <section class="row d-flex">
                                    <div class="d-flex col-6 container-image-credit-card justify-content-center align-items-center">

                                        <iframe class="signature-mobile" id="filePfd" style="width:970px; height:650px;"
                                            title="Authorization"></iframe>

                                    </div>

                                    <div class="col-12 col-md-12 col-lg-6 container-form-credit-card">
                                        <div class="mt-5 mb-3 section-info">
                                            <h4 class="m-0">Signature</h4>
                                            <span>Sign the credit card payment authorization here</span>
                                        </div>
                                        <div class="form-credit-card form-padd padd-30">
                                            <app-sign (onSignature)="onSignature($event)"></app-sign>
                                        </div>

                                    </div>
                                </section>
                            </ng-container>
                            <!-- Fin de la seccion de firma del authorize -->

                            <!-- Seccion de informacion del pago -->
                            <ng-container *ngIf="tabResult == 'active'">
                                <!-- <h3>Payment Information</h3> -->
                                <section class="row d-flex justify-content-center invoice mt-4">

                                    <div class="invoice-container mb-3" style="width: 750px;"
                                        *ngIf="responseTransaction.transaction.status">
                                        <div class="invoice-inbox col-12 pt-2 pb-2">
                                            <div id="ct" class="">
                                                <div class="invoice-00001">
                                                    <div *ngIf="responseTransaction.transaction.status == 'PAID'; else transactionFailed">
                                                        <!-- Payment Successful -->
                                                        <div class="content-section">
                                                            <div class="row">
                                                                <div class="col-2 pr-0 text-center container-center">
                                                                    <i class="far fa-check-circle icon-payment-successful"></i>
                                                                </div>
                                                                <div class="col-10 pl-0">
                                                                    <h4 class="m-0 text-payment-successful">
                                                                        Payment Successful
                                                                    </h4>
                                                                    <span
                                                                        class="desc-payment">{{responseTransaction.message}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ng-template #transactionFailed>
                                                        <!-- Payment Failed -->
                                                        <div *ngIf="responseTransaction.transaction.status == 'DECLINED' || responseTransaction.transaction.status == 'ERROR'; else noneTransaction">
                                                            <div class="content-section">
                                                                <div class="row">
                                                                    <div class="col-2 pr-0 text-center container-center">
                                                                        <i class="far fa-times-circle icon-payment-failed"></i>
                                                                    </div>
                                                                    <div class="col-10 pl-0">
                                                                        <h4 class="m-0 text-payment-failed">
                                                                            Payment Failed
                                                                        </h4>
                                                                        <span class="desc-payment">{{responseTransaction.message}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ng-template #noneTransaction>
                                                            <!-- Payment Failed -->
                                                            <div *ngIf="responseTransaction.transaction.status == 'HELD_FOR_REVIEW'">
                                                                <div class="content-section">
                                                                    <div class="row">
                                                                        <div class="col-2 pr-0 text-center container-center">
                                                                            <i class="far fa-times-circle icon-payment-failed"></i>
                                                                        </div>
                                                                        <div class="col-10 pl-0">
                                                                            <h4 class="m-0 text-payment-failed">
                                                                                Held For Review
                                                                            </h4>
                                                                            <span class="desc-payment">{{responseTransaction.message}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </ng-template>
                                                    </ng-template>

                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-2 download-invoice">
                                            <button class="btn btn-secondary">Download</button>
                                        </div> -->
                                    </div>

                                    <div class="invoice-container" style="width: 750px;">
                                        <div class="invoice-inbox">

                                            <div id="ct" class="">

                                                <div class="invoice-00001">
                                                    <!-- <div class="content-section"
                                                        *ngIf="responseTransaction.transaction.status; else loadingTransaction"> -->
                                                    <div class="content-section">

                                                        <div class="inv--head-section inv--detail-section border-0">

                                                            <div class="row text-mbl-center">

                                                                <div class="col-sm-6 col-12 mr-auto">
                                                                    <img class="company-logo"
                                                                        src="{{environment.az_api.cdn}}/{{authService.session.workspace.id}}/media-files/{{responseTransaction.company.logo}}"
                                                                        alt="company">
                                                                </div>

                                                                <div class="col-sm-6 text-sm-right info-company">
                                                                    <p class="inv-email-address">
                                                                        {{responseTransaction.company.name}}
                                                                    </p>
                                                                    <p class="inv-email-address d-mbl-grid">
                                                                        <span>{{responseTransaction.company.address.street}} {{responseTransaction.company.address.city}}</span>
                                                                        <span> {{responseTransaction.company.address.state}}, {{responseTransaction.company.address.zip_code}}</span>  
                                                                    </p>
                                                                    <p class="inv-email-address d-mbl-grid">
                                                                        <span>ICC MC: {{responseTransaction.company.license}}</span>
                                                                        <span class="dash-mbl-none px-2"> - </span>
                                                                        <span>US D.O.T: {{responseTransaction.company.dot}}</span>                                                                        
                                                                    </p>
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class="inv--detail-section inv--customer-detail-section mt-3">

                                                            <div class="row">

                                                                <div class="col-sm-6 text-left">
                                                                    <p class="inv-created-date inv_separator_1 inv_separator_2">
                                                                        <span class="inv-title title-details">Invoice Date : </span>                                                           
                                                                        <span class="">{{responseTransaction.transaction.created | dateTime}}</span>
                                                                    </p>
                                                                </div>
                                                                <div class="col-sm-6 align-self-center header-company-info invoice-to">
                                                                    <p class="title-details mb-1">Invoice To</p>
                                                                    <p>
                                                                        <span class="inv-name">Name:</span>
                                                                        <span class="invoice-to-name">{{paymentProfile.card_holder.name}} {{paymentProfile.card_holder.last_name}}</span>                                                   
                                                                    </p>
                                                                    <p>
                                                                        <span class="inv-phone">Phone:</span>
                                                                        <span>{{paymentProfile.card_holder.phone}}</span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="inv-email">Email:</span>
                                                                        <span class="invoice-to-email">{{paymentProfile.card_holder.email}}</span>
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div class="row mt-4">
                                                                <div class="col-sm-6 payment-info">
                                                                    <p class="title-details mb-1 inv_separator_3">Payment Info</p>
                                                                    <p>
                                                                        <span class="inv-name">Concept:</span>
                                                                        <span>{{balance.concept}}</span>
                                                                    </p>       
                                                                    <p>
                                                                        <span class="inv-name">Payment Method:</span>
                                                                        <span>Credit Card</span>
                                                                    </p>                                                             
                                                                    <p>
                                                                        <span class="inv-name">Card Type:</span>
                                                                        <span *ngIf="responseTransaction.transaction.card_type == ''">
                                                                            ******
                                                                        </span>
                                                                        <span *ngIf="responseTransaction.transaction.card_type != ''">
                                                                            {{responseTransaction.transaction.card_type}}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="inv-name">Product:</span>
                                                                        <span>{{responseTransaction.transaction.card_number}}</span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="inv-name">Transaction ID: </span>                                                     
                                                                        <span *ngIf="responseTransaction.transaction.auth_code == ''">
                                                                            ******
                                                                        </span>
                                                                        <span *ngIf="responseTransaction.transaction.auth_code != ''">
                                                                            {{responseTransaction.transaction.auth_code}}
                                                                        </span>
                                                                    </p>  
                                                                </div>

                                                               
                                                                <!-- Payment Successful -->
                                                                <div class="col-sm-6 align-self-end inv--payment-info" *ngIf="responseTransaction.transaction.status == 'PAID'">
                                                                    <p class="inv_separator_4">
                                                                        <span class="txt-payment-status">Payment Status:</span>
                                                                        <span class="txt-approved">Approved</span>
                                                                    </p>

                                                                    <div class="total-section-successful mt-2">
                                                                        <span>Deposit</span> 
                                                                        <span>{{amount_balance | currency}}</span>
                                                                    </div>
                                                                    <div class="total-section-successful mt-2">
                                                                        <span>CC Fee</span> 
                                                                        <span>{{cc_fee | currency}}</span>
                                                                    </div>
                                                                    <div class="total-section-successful mt-2">
                                                                        <span>Amount Paid</span> 
                                                                        <span>{{(amount_balance + cc_fee) | currency}}</span>
                                                                    </div>
                                                                </div>

                                                                <!-- Payment Failed -->
                                                                <div class="col-sm-6 align-self-end inv--payment-info" *ngIf="responseTransaction.transaction.status != 'PAID'">
                                                                    <p class="inv_separator_4">
                                                                        <span class="txt-payment-status">Payment Status:</span>
                                                                        <span class="txt-failed">Denied</span>
                                                                    </p>
                                                                    <div class="total-section-failed mt-2">
                                                                        <span>Deposit</span> 
                                                                        <span>{{amount_balance | currency}}</span>
                                                                    </div>
                                                                    <div class="total-section-failed mt-2">
                                                                        <span>CC Fee</span> 
                                                                        <span>{{cc_fee | currency}}</span>
                                                                    </div>
                                                                    <div class="total-section-failed mt-2">
                                                                        <span>Amount Paid</span> 
                                                                        <span>{{(amount_balance + cc_fee) | currency}}</span>
                                                                    </div>
                                                                </div>                                                                    
                                                                
                                                                <div
                                                                    class="col-xl-6 col-lg-5 col-md-6 col-sm-8 col-12 order-sm-0 order-1">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ng-template #loadingTransaction>
                                                        <div class="content-section">
                                                            <div class="row">
                                                                <div class="col-2 pr-0 text-center">
                                                                    <i class="fas fa-spinner"></i>
                                                                </div>
                                                                <div class="col-10 pl-0">
                                                                    <h4 class="m-0 text-payment-processing">
                                                                        Processing...
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row invoice-container mt-3" style="width: 750px;">
                                        <div class="invoice-inbox col-12">
                                            <div id="ct" class="">
                                                <div class="invoice-00001">
                                                    <div class="content-section">
                                                        <div class="response-payment-status">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </ng-container>
                            <!-- Fin de la seccion de informacion del pago -->

                            <!-- Seccion de botones next and preview -->
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12 mb-12 next-and-preview">
                                <button [hidden]="tabCard == 'active' || tabResult == 'active'" class="btn btn-primary"
                                    (click)="changeTab('preview')">
                                    Preview
                                </button>
                                <button [hidden]="tabResult == 'active'" [disabled]="tabSign == 'active' && !signature"
                                    class="btn btn-success" (click)="changeTab('next')">
                                    Next
                                </button>
                            </div>
                            <!-- Fin de seccion de botones next and preview -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>