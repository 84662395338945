import { Document } from './document';

export class EstimateDocument {
    public id: string;
    public estimate_id: string;
    public estimate_email_id: number;
    public balance_id: string;
    public document: Document;
    // type: 'QUOTE' | 'CREDIT_CARD_AUTHORIZATION';
    // pre_pdf: string;
    // pdf_file: File;
    // esignature: ElectronicSignature;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.estimate_id = null;
        this.estimate_email_id = null;
        this.balance_id = null;
        this.document = new Document();
        // this.type = null;
        // this.pre_pdf = null;
        // this.pdf_file = new File();
        // this.esignature = new ElectronicSignature();
        this.created = null;
        this.updated = null;
    }
}
