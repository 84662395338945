export const ConstantsMessages = {
    CHANGE_COMPANY_SUCCESS: 'Company switched successfully',
    COPIED: 'Text copied successfully',
    DELETED: 'Successfully removed',
    LOAD_DOCUMENT: 'The document has been loaded successfully',
    SAVED: 'Successfully saved',
    SIGNED: 'The document has been signed successfully',
    SEND_TOKEN: 'The token has been sent successfully',
    SESSION_STARTED: 'Logged in',
    SESSION_CLOSED: 'Session closed',
    ERROR_CHANGE_COMPANY: 'Company not switched',
    ERROR_DELETED: 'Remove Failed',
    ERROR_DOWNLOAD: 'Download failed',
    ERROR_LOAD_DOCUMENT: 'The document has not been loaded successfully',
    ERROR_SAVED: 'The changes have not been saved',
    ERROR_PRICE_DEPOSIT: 'The deposit cannot be higher than the price.',
    ERROR_SEND_TOKEN: 'The token has not been sent',
    ERROR_SESSION_STARTED: 'Error logging in',
};
