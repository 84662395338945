import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { RecurringCustomer } from 'src/app/entities/recurring-customer';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { RecurringCustomersService } from 'src/app/services/recurring-customers.service';

declare const swal;

@Component({
  selector: 'app-sing-from-email',
  templateUrl: './sing-from-email.component.html',
  styleUrls: ['./sing-from-email.component.scss']
})
export class SingFromEmailComponent implements OnInit {

  private balanceId: string;
  constructor(
    public router: Router,
    private currentRoute: ActivatedRoute,
    private authService: AuthService,
    private recurringCustomersService: RecurringCustomersService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
      // verficamos si se esta editando
      this.currentRoute.params.subscribe(params => {
        this.balanceId = params.id;
        try {
            if (params.id_customer_estimate) {
                this.loginCustomer(params.id_customer_estimate);
            }

        } catch (error) {
            console.error('Error: ', error);
            swal(
                'Error',
                error.error.message,
                'warning'
            );
        }
    });
  }

  loginCustomer(idCustomerEstimate) {
    this.authService
        .loginCustomer(idCustomerEstimate)
        .then((response: CustomerEstimate) => {

            
            const recurringCustomer = new RecurringCustomer();
            recurringCustomer.estimates_id = response.estimate_id;
            recurringCustomer.company_id = this.authService.session.company.id;
    
            this.recurringCustomersService
            .add(recurringCustomer);

            this.router.navigateByUrl('/payment/' + this.balanceId + '/sign-payment');
            this.helperService.showMessageSnackbar(ConstantsMessages.SESSION_STARTED);
        })
        .catch((error) => {
            console.error('Error', error);
            this.helperService.showMessageError(error, idCustomerEstimate);
        })
        .finally(() => {
        });
}

}
