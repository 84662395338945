import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Customer } from 'src/app/entities/customer';
import { Employee } from 'src/app/entities/employee';
import { Estimate } from 'src/app/entities/estimate';
import { Ticket } from 'src/app/entities/ticket';
import { TicketRemark } from 'src/app/entities/ticket-remark';
import { TicketRemarkView } from 'src/app/entities/ticket-remark-view';
import { WorkDepartment } from 'src/app/entities/work-department';
import { AuthService } from 'src/app/services/auth.service';
import { CustomersService } from 'src/app/services/customers.service';
import { EstimatesService } from 'src/app/services/estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { TicketsRemarksService } from 'src/app/services/tickets-remarks.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { environment } from 'src/environments/environment';
import { WorkDeparmentsService } from 'src/app/services/work-deparments.service';
import { CustomerEstimate } from 'src/app/entities/customer-estimate';
import { TicketType } from 'src/app/entities/ticket-type';


declare var jQuery;
@Component({
  selector: 'app-tickets-view',
  templateUrl: './tickets-view.component.html',
  styleUrls: ['./tickets-view.component.scss']
})
export class TicketsViewComponent implements OnInit {

    readonly environment = environment;
    public customerEstimate: CustomerEstimate;
    
    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    ticket: Ticket;
    customer: Customer;
    estimate: Estimate;
    dataForm: TicketRemark;
    remarks: Array<TicketRemarkView>;
    employees: Array<Employee>;
    workDepartments: Array<WorkDepartment>;
    ticketTypes : Array<TicketType>;

    constructor(
        public authService: AuthService,
        private ticketService: TicketsService,
        private currentRoute: ActivatedRoute,
        private estimatesService: EstimatesService,
        private customerService: CustomersService,
        private ticketsRemarksService: TicketsRemarksService,
        // private employeesService: EmployeesService,
        private workDepartmentsService: WorkDeparmentsService,
        private helperService: HelperService
    ) {
        this.ticket = new Ticket();
        this.customer = new Customer();
        this.dataForm = new TicketRemark();
        this.estimate = new Estimate();
        this.remarks = [];
        this.employees = [];
        this.workDepartments = [];
        this.ticketTypes = [];
    }

    ngOnInit(): void {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.loadTicket(params.id);
            }
        });
        this.customer = this.authService.session.customer;
        this.loadWorkDeparments();
        this.customerEstimate = this.helperService.loadCustomerServiceLocalStorage();
        this.loadTypes();
    }

    loadTicket(id: string) {
        this.helperService.showLoadingMxpro360();
        this.ticketService
            .getById(id)
            .then((response) => {
                this.ticket = response;
                this.loadEstimate(this.ticket.estimate_id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                this.loadRemarks();
            });
    }

    // loadEmployees() {
    //     this.helperService.showLoadingMxpro360();
    //     this.employeesService
    //         .getAll()
    //         .then((response) => {
    //             this.employees = response;
    //         })
    //         .catch((error) => {
    //             console.error('error', error);
    //         })
    //         .finally(() => {
    //             this.helperService.hideLoadingMxpro360();
    //         });
    // }

     loadWorkDeparments() {
         this.helperService.showLoadingMxpro360();
         this.workDepartmentsService
             .getAll()
             .then((response) => {
                 this.workDepartments = response;
             })
             .catch((error) => {
                 console.error('error', error);
             })
             .finally(() => {
                 this.helperService.hideLoadingMxpro360();
             });
    }

    loadEstimate(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .getById(id)
            .then((response) => {
                this.estimate = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadRemarks() {
        this.helperService.showLoadingMxpro360();
        this.ticketsRemarksService
            .getAll(this.ticket.id)
            .then((response) => {
                this.remarks = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    addRemarks() {
        this.helperService.showLoadingMxpro360();
        this.dataForm.customer_id = this.ticket.customer_id;
        this.dataForm.employee_id = this.ticket.employee_id;
        this.dataForm.ticket_id = this.ticket.id;
        this.dataForm.private = false;
        this.ticketsRemarksService
            .save(this.ticket.id, this.dataForm)
            .then((response) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.customer = response;

                this.dataForm = new TicketRemark();
                jQuery(".dropify-preview").css('display','none');
                jQuery(".dropify-preview .dropify-render").remove("img")
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('Error: ', error);
            })
            .finally(() => {
                this.loadRemarks();
                this.helperService.hideLoadingMxpro360();
            });

    }

    removeRemarks(id) {
        this.helperService.showLoadingMxpro360();
        this.ticketsRemarksService
            .remove(this.ticket.id, id)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_DELETED);
                console.error(error);
            }).finally(() => {
                this.helperService.hideLoadingMxpro360();
                this.loadRemarks();
            });
    }


    onChangeLogo(files: Array<string>) {
        if (files.length > 0) {
            const filess = {
                id: null,
                name: files[0],
                display_name: files[0],
                mime_type: this.helperService.getMimeType(files[0]),
                created: null
            };
            this.dataForm.files.push(filess);
        }
    }

    deleteImageItem(image) {
        for (let i = this.dataForm.files.length - 1; i >= 0; i--) {
            if (this.dataForm.files[i].display_name === image.display_name) {
                this.dataForm.files.splice(i, 1);
                break;
            }
        }
    }


    /**
     * permite verificar si un empleado pertenece al word department seleccionado
     * @param employee_id 
     */
    workDepartmentHasEmployee(employee_id: string) {
        for (let index in this.workDepartments) {
            if (this.workDepartments[index].id === this.ticket.work_department_id) {
                return this.workDepartments[index].employees_id.includes(employee_id);
            }
        }
        return false;
    }

 loadTypes() {
    this.helperService.showLoadingMxpro360();
    this.ticketService
    .getAllTypes()
    .then(response => {
    this.ticketTypes = response;
    
    })
    .catch((error) => {
      this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      console.error(error);
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }
}
