<div id="content" class="main-content d-flex justify-content-center padd-t40">
    <div class="container justify-content-center">
        <div class="container">
            <div class="row" id="cancel-row">

                <div class="row col-lg-12 layout-spacing marg-0 p-0">

                    <div class="col-12 col-md-1">
                    </div>

                    <ul>
                        <li class="{{tabBank}}">
                            <!-- Circulo e icono -->
                            <span class="marker-number"><i class="fas fa-university fa-2x icon"></i></span>
                            <!-- Linea -->
                            <span class="marker-line"></span>
                            <!-- Texto debajo del circulo -->
                            <!-- <span class="marker-text"></span> -->
                        </li>
                        <li class="{{tabResult}}">
                            <!-- Circulo e icono -->
                            <span class="marker-number"><i class="fa-solid fa-money-check fa-2x icon"></i></span>
                            <!-- <span class="marker-text"></span> -->
                        </li>
                    </ul>
                    <ng-container *ngIf="tabBank == 'active'">

                        <div class="col-12 col-md-6 text-right">
                            <h3 class="m-0"><strong>Payment via {{paymentMethod.name}}</strong></h3>

                            <div *ngIf="paymentMethod.description; else noneDescription">
                                <!-- Payment Successful -->
                                <div class="font-16 text-right" [innerHTML]="paymentMethod.description">
                                </div>
                            </div>
                            <ng-template #noneDescription>
                                <div class="font-16 text-right">
                                    Please enter the info to make the payment
                                </div>
                            </ng-template>

                            <div class="col-12 p-0 mt-3 padd-l0">
                                <div class="infobox-3 infobox-amount mt-0">
                                    <div class="info-icon">
                                        <i class="fas fa-dollar-sign"></i>
                                    </div>
                                    <h5 class="info-heading">Payment amount</h5>
                                    <div class="amount">${{balance.amount}}</div>
                                </div>
                            </div>

                            <div class="container-check">
                                <div class="check-border">

                                    <div class="row-one">

                                        <div class="customer-info">
                                            <div class="customer-name">
                                                <span *ngIf="paymentProfile.bank_information.account_name">{{paymentProfile.bank_information.account_name}}</span>
                                                <span *ngIf="!paymentProfile.bank_information.account_name" class="label">John Doe</span>
                                            </div>
                                            <div class="customer-street">
                                                <span *ngIf="paymentProfile.billing_address.street">{{paymentProfile.billing_address.street}}</span>
                                                <span *ngIf="!paymentProfile.billing_address.street" class="label">123 Main St</span>
                                            </div>
                                            <div class="customer-address">
                                                <span>{{paymentProfile.billing_address.city}} {{paymentProfile.billing_address.state}} {{paymentProfile.billing_address.zip_code}} US</span>
                                            </div>
                                        </div>

                                        <div class="date">
                                            <div class="txt-date">Date</div>
                                            <div class="line">{{dateNow}}</div>
                                        </div>

                                    </div>

                                    <div class="row-two">

                                        <div class="pay-to">
                                            <span>PAY TO THE ORDER OF</span>
                                        </div>

                                        <div class="line">
                                            <span>
                                                {{authService.session.company.name}}
                                            </span>
                                        </div>

                                        <div class="dollar">
                                            <span>$</span>
                                        </div>

                                        <div class="total-amount">
                                            <span>{{balance.amount}}</span>
                                        </div>

                                    </div>

                                    <div class="row-three">
                                        <div class="line">
                                            <span>
                                                {{numberToString}}
                                            </span>
                                        </div>
                                        <div class="amount-letters">
                                            DOLLARS
                                        </div>

                                    </div>

                                    <div class="row-four">

                                        <div class="bank">
                                            <div>
                                                <i class="fa-solid fa-building-columns"></i>
                                            </div>
                                            <!-- <div>
                                                <div class="bank-name"></div>
                                            </div> -->
                                        </div>

                                        <div class="memo">
                                            <span>Deposit</span>
                                        </div>

                                        <div class="customer-name">
                                            <span *ngIf="paymentProfile.bank_information.account_name">{{paymentProfile.bank_information.account_name}}</span>
                                            <span *ngIf="!paymentProfile.bank_information.account_name" class="label">John Doe</span>
                                        </div>

                                    </div>

                                    <div class="row-five">

                                        <div class="routing">
                                            <div *ngIf="paymentProfile.bank_information.routing_number">{{paymentProfile.bank_information.routing_number}}</div>
                                            <div class="label" *ngIf="!paymentProfile.bank_information.routing_number">123456789</div>
                                        </div>

                                        <div class="account">
                                            <div *ngIf="paymentProfile.bank_information.account_number">{{paymentProfile.bank_information.account_number}}</div>
                                            <div class="label" *ngIf="!paymentProfile.bank_information.account_number">987654321</div>
                                        </div>

                                        <div class="check">
                                            <div>XXXX</div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                        <div class="col-12 col-md-6 padd-t5">

                            <div class=" widget widget-content widget-content-area b-radius-10 p-4">
                             
                                <div class="col-md-12 form-group mb-3">
                                    <label for="">Holder's Name</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Full Name"
                                        name="full_name" [(ngModel)]="paymentProfile.bank_information.account_name">
                                    </div>
                                </div>
                       
                                <div class="col-md-12 form-group mb-3">
                                    <label for="">Phone Number</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="(555) 555-5555" name="phone"  [(ngModel)]="paymentProfile.card_holder.phone">
                                    </div>
                                </div>

                                <div class="col-md-12 form-group mb-3">
                                    <label for="">Email</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="email@address.com"  name="email"  [(ngModel)]="paymentProfile.card_holder.email">
                                    </div>
                                </div>
                       
                                <div class="d-flex">

                                    <div class="col-md-7 form-group mb-3 pr-0">
                                        <label for="">Street</label>
                                        <div class="form-group m-0">
                                            <input type="text" class="form-control" placeholder="Address" name="street"  [(ngModel)]="paymentProfile.billing_address.street">
                                        </div>
                                    </div>
    
                                    <div class="col-md-5 form-group mb-3">
                                        <label class="control-label">State</label>
                                        <select name="state" id="state" class="form-control" name="state"  [(ngModel)]="paymentProfile.billing_address.state">
                                            <option value="AK">Alaska</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="IA">Iowa</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MD">Maryland</option>
                                            <option value="ME">Maine</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MT">Montana</option>
                                            <option value="NC">North-carolina</option>
                                            <option value="ND">North-dakota</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NH">New-hampshire</option>
                                            <option value="NJ">New-jersey</option>
                                            <option value="NM">New-mexico</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NY">New-york</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode-island</option>
                                            <option value="SC">South-carolina</option>
                                            <option value="SD">South-dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VA">Virginia</option>
                                            <option value="VT">Vermont</option>
                                            <option value="WI">Washington</option>
                                            <option value="NM">Wisconsin</option>
                                            <option value="WV">West-virginia</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>

                                </div>

                                <div class="d-flex">

                                    <div class="col-md-7 form-group mb-3 pr-0">
                                        <label for="">City</label>
                                        <div class="form-group m-0">
                                            <input type="text" class="form-control" placeholder="City" name="city"  [(ngModel)]="paymentProfile.billing_address.city">
                                        </div>
                                    </div>
                                
                                    <div class="col-md-5 form-group mb-3">
                                        <label for="">Zip Code</label>
                                        <div class="form-group m-0">
                                            <input type="text" class="form-control" placeholder="Zip Code" name="zip_code" [(ngModel)]="paymentProfile.billing_address.zip_code">
                                        </div>
                                    </div>

                                </div>    
                                
                                <div class="d-flex">

                                    <div class="col-md-6 form-group mb-3">
                                        <label for="">Routing #</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Routing"
                                            [(ngModel)]="paymentProfile.bank_information.routing_number">
                                        </div>
                                    </div>
                                
                                    <div class="col-md-6 form-group mb-3">
                                        <label for="">Account #</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Account"
                                            [(ngModel)]="paymentProfile.bank_information.account_number">
                                        </div>
                                    </div>

                                </div> 
                        
                                <div class="col text-right mt-4">
                                    <!-- <button class="btn btn-success width-100" (click)="sendPayment()">Pay</button> -->
                                    <button class="btn btn-success width-100" (click)="sendPaymentAuthorizationAch()">Pay</button>
                                </div>

                            </div>

                        </div>

                    </ng-container>


                    <!-- Seccion de firma del authorize -->
                    <ng-container *ngIf="tabSign == 'active'">
                        <!-- <h3>Authorization</h3> -->
                        <section class="row d-flex">
                            <div class="d-flex col-6 container-image-credit-card justify-content-center align-items-center">

                                <iframe class="signature-mobile" id="filePfd" style="width:970px; height:650px;"
                                    title="Authorization"></iframe>

                            </div>

                            <div class="col-12 col-md-12 col-lg-6 container-form-credit-card">
                                <div class="mt-5 mb-3 section-info">
                                    <h4 class="m-0">Signature</h4>
                                    <span>Sign the ACH payment authorization here</span>
                                </div>
                                <div class="form-credit-card form-padd padd-30">
                                    <app-sign (onSignature)="onSignature($event)"></app-sign>
                                </div>

                                <div class="col text-right mt-4">
                                    <!-- <button class="btn btn-success width-100" (click)="sendPayment()">Pay</button> -->
                                    <button class="btn btn-success width-100" (click)="saveFormData()">Pay</button>
                                </div>

                            </div>
                        </section>
                    </ng-container>
                    <!-- Fin de la seccion de firma del authorize -->


                    <ng-container *ngIf="tabResult == 'active'">
                        <h3>Payment Information</h3>
                        <section class="row d-flex justify-content-center invoice">

                            <div class="row invoice-container mb-3" style="width: 750px;">
                                <div class="invoice-inbox col-12 pt-2 pb-2">
                                    <div id="ct" class="">
                                        <div class="invoice-00001">
                                            <div
                                                *ngIf="responseTransaction.transaction.status == 'PAID'; else noneTransaction">
                                                <!-- Payment Successful -->
                                                <div class="content-section">
                                                    <div class="row">
                                                        <div class="col-2 pr-0 text-center">
                                                            <i class="far fa-check-circle icon-payment-successful"></i>
                                                        </div>
                                                        <div class="col-10 pl-0">
                                                            <h4 class="m-0 text-payment-successful">Payment
                                                                Successful</h4>
                                                            <span class="desc-payment-successful">Your payment
                                                                has
                                                                been processed! Details of transactions are
                                                                incluided below</span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-template #noneTransaction>
                                                <!-- Payment Failed -->
                                                <div class="content-section">
                                                    <div class="row">
                                                        <div class="col-2 pr-0 text-center">
                                                            <i class="far fa-times-circle icon-payment-failed"></i>
                                                        </div>
                                                        <div class="col-10 pl-0">
                                                            <h4 class="m-0 text-payment-failed">Payment Failed
                                                            </h4>
                                                            <span class="desc-payment-successful">Your payment
                                                                coundn´t be processed, please contact our
                                                                agent</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-2 download-invoice">
                                    <button class="btn btn-secondary">Download</button>
                                </div> -->
                            </div>

                            <div class="invoice-container" style="width: 750px;">
                                <div class="invoice-inbox">

                                    <div id="ct" class="">

                                        <div class="invoice-00001">
                                            <div class="content-section">

                                                <div class="inv--head-section inv--detail-section border-0 mb-2">

                                                    <div class="row">

                                                        <div class="col-sm-6 col-12 mr-auto">
                                                            <img class="company-logo"
                                                                src="{{environment.az_api.cdn}}/{{authService.session.workspace.id}}/media-files/{{responseTransaction.company.logo}}"
                                                                alt="company">

                                                        </div>

                                                        <div class="col-sm-6 text-sm-right">
                                                            <p class="inv-email-address">
                                                                {{responseTransaction.company.name}}</p>
                                                            <p class="inv-email-address"
                                                                *ngIf="responseTransaction.company.address.full_address">
                                                                {{responseTransaction.company.address.full_address}}
                                                            </p>
                                                            <p class="inv-email-address"
                                                                *ngIf="responseTransaction.company.license">IC
                                                                MC: {{responseTransaction.company.license}} </p>
                                                            <p class="inv-email-address"
                                                                *ngIf="responseTransaction.company.dot">US
                                                                D.O.T: {{responseTransaction.company.dot}}</p>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="inv--detail-section inv--customer-detail-section">

                                                    <div class="row">

                                                        <div class="col-sm-6 text-left inv--transaction">
                                                            <p class="inv-created-date mb-0"
                                                                *ngIf="responseTransaction.transaction.code"><span
                                                                    class="inv-title">Transaction ID : </span>
                                                                <span
                                                                    class="inv-date">{{responseTransaction.transaction.code}}</span>
                                                            </p>
                                                            <p class="inv-created-date mb-0"
                                                                *ngIf="responseTransaction.transaction.created"><span
                                                                    class="inv-title">Invoice Date : </span>
                                                                <span
                                                                    class="inv-date">{{responseTransaction.transaction.created}}</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-4">
                                                        <div class="col-sm-6">
                                                            <div class="inv--payment-info">
                                                                <p class="title-details mb-1">Payment Info</p>
                                                                <p class=""><span class=" inv-subtitle">Concept:</span>
                                                                    <span>{{balance.concept}}</span>
                                                                </p>
                                                                <p><span class=" inv-subtitle">Product:</span>
                                                                    <span>{{responseTransaction.transaction.card_number}}</span>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            *ngIf="responseTransaction.transaction.status == 'PAID'; else nonePAID">
                                                            <!-- Payment Successful -->
                                                            <div
                                                                class="col-sm-6 align-self-end text-right inv--payment-info">
                                                                <p><span class=" inv-subtitle">Payment
                                                                        Status:</span> <span>Approved</span></p>
                                                                <div class="total-section-successful mt-2 pr-3">
                                                                    Amount Paid ${{balance.amount}}</div>
                                                            </div>
                                                        </div>
                                                        <ng-template #nonePAID>
                                                            <!-- Payment Failed -->
                                                            <div
                                                                class="col-sm-6 align-self-end text-right inv--payment-info">
                                                                <p><span class=" inv-subtitle">Payment
                                                                        Status:</span> <span>Denied</span></p>
                                                                <div class="total-section-failed mt-2 pr-3">
                                                                    Amount Paid ${{balance.amount}}</div>
                                                            </div>
                                                        </ng-template>

                                                        <div
                                                            class="col-xl-6 col-lg-5 col-md-6 col-sm-8 col-12 order-sm-0 order-1">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row invoice-container mt-3" style="width: 750px;">
                                <div class="invoice-inbox col-12">
                                    <div id="ct" class="">
                                        <div class="invoice-00001">
                                            <div class="content-section">
                                                <div class="response-payment-status">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
</div>