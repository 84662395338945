import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

declare const SignaturePad;
declare const domtoimage;
declare const jQuery;

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit {

   intervalTime = null;
   timesForSearch = 2;
   timesExecuted = 0;
   timeInterval = 500;


  // variable para manejar la libreria
  private signaturePad: any;

  // contiene la informacion la firma codificada en url
  private canvasTextSignaturePreview: HTMLCanvasElement;

  // contiene el texto que usaran como firma
  textSignature: string;

  // indica la secction con la cual trabajar
  section: "TYPE" | "DRAW";

  // cargamos el elemento que trabaja la modal
  @ViewChild('signCanvas')
  canvas: ElementRef;

  // cargamos el elemento que trabaja la modal
  @ViewChild('elemTextSignaturePreview')
  elemTextSignaturePreview: ElementRef;

  // indica si la firma realizada es correcta o no
  isValidSignature: boolean;

  // observer usado para cuando se registre una firma
  @Output()
  onSignature: EventEmitter<File>;

  // disabled button save
  @Output()
  onTypingSignature: EventEmitter<boolean>;
  


  constructor() {

    this.textSignature = null;
    this.section = "TYPE";
    this.isValidSignature = false;
    this.onSignature = new EventEmitter();
    this.onTypingSignature = new EventEmitter();
    this.elemTextSignaturePreview = null;

    this.signaturePad = null;
    this.canvasTextSignaturePreview = null;
    



  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

  }

  /**
   * Permite establecer la seccion de la cual tomar la firma
   * 
   * NOTA: en el frontend, el tab se ejecuta por otro lado
   * @param section 
   */
  setSection(section: "TYPE" | "DRAW"): void {

    this.section = section;

    if (this.section == "DRAW") {
      this.onTypingSignature.emit(false);
    }

    setTimeout(() => {
      this.initSignaturePad();
    }, 500)
  }

  /**
   * limpia todo al pad para una nueva firma
   */
  cleanSignaturePad() {
    this.signaturePad.clear();
    this.isValidSignature = false;
  }

  /**
   * Permite indicar al componente padre la nueva firma
   */
  private notifySign() {

    // primero validamos la firma
    this.validateSignature();

    setTimeout(() => {
      // primero validamos si hay firma valida
      if (this.isValidSignature) {
        // obtenemos el archivo de la firma
        const fileSignature = this.getSignature();
        
        // notificamos la nueva firma
        this.onSignature.emit(fileSignature);
        this.onTypingSignature.emit(false);
      }
    }, 500);

  }

  /**
   * Permite obtener la firma en un archivo
   * @returns 
   */
  private getSignature(): File {

    // se valida que la firma sea correcta
    if (!this.isValidSignature) {
      return null
    }

    let dataUrl = null;
    // verificamos sobre cual section esta parado el usuario
    if (this.section == "DRAW") {
      
      // obtenemos la firma en formato url
      dataUrl = this.signaturePad.toDataURL('image/png');
    } else {
      // obtenemos la url de la imagen
      // dataUrl = this.canvasTextSignaturePreview.toDataURL('image/png');
      dataUrl = this.canvasTextSignaturePreview;
    }

    // pasamos a formato blob
    const fileBlob = this.dataURLToBlob(dataUrl);

    // devolvemos el archivo
    return new File([fileBlob], "signature.png");
  }


  /**
   * Permite inicializar las variables
   * @returns 
   */
  private initSignaturePad(): void {

    // si ya esta instanciado no lo ejectuamos
    if (this.signaturePad != null) {
      return;
    }

    // indicamos al lienzo que se actualize cuando haya un cambio de dimensiones sobre la pantalla
    window.onresize = this.resizeCanvas;

    // actualizamos el lienzo a la pantalla actual
    this.resizeCanvas();

    // creamos una instancia del objeto que hace la firma
    this.signaturePad = new SignaturePad(this.canvas.nativeElement, {
      onEnd: () => {
        this.notifySign();
      }
    });

  }

  /**
   * Permite verificar si el trazo realizado lo valida como buena firma
   */
  private validateSignature() {

    if (this.section == "DRAW") {
      // si no es valida la firma indicamos el estado correspondiente
      if (!this.signaturePad.isEmpty()) {

        // obtenemos los puntos de la firma
        const dataPointsSignature = this.signaturePad.toData();

        // recorremos cada manuscrito para determinar su largor
        for (const handwrite of dataPointsSignature) {

          // si esta conformado por almenos 10 puntos lo dejamos pasar
          if (handwrite.length > 10) {
            this.isValidSignature = true;
            return;
          }
        }
      }
    } else {

      // validamos que ya se haya hecho algo
      if (this.textSignature !== null) {
        if (this.textSignature.trim() != "") {
          this.isValidSignature = true;
          return;
        }
      }
    }

    this.isValidSignature = false;
  }

  /**
   * Permite adaptar el canvas a la densidad de pantalla
   */
  private resizeCanvas(): void {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.canvas.nativeElement.width = this.canvas.nativeElement.offsetWidth * ratio;
    this.canvas.nativeElement.height = this.canvas.nativeElement.offsetHeight * ratio;
    this.canvas.nativeElement.getContext("2d").scale(ratio, ratio);
  }

  // One could simply use Canvas#toBlob method instead, but it's just to show
  // that it can be done using result of SignaturePad#toDataURL.
  private dataURLToBlob(dataURL): Blob {

    // Code taken from https://github.com/ebidel/filer.js
    var parts = dataURL.split(';base64,');
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  /**
   * se dispara cuando el usuario deja de escribir el texto que usara como firma
   */
  onBlurTextSignature() {
    if (this.intervalTime) {
        clearInterval(this.intervalTime);
    }
    this.intervalTime = setInterval(() => {
        this.timesExecuted++;
        if (this.timesExecuted >= 0) {
          this.timesExecuted = 0;
            clearInterval(this.intervalTime);
            // contertimos el elemento donde se muestra la firma a canvas
            domtoimage.toPng(this.elemTextSignaturePreview.nativeElement)
            .then((canvas) => {
              this.canvasTextSignaturePreview = canvas;
              // dejamos que se procese y luego lo notifique
              this.notifySign();
            });
        }
    }, this.timeInterval);
  }

  disableSaveButton() {
    this.onTypingSignature.emit(true);
  }


}
