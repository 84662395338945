import { Balance } from './balance';

export class BalanceListView extends Balance{
    job_number: string;

    constructor(){
        super();
        this.job_number = null;
    }
}
